import styled from "styled-components";
import React from "react";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {PjTa} from "../../Types";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {addNewFilter, queryI} from "../../features/tableSlice";
import Icon from "../misc/Icon";
import SimpleFilter from "./SimpleFilter";
import useTableFiltersSchema from "./useTableFiltersSchema";

function AsideTableFilterBar({tableName, projectName}: PjTa) {

    const {allFields, filtersSchema} = useTableFiltersSchema({tableName, projectName})

    let query = useAppSelector(state => state.tables.queries[tableName].current) as queryI;

    let dispatch = useAppDispatch();

    return <Container>
        {query.filters.length !== 0 && query.filters.map((f, i) => <SimpleFilter allFields={allFields}
                                                                                 filtersSchema={filtersSchema}
                                                                                 key={f.nanoid}
                                                                                 nanoId={f.nanoid}
                                                                                 filter={f}
                                                                                 side={true}
                                                                                 index={i}/>)}
        <button className={'head-filt'} onClick={() => dispatch(addNewFilter(tableName))}>
            <div className={'filter-head'}>Додати фільтр</div>
            <div className={'add-filter'}>
                <Icon src={"/win-icons/Mail stamp (move).ico"}
                      title={'Додати фільтр'} alt={'Додати фільтр'}
                      width={'1.2rem'}
                      height={'1.2rem'}
                      component={faPlus}
                />
            </div>
        </button>
    </Container>
}

export default AsideTableFilterBar


const Container = styled.div`
    display: flex;
    //flex-flow: column;
    flex-wrap: wrap;
    //overflow-y: auto;

    gap: .6rem;
    align-items: center;


    & .head-filt {
        display: flex;
        gap: .2rem;
        align-items: center;
        justify-content: center;
        width: 100%;

        font-size: 1rem;
        padding: .4rem;
        background-color: var(--table-row-odd-bg-color);
        border-radius: .4rem;
        background: none;
        border: 2px solid var(--table-border-color);
        border: none;
        cursor: pointer;

        &[disabled] {
            filter: grayscale(.5);
        }

        &:hover {
            filter: brightness(.85);
        }
    }

    & .filter-head {
        color: var(--secondary-text);
        font-size: 1rem;
    }

    & .add-filter {
        font-size: 1rem;
        display: flex;
        align-items: center;
        padding: .4rem;
        justify-content: center;
        background-color: var(--table-row-odd-bg-color);
        border-radius: .4rem;
        background: none;
        border: 2px solid var(--table-border-color);
        border: none;
        cursor: pointer;

        &[disabled] {
            filter: grayscale(.5);
        }

        &:hover {
            filter: brightness(.85);
        }

        & img {
            height: 1.2rem;
            width: 1.2rem;
            transform: scaleX(-1);
        }
    }

`

const AddFilterButton = styled.div<any>`

    flex-basis: ${props => props.$full ? '100%' : 'auto'};
    align-self: end;
    margin-left: auto;

    @media (max-width: 720px) {
        width: 100% !important;
        margin-left: 0;
    }

    & button {
        font-size: 1rem;
        display: flex;
        align-items: center;
        padding: .4rem;
        width: 100%;
        justify-content: center;


        & img {
            height: 1.5rem;
            width: 1.5rem;
            transform: scaleX(-1);
        }
    }
`
