import React, {useEffect, useState} from 'react';
import 'moment/locale/uk';
import {useAppDispatch, useAppSelector} from "../../hooks";
import Table from "./Table";
import styled from "styled-components";
import {fetchAsyncMap, fetchTableCounts, setActiveTable} from "../../features/tableSlice";
import TableFilter from "./TableFilter";
import TableControls from "./TableControls";
import {functions, schemas} from "../../config";
import {createPortal} from "react-dom";
import AsideTableFunc from "../header/AsideTableFunc";
import {LANG} from "../CONSTANTS";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMeteor, faTable} from "@fortawesome/free-solid-svg-icons";
import {OverlayContainer} from "../misc/ImageViewer";
import Settings from "../settings/Settings";
import HiddenTableList from "./HiddenTableList";


function TablesPage({projectName}: { projectName: string }) {

    let useSideHeader = useAppSelector(state => state.layout.useSideHeader);
    let sideHeaderReady = useAppSelector(state => state.layout.sideHeaderReady);
    let tableName = useAppSelector(state => state.tables.activeTable);
    let asyncMaps = useAppSelector(state => state.tables.tableInfo[tableName].asyncMaps);
    const dispatch = useAppDispatch()
    useEffect(() => {
        if (asyncMaps.loading === 'idle')
            dispatch(fetchAsyncMap({project: projectName, table: tableName, data: asyncMaps.data}))
    }, [asyncMaps.data, asyncMaps.loading, dispatch, projectName, tableName]);

    return (
        <>
            <List tableName={tableName}/>
            <Divider/>
            {asyncMaps.loading === 'loaded' && <>
                {!useSideHeader && <TableFilter tableName={tableName} projectName={projectName}/>}
                {useSideHeader && sideHeaderReady &&
                    createPortal(<AsideTableFunc tableName={tableName} projectName={projectName}/>,
                        document.getElementById("side-head-content")!)}
                <TableControls projectName={projectName} tableName={tableName}/>
                <Table tableName={tableName} projectName={projectName}/>
            </>}
            {schemas[projectName].tables[tableName].action &&
                functions[schemas[projectName].tables[tableName].action!]()
            }
        </>
    )
}

export default TablesPage;


function List({tableName}: { tableName: string }) {

    let project = useAppSelector(state => state.project.name)
    let tableInfo = useAppSelector(state => state.tables.tableInfo)
    let count = useAppSelector(state => state.tables.count)
    let isAdmin = useAppSelector(state => state.project.isUserAdmin)
    const [isTableHiddenOpen, setIsTableHiddenOpen] = useState<boolean>(false)

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!count[tableName]?.total) {
            dispatch(fetchTableCounts(project))
        }
    }, [count, dispatch, project, tableName]);

    const list = Object.entries(tableInfo).filter(([k, t]) => (!t.forAdmin || isAdmin) && !t.hidden);
    return (
        <>
            <UL>
                <li>
                    <Btn className={'hide-tables'} $active={false} onClick={() => setIsTableHiddenOpen(true)}>
                        <FontAwesomeIcon title={'side-menu'} icon={faTable}
                                         style={{width: '1.2rem', height: '1.2rem'}}
                                         className={'fa-color'}/>
                    </Btn>
                </li>
                {list.map(([k, t]) => <li key={k}>
                    <Btn $active={tableName === k} onClick={() => dispatch(setActiveTable(k))}>
                        {t.forAdmin && <FontAwesomeIcon title={'side-menu'} icon={faMeteor}
                                                        style={{width: '1rem', height: '1rem', marginRight: '.2rem'}}
                                                        className={'fa-color'}/>}
                        <span>{t.name[LANG]}</span>&nbsp;<small
                        className={'count'}>{count[k].inCondition ?? '?'} / {count[k].total || '?'}</small>
                    </Btn>
                </li>)}
            </UL>
            {isTableHiddenOpen &&
                <OverlayContainer onClick={() => setIsTableHiddenOpen(false)} $isVisible={isTableHiddenOpen}>
                    <HiddenTableList close={() => setIsTableHiddenOpen(false)} />
                </OverlayContainer>}
        </>
    )
}

const H2 = styled.h2`
    color: var(--table-row-text-color);
    font-size: 1.6rem;
    font-weight: bold;
    width: 100%;
    margin-top: .5rem;
    text-align: center;
`

const UL = styled.ul`

    margin-top: .5rem;
    padding: .4rem 0;
    //display: grid;
    //grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    //justify-items: start;
    display: flex;
    gap: .5rem;
    flex-wrap: wrap;

    & .hide-tables {
        display: flex;
        justify-content: center;
        height: 100%;
        align-items: center;
    }

    & > li {
        white-space: nowrap;
        list-style: none;

        @media (max-width: 500px) {
            flex-basis: 100%;

        }
    }



`

const Btn = styled.button<any>`
    appearance: none;
    //background: var(--table-row-odd-bg-color);
    background: transparent;
    padding: .5rem;
    color: var(--table-row-text-color);
    background-color: hsl(from var(--table-row-odd-bg-color) h s l / 0.8);
    //border-radius: .5rem;
    cursor: pointer;
    width: 100%;
    font-size: 1rem;
    border: none;
    border-bottom: 3px solid ${props => props.$active ? `var(--table-action-color)` : 'var(--table-border-color)'};
    //border-bottom: 3px solid var(--table-border-color);
    //border-radius: .2rem;
    font-weight: ${props => props.$active ? 'bold' : 'normal'};

    & .count {
        background: ${props => props.$active ? 'var(--table-action-color)' : 'var(--table-row-odd-bg-color)'};
        color: ${props => !props.$active ? 'var(--table-row-text-color)' : 'var(--secondary-text)'};
        padding: .1rem .2rem;
        border-radius: .2rem;
        font-size: 12px;
        vertical-align: top;
    }


    &:hover {
        filter: brightness(.85);
    }
`
//${props => props.$active && `color: var(--table-action-color);`}


const Divider = styled.hr`
    width: 100%;
    height: 2px;
    background-color: var(--table-action-color);
    margin: 0;
`
