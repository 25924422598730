import styled from "styled-components";
import {schemas} from "../../config";
import {PjTa} from "../../Types";
import SimpleFilter from "../filters/SimpleFilter";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {addNewFilter, queryI} from "../../features/tableSlice";
import React, {useMemo} from "react";
import Icon from "../misc/Icon";
import {faPlus} from "@fortawesome/free-solid-svg-icons";

function TableFilter({tableName, projectName}: PjTa) {

    const {allFields, filtersSchema} = useMemo(() => {
        let filtersSchema = schemas[projectName].filterSchemas[tableName];
        const allFields = Object.keys(filtersSchema).map((field) => ({
            label: filtersSchema[field].title.ua,
            value: field
        }));
        return {
            allFields,
            filtersSchema
        }
    }, [projectName, tableName]);


    let query = useAppSelector(state => state.tables.queries[tableName].current) as queryI;
    let dispatch = useAppDispatch();

    return <Wrapper>
        {/*<div className={'head'}>*/}
        {/*    <h2>Фільтри</h2>*/}
        {/*    <button onClick={() => dispatch(addNewFilter(tableName))}>*/}
        {/*        <img src="/win-icons/Mail stamp (move).ico" alt="add filter"/>*/}
        {/*    </button>*/}
        {/*</div>*/}
        <Container>
            <div className={'head-filt'}>
                <div className={'filter-head'}>Фільтри:</div>
                <button className={'add-filter'} onClick={() => dispatch(addNewFilter(tableName))}>
                    <Icon src={"/win-icons/Mail stamp (move).ico"}
                          title={'Додати фільтр'} alt={'Додати фільтр'}
                          width={'1.2rem'}
                          height={'1.2rem'}
                          component={faPlus}
                    />
                </button>
            </div>
            {query.filters.length !== 0 && query.filters.map((f, i) => <SimpleFilter allFields={allFields}
                                                                                     filtersSchema={filtersSchema}
                                                                                     key={f.nanoid}
                                                                                     nanoId={f.nanoid}
                                                                                     filter={f}
                                                                                     index={i}/>)}
        </Container>
    </Wrapper>
}

export default TableFilter

const Wrapper = styled.div`
    margin-bottom: .5rem;
    margin-top: .5rem;

    display: flex;
    flex-flow: column;
    gap: .5rem;

    & .head {

        & h2 {
            color: var(--table-row-text-color);
            font-size: 1.6rem;
            width: unset;
        }

        display: flex;
        justify-content: center;
        gap: .5rem;
        align-items: center;

        & button {
            font-size: 1rem;
            display: flex;
            align-items: center;
            padding: .4rem;
            justify-content: center;


            & img {
                height: 1.5rem;
                width: 1.5rem;
                transform: scaleX(-1);
            }
        }
    }

`


const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: .6rem;
    align-items: center;
    
    & .head-filt {
        display: flex;
        gap: .2rem;
        align-items: center;
        justify-content: center;
        @media (max-width: 500px) {
            width: 100%;
        }
    }
    
    & .filter-head {
        color: var(--table-row-text-color);
        font-size: 1.2rem;
    }

    & .add-filter {
        font-size: 1rem;
        display: flex;
        align-items: center;
        padding: .4rem;
        justify-content: center;
        background-color: var(--table-row-odd-bg-color);
        border-radius: .4rem;
        background: none;
        border: 2px solid var(--table-border-color);
        cursor: pointer;

        &[disabled] {
            filter: grayscale(.5);
        }

        &:hover {
            filter: brightness(.85);
        }

        & img {
            height: 1.2rem;
            width: 1.2rem;
            transform: scaleX(-1);
        }
    }
    
`

const AddFilterButton = styled.div<any>`

    flex-basis: ${props => props.$full ? '100%' : 'auto'};
    align-self: end;
    margin-left: auto;

    @media (max-width: 720px) {
        width: 100% !important;
        margin-left: 0;
    }

    & button {
        font-size: 1rem;
        display: flex;
        align-items: center;
        padding: .4rem;
        width: 100%;
        justify-content: center;


        & img {
            height: 1.5rem;
            width: 1.5rem;
            transform: scaleX(-1);
        }
    }
`
