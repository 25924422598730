import styled from "styled-components";
import {FieldType, SqlOperation} from "../../Types";
import React, {useState} from "react";
import {useAppDispatch} from "../../hooks";
import {setFilterValue} from "../../features/tableSlice";
import {FilterSchema} from "../../287/tables/UsersTable";
import DatetimeFilterValue from "./DatetimeFilterValue";
import FilterMultiMapValue from "./FilterMultiMapValue";
import CreateSelectFilter from "./CreateSelectFilter";

interface FilterValueI {
    index: number;
    op: SqlOperation;
    filterSchema: FilterSchema,
    arrPos?: 0 | 1,
    value: any,
    nanoId?: string,
    side?: boolean
}

function FilterValue({index, op, filterSchema, arrPos, value, nanoId, side = false}: FilterValueI) {

    if (op === SqlOperation.IS_NULL || op === SqlOperation.IS_NOT_NULL) {
        return null
    }

    let element

    switch (filterSchema.type) {
        case "string":
        case "number":
        case "email":
        case "url":
        case "tel":
        case "image":
            if (op === SqlOperation.IN || op === SqlOperation.NOT_IN) {
                element =
                    <CreateSelectFilter index={index} schema={filterSchema} type={filterSchema.type} textCenter={side}/>
                break;
            }
            element =
                <FilterInputStringNumber initialValue={value} index={index} type={filterSchema.type} arrPos={arrPos}
                                         textCenter={side}/>
            break
        case "boolean":
            element = <FilterBooleanValue initialValue={value} index={index} textCenter={side}/>
            break;
        case "datetime":
        case "time":
        case "date":
            element = <DatetimeFilterValue initialValue={value} index={index} schema={filterSchema} arrPos={arrPos}
                                           textCenter={side}/>
            break;
        case "map":
            element = <FilterMultiMapValue initialValue={value} index={index} schema={filterSchema} textCenter={side}/>
            break;
        default:
            element = <div>NO SUPPORT YET!</div>;
    }

    return element
}

export default FilterValue

const StringNumberContainer = styled.div<any>`
    width: 100%;
    height: ${props => props.$maxHeight ? '100%' : 'unset'};

    & .text-num-wrapper {
        height: 100%;
        display: flex;
        align-items: stretch;
    }

    & input {
        box-sizing: border-box;
        height: 100%;
        width: 100%;
        border: none;
        padding: .2rem;
        min-height: 2rem;
        //background: var(--table-row-even-bg-color);
        //color: var(--table-row-text-color);
        //font-weight: bold;
        line-height: 1.2;
        font-size: 16px;
        text-indent: .2rem;

        text-align: ${props => props.$textCenter ? 'center' : 'auto'};
        
        &[type=number] {
            -moz-appearance: textfield;
        }

        &[type="number"]::-webkit-inner-spin-button,
        &[type="number"]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }


        @media (min-width: 720px) {
            &[type=text] {
                min-width: 90px;
                max-width: 650px;
            }

            &[type=number] {
                min-width: 80px;
                max-width: 650px;
            }

            &[type=email] {
                min-width: 250px;
                max-width: 650px;
            }

            &[type=url] {
                min-width: 150px;
                max-width: 650px;
            }

            &[type=tel] {
                min-width: 110px;
                max-width: 650px;
            }
        }


    }
`

const iniStrNumVal = (type: FieldType, arrPos: 0 | 1 | undefined, initialValue: any) => {

    if (arrPos !== undefined && initialValue) {
        return initialValue[arrPos]
    }
    if (!!initialValue)
        return initialValue
    if (!initialValue)
        return type === 'number' ? '0' : ''

    return null;
}

function FilterInputStringNumber({type, index, arrPos, initialValue, textCenter = false}: {
    type: FieldType,
    index: number,
    arrPos?: 0 | 1,
    initialValue: any,
    textCenter?: boolean
}) {

    const [value, setValue] = useState(iniStrNumVal(type, arrPos, initialValue))

    function onChange(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, isNumber = false) {
        let val = e.currentTarget.value;
        if (isNumber) {
            if (val === '') {
                setValue('')
                return
            }
            const parsedValue = parseFloat(val);
            setValue(isNaN(parsedValue) ? 0 : parsedValue)
        } else setValue(val)
    }

    const dispatch = useAppDispatch();

    const onBlur = () => dispatch(setFilterValue({index, value: value === '' ? 0 : value, arrPos}));

    let element;

    switch (type) {
        case 'number':
            element = (
                <input type="number" value={value}
                       onBlur={onBlur}
                       onChange={(e) => onChange(e, true)}/>
            );
            break;

        case 'url':
        case 'tel':
        case 'email':
        case "string":
        case "image":
            element = (
                <input type={type === 'email' ? 'email' : type === 'tel' ? 'tel' : 'text'}
                       value={value}
                       placeholder={value === '' ? '"Порожній рядок"' : undefined}
                       onBlur={onBlur}
                       onChange={(e) => onChange(e)}/>
            );
            break;

        default:
            break;
    }

    return <StringNumberContainer $maxHeight={arrPos === undefined} $textCenter={textCenter}>
        {element}
    </StringNumberContainer>
}

function FilterBooleanValue({index, initialValue, textCenter = false}: {
    index: number,
    initialValue: any,
    textCenter?: boolean
}) {

    const dispatch = useAppDispatch();

    const [value, setValue] = useState(initialValue || true)

    // useEffect(() => {
    //     dispatch(setFilterValue({index, value: true}))
    // }, [index, dispatch]);

    const onClick = () => {
        dispatch(setFilterValue({index, value: !value}))
        setValue(!value)
    };


    return <BooleanContainer $truthy={value}>
        <div>{value ? 'Так' : "Ні"}</div>
        <button onClick={onClick}><img
            title={`Змінити значення на ${value ? 'НІ' : 'ТАК'}`}
            src={"/win-icons/IR-Port transfer denied.ico"}
            alt=""/></button>
    </BooleanContainer>
}

const BooleanContainer = styled.div<any>`
    width: 100%;
    padding-left: .5rem;
    padding-right: .5rem;
    display: flex;
    gap: .5rem;
    align-items: center;
    min-height: 2rem;
    justify-content: center;
    height: 100%;
    color: ${props => props.$truthy ? 'lightgreen' : "red"};

    //padding: .2rem;

    button {
        background: none;
        border: none;
        padding: 0;
        height: 100%;

    }

    & img {
        width: 1.2rem;
        height: 1.2rem;
    }
`

function IsNullValue({value}: { value: boolean }) {

    return <StringNumberContainer>
        <NullContainer>{value ? 'IS NULL' : 'IS NOT NULL'}</NullContainer>
    </StringNumberContainer>
}

const NullContainer = styled.span`
    font-style: italic;
    color: var(--table-action-color);
`
