import styled from "styled-components";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {LANG} from "../CONSTANTS";
import {setTableHidden} from "../../features/tableSlice";


export default function HiddenTableList({close}: { close: () => void }) {
    let tableInfo = useAppSelector(state => state.tables.tableInfo)
    let isAdmin = useAppSelector(state => state.project.isUserAdmin)
    let project = useAppSelector(state => state.project.name)

    const dispatch = useAppDispatch()
    const toggle = (table: string, val: boolean) => dispatch(setTableHidden({table, val}));
    const list = Object.entries(tableInfo).filter(([k, t]) => !t.forAdmin || isAdmin);

    const save = () => {
        const hidden = list.filter(([k, v]) => v.hidden).map(([k, v]) => k);
        localStorage.setItem(`${project}-hidden-tables`, JSON.stringify(hidden))
        close()
    }

    return <Container onClick={(e) => e.stopPropagation()}>
        <h3 className="header">Видимі таблиці</h3>
        <ul className="main">
            {list.map(([k, t]) => {
                return <li key={k}>
                    <label>
                        <input type="checkbox" checked={!t.hidden} onChange={() => toggle(k, !t.hidden)}/>
                        <span>{t.name[LANG]}</span>
                    </label>
                </li>
            })}
        </ul>
        <div>
            <button className="action-btn" onClick={save}>
                Зберегти
            </button>
        </div>
        <div className="controls">
            <button onClick={close} className={'action-btn'}>
                ❌
            </button>
        </div>
    </Container>
}

const Container = styled.section`
    max-width: min(500px, 85vw);
    max-height: 80vh;
    width: 100%;
    color: var(--table-row-text-color);
    padding: .5rem;
    border-radius: .5rem;
    background-color: var(--table-row-odd-bg-color);

    display: flex;
    gap: .5rem;
    flex-flow: column;

    & .header {
        font-size: 1.1rem;
        font-weight: bold;
        text-align: center;
    }

    & .main {
        display: flex;
        flex-flow: column;
        gap: .5rem;

        & label {
            cursor: pointer;
            display: flex;
            gap: .2rem;
            align-items: center;
            font-size: 1rem;

            & input {
                margin: 0;
                width: 1rem;
                height: 1rem;
            }
        }
    }


    & .controls {
        display: flex;
        justify-content: end;
        align-items: center;
    }
`
