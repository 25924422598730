import React, {useEffect} from 'react';
import Row from "./Row";
import {schemas} from "../../config";
import styled from "styled-components";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {Field, PrimitiveObject} from "../../Types";
import {fetchTableByQuery} from "../../features/tableSlice";
import TableBottomPagination from "./TableBottomPagination";
import Loader from "../Loader";
import FakeRow from "./FakeRow";

const Table = ({tableName, projectName}: { tableName: string, projectName: string }) => {

    const schema = schemas[projectName].tables[tableName].fields as Field[];
    const pkField = schemas[projectName].tables[tableName].pk as string;
    const cols = schema.filter(x => x.inline).length;
    let inConditionRecords = useAppSelector(state => state.tables.count[tableName].inCondition);
    let tableInfo = useAppSelector(state => state.tables.tableInfo[tableName]);
    let page = useAppSelector(state => state.tables.pages[tableName][tableInfo.current_page]) as PrimitiveObject[];
    let prevPage = useAppSelector(state => state.tables.pages[tableName][tableInfo.prev_page]) as PrimitiveObject[];


    const dispatch = useAppDispatch();

    useEffect(() => {
        !page && tableInfo.loading === 'idle' && dispatch(fetchTableByQuery())
    }, [dispatch, page, tableInfo.loading]);


    const renderPage = page ? page : prevPage

    return (
        <TableWrapper>
            <table className="custom-table">
                <thead>
                <tr>
                    {schema.map(field => !field.inline ? null :
                        <HeaderColumn key={field.key}>
                            <div className="wrapper">
                                {/*<TypeIcon className={'title-icon'} type={field.type}/>*/}
                                {field.title.ua}
                            </div>
                        </HeaderColumn>)}
                </tr>
                </thead>
                <tbody>
                {renderPage && renderPage.map((record: any, i: number) =>
                    <Row record={record} schema={schema} cols={cols} key={tableInfo.current_page + '-' + i}
                         pkField={pkField} tableName={tableName}/>)}
                {renderPage && renderPage.length === 0 && <tr className={'not-found-table-row'}>
                    <td colSpan={cols} className={'text-align-center not-found-table-cell'}>Записів що підпадають під умови не знайдено</td>
                </tr>}
                {!renderPage && Array.from({length: 20}, (_, index) => (
                    <FakeRow key={index} schema={schema}/>
                ))}
                </tbody>
                <tfoot>
                <tr>
                    <TableBottomPagination maxPages={tableInfo.max_pages} currentPage={tableInfo.current_page} tableName={tableName}
                                           inConditionRecords={inConditionRecords} cols={cols}/>
                </tr>
                {/*<tr>*/}
                {/*    <td colSpan={cols}>*/}
                {/*        <TablePerPage tableName={tableName}/>*/}
                {/*    </td>*/}
                {/*</tr>*/}
                </tfoot>
            </table>
            {tableInfo.loading === 'loading' && <LoaderContainer>
                <Loader/>
            </LoaderContainer>}


        </TableWrapper>
    );
};

export default Table;

const HeaderColumn = styled.th`


    & .wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: .2rem;


        & img {
            width: 1rem;
            height: 1rem;
        }
    }
`

export const LoaderContainer = styled.div`

    background: rgba(0, 0, 0, 0.54);

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`

const TableWrapper = styled.div`
    overflow-x: auto;
    max-width: 100vw;
    position: relative;

    .custom-table {
        width: 100%;
        //border-collapse: collapse;
        text-align: left;
        color: var(--table-row-text-color);
        //border: 1px solid var(--table-border-color);


        thead {
            background-color: var(--table-header-bg-color);
            color: var(--table-header-text-color);
            font-weight: bold;
            border-bottom: 2px solid var(--table-border-color);
            border-top: 2px solid var(--table-border-color);
        }

        tfoot {
            border-top: 1px solid var(--table-border-color);
            background-color: var(--table-footer-bg-color);
            color: var(--table-footer-text-color);
            font-weight: bold;
            text-align: right;

        }
        th {
            padding: 6px;
            white-space: nowrap;
        }

        td {
            padding: 12px;
            //border: 2px solid var(--table-border-color);
        }

        tbody {
            tr:nth-child(even) {
                background-color: var(--table-row-even-bg-color);
            }

            tr:nth-child(odd) {
                background-color: var(--table-row-odd-bg-color);
            }
        }
    }

    .edit-table {
        width: 100%;
        border-radius: 4px;
        border-collapse: collapse;
    }

    .edit-textarea, .edit-input-number, .edit-input-text {
        width: 100%;
        text-indent: 0;
    }
`;
