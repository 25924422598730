import Icon from "../misc/Icon";
import {faRightToBracket} from "@fortawesome/free-solid-svg-icons";
import React, {useState} from "react";
import {OverlayContainer} from "../misc/ImageViewer";
import Settings from "../settings/Settings";
import {schemas} from "../../config";


function logout(project: string) {
    fetch(schemas[project].backend + '/logout.php', {credentials: 'include'})
        .then(() => {
            localStorage.removeItem('project')
            window.location.href = '/login.html'
        })
}

export default function HeadActions({projectName}: { projectName: string }) {
    const [isSettingsOpen, setIsSettingsOpen] = useState<boolean>(false)


    return <>
        <div className="head-actions flex fl-col">
            <ul className="list">
                <li className={'flex fl-al-ce'}>
                    <div className="dot"/>
                    <button className="header-action-btn" onClick={() => setIsSettingsOpen(!isSettingsOpen)}>
                        Налаштування
                    </button>
                </li>
                <li className={'flex fl-al-ce'}>
                    <div className="dot"/>
                    <button className="header-action-btn">
                        Список акцій
                    </button>
                </li>
            </ul>
            <div>
                <button className="logout" onClick={() => logout(projectName)}>
                    <Icon src={"/win-icons/Exit%20(gray).ICO"}
                          title={'Вийти'} alt={'Вийти'}
                          width={'1.2rem'}
                          height={'1.2rem'}
                          className={'fa-rotate-180'}
                          component={faRightToBracket}
                    />
                    <span>Вийти</span>
                </button>
            </div>
        </div>
        {isSettingsOpen && <OverlayContainer onClick={() => setIsSettingsOpen(false)} $isVisible={isSettingsOpen}>
            <Settings close={() => setIsSettingsOpen(false)}/>
        </OverlayContainer>}
    </>
}
