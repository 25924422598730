import {PjTa} from "../../Types";
import AsideTableFilterBar from "../filters/AsideTableFilterBar";
import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../hooks";
import Icon from "../misc/Icon";
import {faArrowsRotate, faMeteor} from "@fortawesome/free-solid-svg-icons";
import {fetchTableByQuery} from "../../features/tableSlice";
import PaginateTable from "../table/PaginateTable";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


export default function AsideTableFunc({tableName, projectName}: PjTa) {

    let count = useAppSelector(state => state.tables.count[tableName])
    let name = useAppSelector(state => state.tables.names[tableName])
    let status = useAppSelector(state => state.tables.tableInfo[tableName].loading)
    let tableInfo = useAppSelector(state => state.tables.tableInfo[tableName]);

    let dispatch = useAppDispatch();

    const fetchTable = () => {
        dispatch(fetchTableByQuery({clean: true, pageIndex: 1}))
    }

    const [opacity, setOpacity] = useState(0);

    useEffect(() => {
        setOpacity(1);
    }, []);

    return <div className={'aside-table-func'} style={{opacity: opacity}}>
        <div className="table-name">
            {tableInfo.forAdmin && <FontAwesomeIcon title={'side-menu'} icon={faMeteor}
                                            style={{width: '1.2rem', height: '1.2rem', marginRight: '.2rem'}}
                                            className={'fa-color'}/>}
            <span>{name.ua}</span>&nbsp;<small
            className={'count'}>{count.inCondition ?? '?'} / {count.total || '?'}</small>
        </div>
        <AsideTableFilterBar tableName={tableName} projectName={projectName}/>
        <div className="flex fl-ju-ce">
            <UpdateBtn disabled={status !== 'idle'} onClick={fetchTable}>
                <span>Оновити</span>
                <Icon src={"/win-icons/Mail stamp (move).ico"}
                      title={'Додати фільтр'} alt={'Додати фільтр'}
                      width={'1.2rem'}
                      height={'1.2rem'}
                      className={'text-color'}
                      component={faArrowsRotate}
                />
            </UpdateBtn>
        </div>
        <div className="flex fl-ju-ce mt-4">
            <PaginateTable maxPages={tableInfo.max_pages} currentPage={tableInfo.current_page} smaller secondaryColor
                           disable={status !== 'idle'}/>
        </div>
    </div>
}

const UpdateBtn = styled.button`

    background: var(--table-action-color);
    color: var(--secondary-text);
    padding: 10px;
    border-radius: 10px;
    font-size: 1rem;
    width: 170px;
    width: 100%;
    cursor: pointer;
    border: none;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: .2rem;

    & .text-color {
        & path {
            fill: var(--secondary-text);
            stroke: var(--secondary-text);
        }
    }

    &:hover {
        filter: brightness(.85);
    }

    &[disabled] {
        filter: grayscale(.5);
    }

    & img {
        flex-shrink: 0;
    }
`
