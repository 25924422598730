import inmsgChannelPie from "./charts/InmsgChannelPie";
import inmsgByTypes from "./charts/InmsgByTypes";
import inmsgDayActivity from "./charts/InmsgDayActivity";
import inmsgPerUser from "./charts/InmsgPerUser";
import {ChartI} from "../Types";
import inmsgUniqueUsers from "./charts/InmsgUniqueUsers";
import inmsgSuccess from "./charts/InmsgSuccess";
import inmsgByTypesLong from "./charts/InmsgByTypesLong";
import orderTop5Pie from "./charts/OrderTop5Pie";
import orderRate from "./charts/OrderRate";
import inmsgBalls from "./charts/InmsgBalls";
import orderCount from "./charts/OrderCount";
import inmsgBallsLong from "./charts/InmsgBallsLong";
import inmsgChannelLong from "./charts/InmsgChannelLong";


const charts = {
    inmsgChannelPie: inmsgChannelPie,
    inmsgChannelLong: inmsgChannelLong,
    orderTop5Pie: orderTop5Pie,
    orderRate: orderRate,
    orderCount: orderCount,
    inmsgBalls: inmsgBalls,
    inmsgBallsLong: inmsgBallsLong,
    inmsgByTypes: inmsgByTypes,
    inmsgSuccess: inmsgSuccess,
    inmsgDayActivity: inmsgDayActivity,
    inmsgPerUser: inmsgPerUser,
    inmsgByTypesLong: inmsgByTypesLong,
    inmsgUniqueUsers: inmsgUniqueUsers,
} as { [key: string]: ChartI }

export {charts as Act287Charts}
