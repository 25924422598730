import React, {useState} from 'react';

import ExampleControls, {Glyphs, KeyOfGlyphs} from './XyControls';
import CustomChartBackground from './CustomChartBackground';
import {ChartEntry, GlyphI, NormalObject, Strings, XyChartI} from "../../../Types";
import moment from "moment";
import {useAppSelector} from "../../../hooks";
import {GlyphProps} from "@visx/xychart/lib/types";
import {XYChartTheme} from "@visx/xychart";
import {MIN_SLOT_WIDTH} from "../../graphs/GraphLayout";
import {LANG, XY_GAP} from "../../CONSTANTS";
import PieView, {PieEntry} from "../Pie/PieView";
import {RenderTooltipGlyphProps} from "@visx/xychart/lib/components/Tooltip";

export type XYChartProps = {
    width: number;
    chartId: string;
    slots: number;
    data: NormalObject[]
};

const dateKey = 'date'

const tickValuesCalculator = (lowest: number, highest: number, numValues: number) => {
    const result = [];
    if (numValues < 2) {
        throw new Error("numValues must be at least 2 to include head and tail.");
    }

    const step = (highest - lowest) / (numValues - 1);

    for (let i = 0; i < numValues; i++) {
        result.push(lowest + i * step);
    }

    return result;
};

function getNumOfTicks(width: number, max: number, tickWidth: number) {
    return Math.min(Math.floor((width - 100) / tickWidth), max)
}

function getTimeTicks(width: number, data: any[], dateKey: string, tickWidth: number) {
    let values = data.map(d => moment(d[dateKey]).toDate().getTime())
    return tickValuesCalculator(values[values.length - 1], values[0], getNumOfTicks(width, data.length, tickWidth))
}

type SafeSettings = {
    glyphs: (ChartEntry & { value: KeyOfGlyphs | string })[];
    colors: Strings;
    entries: ChartEntry[];
    xAxisName: string;
    yAxisName: string;
};


export default function MyXyChart({width, chartId, slots, data}: XYChartProps) {
    const settings = useAppSelector(state => (state.charts.entries[chartId].settings as XyChartI))
    const {glyphs, colors, entries, xAxisName, yAxisName} = settings as unknown as SafeSettings
    const height = useAppSelector(state => state.charts.entries[chartId].height);
    const tickTimeFormat = useAppSelector(state => state.charts.status[chartId].tickTimeFormat!);


    const visibleSeries = entries.filter(e => !e.hide)

    const actualSlots = Math.max(Math.floor(width / MIN_SLOT_WIDTH), 1)

    const [isHovered, setIsHovered] = useState(false)

    return (
        <ExampleControls chartId={chartId} slots={slots} width={width} isHovered={isHovered}>
            {({
                  accessors,
                  animationTrajectory,
                  colorAccessorFactory,
                  colorAccessorFactoryForLines,
                  config,
                  curve,
                  renderAreaSeries,
                  renderAreaStack,
                  renderBarGroup,
                  renderBarSeries,
                  renderBarStack,
                  renderGlyphSeries,
                  renderHorizontally,
                  renderLineSeries,
                  sharedTooltip,
                  showGridColumns,
                  showGridRows,
                  showHorizontalCrosshair,
                  showTooltip,
                  showVerticalCrosshair,
                  snapTooltipToDatumX,
                  snapTooltipToDatumY,
                  stackOffset,
                  theme,
                  xAxisOrientation,
                  yAxisOrientation,
                  occupySlots,
                  usePie,

                  // components are animated or not depending on selection
                  AreaSeries,
                  AreaStack,
                  Axis,
                  BarGroup,
                  BarSeries,
                  BarStack,
                  GlyphSeries,
                  Grid,
                  LineSeries,
                  Tooltip,
                  XYChart,
              }) => (
                <>
                    {(!settings.usePie || (settings.usePie && actualSlots > 2)) && <XYChart
                        width={(width - (XY_GAP * (actualSlots - occupySlots))) * occupySlots / actualSlots}
                        height={height!}
                        margin={{
                            top: xAxisOrientation === 'top' ? xAxisName !== '' ? 50 : 40 : 40,
                            bottom: xAxisOrientation === 'bottom' ? xAxisName !== '' ? 50 : 40 : 40,
                            left: yAxisOrientation === 'left' ? yAxisName !== '' ? 60 : 50 : 40,
                            right: yAxisOrientation === 'right' ? yAxisName !== '' ? 60 : 50 : 40
                        }}
                        captureEvents={true}
                        onPointerMove={(p: any) => {
                            setIsHovered(true)
                        }}
                        // onPointerOut={() => setIsHovered(isHovered ? false : true)}
                        // captureEvents={!editAnnotationLabelPosition}
                        // onPointerUp={(d) => {
                        //     setAnnotationDataKey(d.key as 'New York' | 'San Francisco' | 'Austin');
                        //     setAnnotationDataIndex(d.index);
                        // }}
                    >
                        <CustomChartBackground/>
                        <Grid
                            key={`grid-${animationTrajectory}`} // force animate on update
                            rows={showGridRows}
                            columns={showGridColumns}
                            animationTrajectory={animationTrajectory}
                            numTicks={!renderHorizontally ? getNumOfTicks(width, data.length, tickTimeFormat.width) : undefined}
                        />

                        {renderLineSeries && visibleSeries.map(e => <LineSeries
                                key={e.key}
                                dataKey={e.key}
                                data={data}
                                xAccessor={(d) => accessors.x(d, e.key, dateKey)}
                                yAccessor={(d) => accessors.y(d, e.key, dateKey)}
                                curve={curve}
                                colorAccessor={colorAccessorFactoryForLines}
                            />
                        )}

                        {renderAreaStack && (
                            <AreaStack curve={curve} offset={stackOffset} renderLine={stackOffset !== 'wiggle'}>
                                {visibleSeries.map(e => <AreaSeries
                                    key={e.key}
                                    dataKey={e.key}
                                    data={data}
                                    xAccessor={(d) => accessors.x(d, e.key, dateKey)}
                                    yAccessor={(d) => accessors.y(d, e.key, dateKey)}
                                    fillOpacity={0.4}
                                    fill={colors?.[e.key]}
                                    lineProps={{
                                        stroke: colors?.[e.key]
                                    }}
                                />)}
                            </AreaStack>
                        )}

                        {renderAreaSeries && (visibleSeries.map(e => <AreaSeries
                                key={e.key}
                                dataKey={e.key}
                                data={data}
                                xAccessor={(d) => accessors.x(d, e.key, dateKey)}
                                yAccessor={(d) => accessors.y(d, e.key, dateKey)}
                                fillOpacity={0.4}
                                curve={curve}
                                fill={colors?.[e.key]}
                                lineProps={{
                                    stroke: colors?.[e.key]
                                }}
                            />)
                        )}

                        {renderBarSeries && (entries.filter(e => !e.hide).slice(0, 1).map(e => <BarSeries
                                key={e.key}
                                dataKey={e.key}
                                data={data}
                                xAccessor={(d) => accessors.x(d, e.key, dateKey)}
                                yAccessor={(d) => accessors.y(d, e.key, dateKey)}
                                colorAccessor={colorAccessorFactory(e.key)}
                            />)
                        )}

                        {/*<MemoizedSeries props={seriesProps}/>*/}

                        {renderBarGroup && (
                            <BarGroup>
                                {visibleSeries.map(e => <BarSeries
                                    key={e.key}
                                    dataKey={e.key}
                                    data={data}
                                    xAccessor={(d) => accessors.x(d, e.key, dateKey)}
                                    yAccessor={(d) => accessors.y(d, e.key, dateKey)}
                                    colorAccessor={colorAccessorFactory(e.key)}
                                />)}
                            </BarGroup>
                        )}

                        {renderBarStack && (
                            <BarStack offset={stackOffset}>
                                {visibleSeries.map(e => <BarSeries
                                    key={e.key}
                                    dataKey={e.key}
                                    data={data}
                                    xAccessor={(d) => accessors.x(d, e.key, dateKey)}
                                    yAccessor={(d) => accessors.y(d, e.key, dateKey)}
                                    colorAccessor={colorAccessorFactory(e.key)}
                                />)}
                            </BarStack>
                        )}


                        <Axis
                            key={`time-axis-${animationTrajectory}-${renderHorizontally}`}
                            label={xAxisName}
                            orientation={renderHorizontally ? yAxisOrientation : xAxisOrientation}
                            tickValues={(renderBarGroup || renderBarSeries || renderBarStack) ?
                                undefined :
                                getTimeTicks(!renderHorizontally ? width : height!, data, dateKey, tickTimeFormat.width)}
                            tickFormat={(d) => {
                                return moment(d).format(tickTimeFormat.format)
                            }}
                            animationTrajectory={animationTrajectory}
                            // tickLabelProps={() => ({
                            //     // transform: 'rotate(-15) translate(0 10)'
                            // })}
                        />
                        <Axis
                            key={`temp-axis-${animationTrajectory}-${renderHorizontally}`}
                            label={yAxisName}
                            orientation={renderHorizontally ? xAxisOrientation : yAxisOrientation}
                            //numTicks={renderHorizontally ? numTicks : undefined}
                            animationTrajectory={animationTrajectory}
                            // values don't make sense in stream graph
                            tickFormat={stackOffset === 'wiggle' ? () => '' : undefined}
                        />

                        {renderGlyphSeries && glyphs.filter(g => !g.hide).map((g, i) => <GlyphSeries
                                key={g.key}
                                dataKey={g.key}
                                data={data}
                                xAccessor={(d) => accessors.x(d, g.key, dateKey)}
                                yAccessor={(d) => accessors.y(d, g.key, dateKey)}
                                renderGlyph={(p) => renderGlyph(p, g, theme, g.key)}
                                colorAccessor={colorAccessorFactory(g.key)}
                            />
                        )}

                        {showTooltip && (
                            <Tooltip<NormalObject>
                                showHorizontalCrosshair={showHorizontalCrosshair}
                                showVerticalCrosshair={showVerticalCrosshair}
                                snapTooltipToDatumX={snapTooltipToDatumX}
                                snapTooltipToDatumY={snapTooltipToDatumY}
                                showDatumGlyph={(snapTooltipToDatumX || snapTooltipToDatumY) && !renderBarGroup}
                                showSeriesGlyphs={sharedTooltip && !renderBarGroup}
                                renderGlyph={(p) => renderTooltipGlyph(p, settings.tooltipGlyphComponent, settings.colors)}
                                renderTooltip={({tooltipData, colorScale}) => (
                                    <>
                                        {/** date */}
                                        {(tooltipData?.nearestDatum?.datum &&
                                                tooltipData?.nearestDatum?.datum['label']) ||
                                            'No date'}
                                        <br/>
                                        <br/>
                                        {/** temperatures */}
                                        {(
                                            (sharedTooltip
                                                    ? Object.keys(tooltipData?.datumByKey ?? {})
                                                    : [tooltipData?.nearestDatum?.key]
                                            ).filter((o) => o) as string[]
                                        ).map((object) => {
                                            const value =
                                                tooltipData?.nearestDatum?.datum &&
                                                accessors[renderHorizontally ? 'x' : 'y'](
                                                    tooltipData?.nearestDatum?.datum, object, dateKey
                                                );

                                            return (
                                                <div key={object}>
                                                    <em
                                                        style={{
                                                            color: colors?.[object],
                                                            textDecoration:
                                                                tooltipData?.nearestDatum?.key === object ? 'underline' : undefined,
                                                        }}
                                                    >
                                                        {entries.find(x => x.key === object)!.lang[LANG]}
                                                    </em>{' - '}
                                                    {value == null || Number.isNaN(value)
                                                        ? '–'
                                                        : value}
                                                </div>
                                            );
                                        })}
                                    </>
                                )}
                            />
                        )}

                    </XYChart>}
                    {settings.usePie &&
                        <PieView width={(width - (XY_GAP * (1))) / actualSlots} whole={settings.pieWhole}
                                 usePieNumberPercentage={settings.usePieNumberPercentage!}
                                 useNumberLabel={settings.usePieNumberLabel!}
                                 shortNames={settings.pieShortNames}
                                 names={fullNamesForEntries(entries)}
                                 height={height!} colors={settings.colors!}
                                 data={shapeDataForPie(data,visibleSeries)}/>}
                </>
            )}
        </ExampleControls>
    );
}

function shapeDataForPie(input: NormalObject[], entries: ChartEntry[]): PieEntry[] {

    const map = entries.reduce((p, c) => {
        p[c.key] = 0
        return p
    }, {} as any)

    const keys = entries.map(x => x.key)
    for (const o of input) {
        for (const key of keys) {
            map[key] = map[key] + o[key]
        }
    }
    return Object.entries(map).map(([k, v]) => ({label: k, value: v as number}))
}

export function fullNamesForEntries(entries: ChartEntry[]) {
    let res = {} as any

    for (const entry of entries) {
        res[entry.key] = entry.lang[LANG]
    }

    return res
}

const renderTooltipGlyph =
    ({
         x,
         y,
         key,
         size,
         color,
         onPointerMove,
         onPointerOut,
         onPointerUp,
     }: RenderTooltipGlyphProps<NormalObject>,
     tooltipGlyphComponent: string | undefined,
     colors: Strings | undefined) => {
        const handlers = {onPointerMove, onPointerOut, onPointerUp};
        if (Glyphs[tooltipGlyphComponent as KeyOfGlyphs] !== undefined) {
            const CurrGlyph = Glyphs[tooltipGlyphComponent as KeyOfGlyphs];
            return <CurrGlyph
                left={x}
                top={y}
                stroke={'#000'}
                fill={colors?.[key] || color}
                size={size * 24}
                {...handlers}
            />
        }

        return <text x={x} y={y} dx="-.6em" dy="0.25em" fontSize={14} stroke={'#000'}
                     strokeWidth="4"
                     paintOrder="stroke"
                     {...handlers}>
            {tooltipGlyphComponent}
        </text>
    };

function renderGlyph({
                         datum,
                         x,
                         y,
                         size,
                         color,
                         onPointerMove,
                         onPointerOut,
                         onPointerUp,
                     }: GlyphProps<NormalObject>, glyph: GlyphI, theme: XYChartTheme, key: string) {
    const handlers = {onPointerMove, onPointerOut, onPointerUp};

    if (datum[key] === 0)
        return null

    if (Glyphs[glyph.value as KeyOfGlyphs] !== undefined) {
        const CurrGlyph = Glyphs[glyph.value as KeyOfGlyphs];
        return <CurrGlyph
            left={x}
            top={y}
            stroke={'#000'}
            fill={color}
            size={size * 16}
            {...handlers}
        />
    }

    return (
        <text x={x} y={y} dx="-.6em" dy="0.25em" fontSize={14} stroke={'#000'}
              strokeWidth="4"
              paintOrder="stroke"
              {...handlers}>
            {glyph.value}
        </text>
    );
}
