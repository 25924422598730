import React from "react";
import {GetColumnValueFuncI} from "../Types";
import CreateSeminar from "./actions/CreateSeminar";
import Act287SummaryPage from "./Act287SummaryPage";
import {inmsg287DefFilters} from "./tables/InmsgTable";
import {orders287DefFilters} from "./tables/OrdersTable";
import {ordersGrouped287DefFilters} from "./tables/OrdersGroupedTable";
import {users287DefFilters} from "./tables/UsersTable";
import {photos287DefFilters} from "./tables/PhotosTable";
import {seminars287DefFilters} from "./tables/SeminarsTable";
import {seminarRegs287DefFilters} from "./tables/SeminarRegsTable";
import {prizes287DefFilters} from "./tables/PrizesTable";
import {webinars287DefFilters} from "./tables/WebinarsTable";
import {viberMap287DefFilters} from "./tables/ViberMapTable";
import CreateViberSpam from "./actions/CreateViberSpam";
import SendViberSpam from "./actions/SendViberSpam";
import {spam287DefFilters} from "./tables/SpamTable";
import {extraFields287DefFilters} from "./tables/ExtraFieldsTable";
import {extraActivation287DefFilters} from "./tables/ExtraActivationTable";
import CreateExtraActivation from "./actions/CreateExtraActivation";

export const act287Functions = {
    act287getInmsgCodeColumnValue: ((value, schema, forHeader = false, record = null) => {
        if (!!record
            && record['msgtype'] == '4'
            && record['balls'] == '0'
            && record['balls_for_first'] == '0'
            && (record['vac_level'] == '0' || record['vac_level'] === null)) {
            return <React.Fragment>
                {value}
                &nbsp;
                <small>❗</small>
            </React.Fragment>
        } else return value
    }) as GetColumnValueFuncI,
    act287createSeminarComponent: () => <CreateSeminar/>,
    act287createViberSpam: () => <CreateViberSpam/>,
    act287createExtraActivation: () => <CreateExtraActivation/>,
    act287sendSpam: () => <SendViberSpam/>,
    Act287SummaryPage: () => <Act287SummaryPage/>,
    inmsg287DefFilters: () => inmsg287DefFilters(),
    orders287DefFilters: () => orders287DefFilters(),
    ordersGrouped287DefFilters: () => ordersGrouped287DefFilters(),
    users287DefFilters: () => users287DefFilters(),
    photos287DefFilters: () => photos287DefFilters(),
    seminars287DefFilters: () => seminars287DefFilters(),
    seminarRegs287DefFilters: () => seminarRegs287DefFilters(),
    prizes287DefFilters: () => prizes287DefFilters(),
    webinars287DefFilters: () => webinars287DefFilters(),
    viberMap287DefFilters: () => viberMap287DefFilters(),
    spam287DefFilters: () => spam287DefFilters(),
    extraFields287DefFilters: () => extraFields287DefFilters(),
    extraActivation287DefFilters: () => extraActivation287DefFilters(),
} as { [p: string]: (...args: any[]) => any }
