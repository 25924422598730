

type DataObject = Record<string, any>;


export function handleFetchResponseStatus(response: Response): Promise<Response> {
    if (response.status === 401) {
        // Redirect to login page for unauthorized status
        window.location.href = '/login.html';
    }

    if (response.status >= 400 && response.status < 500) {
        // Handle other client errors
        return Promise.reject(new Error(`Client Error: ${response.statusText}`));
    }

    if (response.status >= 500) {
        // Handle server errors
        return Promise.reject(new Error(`Server Error: ${response.statusText}`));
    }

    // If response is OK, resolve the promise
    return Promise.resolve(response);
}

export function takeBefore<T extends DataObject>(
    data: T,
    stopKey: keyof T,
    limit: number
): Partial<T> {
    const entries = Object.entries(data) as [keyof T, T[keyof T]][];
    const result: Partial<T> = {};

    // Find the index of the stopKey
    const stopIndex = entries.findIndex(([key]) => key === stopKey);
    if (stopIndex === -1) {
        throw new Error(`Key "${String(stopKey)}" not found in the object.`);
    }

    // Determine the range of keys to take
    const start = Math.max(0, stopIndex - limit);
    const selectedEntries = entries.slice(start, stopIndex);

    // Populate the result object
    for (const [key, value] of selectedEntries) {
        result[key] = value;
    }

    return result;
}
