import {ChartI, XyChartI} from "../../Types";
import {GREEN_COLOR, RED_COLOR} from "../../components/CONSTANTS";

const inmsgUniqueUsers: ChartI<XyChartI> = {
    id: "inmsgUniqueUsers",
    queryId: "inmsgUniqueUsers",
    lang: {
        ua: 'Унікальних користувачів та реєстрацій'
    },
    type: "xy",
    height: 450,
    parameters: {
        periods: ["Day1", "Day2", "Day3", "Day7", "Day14", "Month1", "Month3"],
        selectedPeriod: "Day7",
        selectedInterval: "Day1",
        filters: {
            from: {
                lang: {ua: 'З'},
                key: 'from',
                type: 'datetime'
            },
            to: {
                lang: {ua: 'До'},
                key: 'to',
                type: 'datetime'
            }
        }
    },
    settings: {
        usePie: false,
        pieWhole: false,
        usePieNumberLabel: true,
        usePieNumberPercentage: false,
        pieShortNames: {
            inmsg_count: 'Усп. реєстр.',
            msisdn_count: 'Унік. кор.',
        },
        entries: [
            {
                key: 'inmsg_count',
                lang: {
                    ua: 'Успішних реєстрацій'
                }
            }, {
                key: 'msisdn_count',
                lang: {
                    ua: 'Унікальних користувачів'
                }
            },
        ],
        colors: {
            inmsg_count: GREEN_COLOR,
            msisdn_count: RED_COLOR,
        },
        glyphs: [
            {
                key: 'inmsg_count',
                value: 'circle'
            },
            {
                key: 'msisdn_count',
                value: 'star'
            },
        ],
        barType: "none",
        lineType: "area",
        curveShape: 'cardinal',
        xAxisOrientation: 'top',
        tooltipGlyphComponent: "🐋"
    }
}

export default inmsgUniqueUsers
