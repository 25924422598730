import {PaneType} from "../../Types";
import ChartSideInfo from "../charts/XY/ChartSideInfo";

export default function GraphAsidePaneInfo({id, type}: { id: string, type: PaneType }) {

    switch (type) {
        case "xyChart":
            return <ChartSideInfo chartId={id} type={'xy'}/>
        case "pieChart":
            return <ChartSideInfo chartId={id} type={'pie'}/>
        default:
            break;
    }

    return null
}
