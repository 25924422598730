import React, {useState} from 'react';
import {Field} from '../../Types';
import UpdateFieldButton from "../table/UpdateFieldButton";
import styled from "styled-components";
import {useAppDispatch} from "../../hooks";
import {updateSingleTableField} from "../../features/tableSlice";

function EditStringNumber({value, schema, pk, closeEditing}: {
    value: string | number,
    schema: Field,
    pk: string | number,
    closeEditing: () => void
}) {

    let [isChanged, setIsChanged] = useState(false);
    let [newValue, setNewValue] = useState<string | number>(value ?? '');

    function onChange(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, isNumber = false) {
        let val = e.currentTarget.value;
        if (isNumber) {
            const parsedValue = parseFloat(val);
            setNewValue(isNaN(parsedValue) ? 0 : parsedValue);
        } else {
            setNewValue(val);
        }
        val !== value ? setIsChanged(true) : setIsChanged(false)
    }

    const dispatch = useAppDispatch();

    function updateField() {
        dispatch(updateSingleTableField({field: schema.key, value: newValue, id: pk}))
        closeEditing()
    }

    let element;

    switch (schema.type) {
        case 'string':
        case "json":
            element = (
                <textarea className="edit-textarea" rows={schema.type === 'json' ? 20 : 4}
                          value={newValue} onChange={(e) => onChange(e)}/>
            );
            break;

        case 'number':
            element = (
                <input type="number" className="edit-input-number" value={newValue}
                       onChange={(e) => onChange(e, true)}/>
            );
            break;

        case 'url':
        case 'tel':
        case 'email':
        case 'image':
            element = (
                <input type={schema.type === 'url' ? 'text' : schema.type === 'email' ? 'email' : 'tel'}
                       className="edit-input-text"
                       value={newValue}
                       onChange={(e) => onChange(e)}/>
            );
            break;

        default:
            break;
    }
    return <Container>
        <div className="wrapper">
            <UpdateFieldButton disabled={!isChanged} onClick={updateField}/>
            {element}
        </div>
    </Container>
}

export default EditStringNumber;

const Container = styled.div`

    & .wrapper {
        display: flex;
        gap: .5rem;
    }


`
