import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Strings} from "../Types";
import {KeyOfIcons} from "../components/CONSTANTS";

export type PageI = {
    custom?: boolean;
    name: string,
    icon?: KeyOfIcons
    lang: Strings
    func?: string
    disabled?: boolean
};

interface ProjectI {
    name: string;
    isUserAdmin: boolean
    activePage: number
    pages: PageI[];
    iconsStyle: string
}

const initialState: ProjectI = {
    name: 'act287',
    isUserAdmin: false,
    iconsStyle: 'fontAwesome',
    activePage: 0,
    pages: [
        {
            name: 'table',
            lang: {
                ua: 'Таблиці'
            },
            icon: 'table'
        },
        {
            name: 'summary',
            lang: {
                ua: 'Сумарно'
            },
            func: 'Act287SummaryPage',
            icon: 'rectangleList'
        },
        {
            name: 'graph',
            lang: {
                ua: 'Дошка'
            },
            disabled: false,
            icon: 'chartBar'
        }
    ],
}

const ADMIN_IDS = ['1', '150'];


export const projectSlice = createSlice({
    name: 'project',
    initialState,
    reducers: {
        setActivePage(state, action: PayloadAction<number>) {
            state.activePage = action.payload
        },
        setIconsStyle(state, action: PayloadAction<string>) {
            state.iconsStyle = action.payload
        },
        setIsUserAdmin(state, action: PayloadAction<string>) {
            state.isUserAdmin = ADMIN_IDS.includes(action.payload)
        }
    },
})

// Action creators are generated for each case reducer function
export const {setActivePage, setIconsStyle, setIsUserAdmin} = projectSlice.actions

export default projectSlice.reducer
