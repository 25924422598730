import {FieldOperations, OpsCategories, TableI} from "../../Types";


let fieldOperations: FieldOperations = {
    dt: [...OpsCategories.range],
    id: [...OpsCategories.eq, ...OpsCategories.range, ...OpsCategories.in,],
};

export const CertificatesTable: TableI = {
    backend: "/287/",
    title: {
        ua: "Сертифікати",
        en: "Замовлення",
    },
    pk: 'id',
    hidden: true,
    defaultOrderedBy: "id",
    defaultOrderedWay: "DESC",
    defaultPerPage: 20,
    orderByFields: {
        "id": {ua: "ID", en: "№"},
        "dt": {ua: "Створено", ru: "Створено"},
    },
    fieldOperations: fieldOperations,
    fields: [
        {
            key: "n",
            inline: true,
            title: {ua: "№", ru: "№"}, type: "number",
            applyTransform: true
        },
        {
            key: "dt",
            title: {ua: "Створено", ru: "Дата"},
            type: "datetime",
            datetimeFormat: "DD MMMM YYYY HH:mm",
            applyTransform: true,
            inline: true,
            detailed: true
        },
        {
            key: "msisdn", type: 'tel',
            applyTransform: true,
            detailed: true, inline: true,
            title: {ua: "Телефон", ru: "Телефон"}
        },
        {
            key: "certificate",
            title: {ua: "Сертифікат", ru: "Тема"},
            detailed: true,
            inline: true,
            type: 'string'
        },
        {
            key: "comment",
            title: {ua: "Коментар", ru: "Тема"},
            detailed: true,
            inline: true,
            type: 'string'
        },
        {
            key: "id",
            detailed: true,
            title: {ua: "ID", ru: "№"},
            type: "number",
            applyTransform: true
        },
    ]
}
