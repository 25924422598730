import {useAppDispatch, useAppSelector} from "../../hooks";
import React from "react";
import {PjTa} from "../../Types";
import styled from "styled-components";
import {schemas} from "../../config";
import {fetchTableByQuery, fetchXls} from "../../features/tableSlice";
import TableOrderBy from "../order/TableOrderBy";
import XlsIcon from "../../icons/XlsIcon";
import {faArrowsRotate} from "@fortawesome/free-solid-svg-icons";
import Icon from "../misc/Icon";

const lang = 'ua'

const defValue = (projectName: string, tableName: string) => {
    let field = schemas[projectName].tables[tableName].defaultOrderedBy;
    return {
        label: field
    }
}

function TableControls({tableName, projectName}: PjTa) {


    const dispatch = useAppDispatch();

    let status = useAppSelector(state => state.tables.tableInfo[tableName].loading)

    const fetchTable = () => {
        dispatch(fetchTableByQuery({clean: true, pageIndex: 1}))
    }

    const getXls = () => {
        dispatch(fetchXls({pageIndex: 1}))
    }



    return (
        <Container>
            <div className="fake"></div>
            <div className={'main-control'}>
                <button disabled={status !== 'idle'} className={'update-btn'} onClick={fetchTable}>
                    <span>Оновити</span>
                    <Icon src={"/win-icons/Mail stamp (move).ico"}
                          title={'Додати фільтр'} alt={'Додати фільтр'}
                          width={'1.2rem'}
                          height={'1.2rem'}
                          component={faArrowsRotate}
                    />
                </button>
                {/*<hr className={'divider'}></hr>*/}
                <button className={'download'} onClick={getXls}>
                    <span className={'load-text'}>Завантажити</span>
                    <div className={'xls-btn'}>
                        <XlsIcon width={'1.5rem'} height={'1.5rem'}/>
                    </div>
                </button>

            </div>
            <div className="orderBy">
                <TableOrderBy projectName={projectName} tableName={tableName}/>
            </div>
        </Container>
    )
}

const Container = styled.div`

    display: flex;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    justify-content: end;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
    
    & .fake {
            flex-shrink: 0;
            flex-basis: 16rem;
            @media (max-width: 1024px) {
                display: none;
            }
    }

    & .main-control {
        flex-grow: 1;
        display: flex;
        align-items: stretch;
        justify-content: center;
        gap: .5rem;
    }

    & .divider {
        width: 4px;
        border-radius: 2px;
        margin: 0;
        background: var(--table-action-color);
    }

    & .xls-btn {
        //background: var(--table-row-text-color);
        padding: .2rem .4rem;
        border-radius: .2rem;
        //border: 2px solid var(--table-border-color);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    & .download {
        background: transparent;
        color: var(--table-action-color);
        padding: .2rem .5rem;
        border-radius: .2rem;
        font-size: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: .2rem;
        width: 100%;
        max-width: 200px;
        cursor: pointer;
        border: none;
        border-bottom: 3px solid var(--table-action-color);

        @media (max-width: 500px) {
            max-width: 50px;
        }
        
        & .load-text {
            @media (max-width: 500px) {
                display: none;
            }
        }
        
        &:hover {
            filter: brightness(.85);
        }
    }
    
    & .fetch-btn {
        background: var(--table-action-color);
        color: var(--table-header-bg-color);
        padding: .5rem .5rem;
        border-radius: .2rem;
        font-size: 1rem;
        font-weight: 600;
        width: 100%;
        cursor: pointer;
        max-width: 800px;
        border: 2px solid transparent;

        &:hover {
            filter: brightness(.85);
        }
    }

    & .update-btn {
        background: transparent;
        color: var(--table-row-text-color);
        padding: .2rem .5rem;
        border-radius: .2rem;
        font-size: 1rem;
        width: 100%;
        cursor: pointer;
        max-width: 200px;
        border: none;
        border-bottom: 3px solid var(--table-action-color);
        
        display: flex;
        align-items: center;
        justify-content: center;
        gap: .2rem;
        &:hover {
            filter: brightness(.85);
        }
        
        &[disabled] {
            filter: grayscale(.5);
        }
        
        & img {
            flex-shrink: 0;
        }
    }

    & .orderBy {
        flex-shrink: 0;
        flex-basis: 16rem;

        @media (max-width: 500px) {
            flex-basis: 100%;
            order: -1;
        }
        //margin-left: auto;
    }


`

export default TableControls
