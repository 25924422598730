import {ChartI, XyChartI} from "../../Types";

const inmsgChannelLong: ChartI<XyChartI> = {
    id: "inmsgChannelLong",
    queryId: "inmsgChannel",
    lang: {
        ua: 'Успішні реєстрації за каналами (довгий період)'
    },
    type: "xy",
    height: 450,
    parameters: {
        periods: ["Month6", "Year1", "Year2", "Year3", "Year5", "Year7"],
        selectedPeriod: "Year3",
        selectedInterval: "Month3",
        filters: {
            from: {
                lang: {ua: 'З'},
                key: 'from',
                type: 'datetime'
            },
            to: {
                lang: {ua: 'До'},
                key: 'to',
                type: 'datetime'
            }
        }
    },
    settings: {
        usePie: false,
        pieWhole: false,
        usePieNumberLabel: false,
        usePieNumberPercentage: true,
        entries: [
            {
                key: 'web',
                lang: {
                    ua: 'WEB'
                }
            }, {
                key: 'viber',
                lang: {
                    ua: 'VIBER'
                }
            }
        ],
        colors: {
            'web': '#238dfe',
            'viber': '#bc30ee',
        },
        glyphs: [],
        barType: "barstack",
        lineType: "none",
        tooltipGlyphComponent: "🐋"
    }
}

export default inmsgChannelLong
