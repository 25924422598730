export default function userPrefersReducedMotion() {
    const prefersReducedMotionQuery =
        typeof window === 'undefined' ? false : window.matchMedia('(prefers-reduced-motion: reduce)');
    return !prefersReducedMotionQuery || !!prefersReducedMotionQuery.matches;
}

export function userPrefersDarkTheme() {
    const prefersDarkTheme =
        typeof window === 'undefined' ? false : window.matchMedia('(prefers-color-scheme: dark)');
    return !prefersDarkTheme || !!prefersDarkTheme.matches;
}
