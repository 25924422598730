import {PaneI} from "./GraphLayout";
import EmptyPane, {PaneContainer} from "./EmptyPane";
import ExChart from "../charts/ExChart";
import React from "react";

export default function Pane({pane, mountDelay = 0}: { pane: PaneI, mountDelay?: number }) {

    let element

    switch (pane.type) {
        case "xyChart":
        case "pieChart":
            element = <ExChart pane={pane} mountDelay={mountDelay}/>
            break
        case "none":
            element = <EmptyPane slots={pane.slots}/>
            break
        default:
            element = <EmptyPane slots={pane.slots}/>
    }

    return <PaneContainer $hide={pane.type === 'none'}>
        {element}
    </PaneContainer>

}
