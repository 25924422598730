import {faViber} from "@fortawesome/free-brands-svg-icons";
import {faChartBar, faGlobe, faRectangleList, faTableList} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

export const API_BACK_URL = process.env.NODE_ENV === 'development' ? "https://192.168.0.106:8443/engine/php" : "/engine/php";

export const ASIDE_HEADER_WIDTH = 380
export const ASIDE_HEADER_WIDTH_MOBILE = 330
export const SIDE_BAR_THRESHOLD_WIDTH = 1280 - ASIDE_HEADER_WIDTH
export const BOTTOM_NAV_HEIGHT = 64
export const TOP_BAR_HEIGHT = 54

export const LANG = 'ua'

//export const SELECT_BACK_COLOR = '#383b3b';
export const SELECT_BACK_COLOR = '#3d3d42';
export const GREEN_COLOR = '#2FD183';
export const RED_COLOR = '#E03535';

export const XY_GAP = 8

export const ICONS = {
    viber: faViber,
    web: faGlobe,
    table: faTableList,
    rectangleList: faRectangleList,
    chartBar: faChartBar
} as const

export type KeyOfIcons = keyof typeof ICONS

export const DASHBOARD_PANE_MOUNT_DELAY = 400

const toNow = () => moment().startOf('day').add(1, 'day');
export const TIME_PERIODS = {
    Min15: {
        lang: {
            ua: '15хв',
            end: '',
        },
        value: 0,
        date: false,
        intervalVals: [15, 'minutes'] as const,
        getToNow: () => moment().startOf('hour').add(Math.floor(moment().minute() / 15) * 15, 'minutes'),
    },
    Hour1: {
        lang: {
            ua: '1год',
            end: '',
        },
        value: 1,
        date: false,
        intervalVals: [1, 'hours'] as const,
        getToNow: () => moment().startOf('hour'),
    },
    Hour6: {
        lang: {
            ua: '6год',
            end: '',
        },
        value: 2,
        date: false,
        intervalVals: [6, 'hours'] as const,
        getToNow: () => moment().startOf('day').add(Math.floor(moment().hour() / 6) * 6, 'hours'),
    },
    Day1: {
        lang: {
            ua: '1д',
            end: '',
        },
        value: 3,
        date: true,
        intervalVals: [1, 'days'] as const,
        getToNow: toNow,
    },
    Day2: {
        lang: {
            ua: '2д',
            end: '',
        },
        value: 3,
        date: true,
        intervalVals: [2, 'days'] as const,
        getToNow: toNow,
    },
    Day3: {
        lang: {
            ua: '3д',
            end: '',
        },
        value: 3,
        date: true,
        intervalVals: [3, 'days'] as const,
        getToNow: toNow,
    },
    Day7: {
        lang: {
            ua: '7д',
            end: '',
        },
        value: 4,
        date: true,
        intervalVals: [7, 'days'] as const,
        getToNow: toNow,
    },
    Day14: {
        lang: {
            ua: '14д',
            end: '',
        },
        value: 5,
        date: true,
        intervalVals: [14, 'days'] as const,
        getToNow: toNow,
    },
    Month1: {
        lang: {
            ua: '1міс',
            end: '',
        },
        value: 6,
        date: true,
        intervalVals: [1, 'months'] as const,
        getToNow: toNow,
    },
    Month3: {
        lang: {
            ua: '3міс',
            end: '',
        },
        value: 7,
        date: true,
        intervalVals: [3, 'months'] as const,
        getToNow: toNow,
    },
    Month6: {
        lang: {
            ua: '6міс',
            end: '',
        },
        value: 8,
        date: true,
        intervalVals: [6, 'months'] as const,
        getToNow: toNow,
    },
    Year1: {
        lang: {
            ua: '1р',
            end: '',
        },
        value: 9,
        date: true,
        intervalVals: [1, 'years'] as const,
        getToNow: toNow,
    },
    Year2: {
        lang: {
            ua: '2р',
            end: '',
        },
        value: 10,
        date: true,
        intervalVals: [2, 'years'] as const,
        getToNow: toNow,
    },
    Year3: {
        lang: {
            ua: '3р',
            end: '',
        },
        value: 11,
        date: true,
        intervalVals: [3, 'years'] as const,
        getToNow: toNow,
    },
    Year5: {
        lang: {
            ua: '5р',
            end: '',
        },
        value: 12,
        date: true,
        intervalVals: [5, 'years'] as const,
        getToNow: toNow,
    },
    Year7: {
        lang: {
            ua: '7р',
            end: '',
        },
        value: 13,
        date: true,
        intervalVals: [7, 'years'] as const,
        getToNow: toNow,
    },
    AllTime: {
        lang: {
            ua: 'Весь час',
            end: '',
        },
        value: 14,
        date: true,
        intervalVals: [0, ''] as const, // No specific interval
        getToNow: toNow,
    },
} as const;
export type KeyOfTimePeriods = keyof typeof TIME_PERIODS

export const INTERVALS_FOR_PERIODS = {
    Min15: [],
    Hour1: ["Min15"],
    Hour6: ["Min15", "Hour1"],
    Day1: ["Min15", "Hour1", "Hour6"],
    Day2: ["Min15", "Hour1", "Hour6"],
    Day3: ["Min15", "Hour1", "Hour6"],
    Day7: ["Hour1", "Hour6", "Day1"],
    Day14: ["Hour1", "Hour6", "Day1"],
    Month1: ["Hour6", "Day1"],
    Month3: ["Day1", "Month1"],
    Month6: ["Day1", "Month1"],
    Year1: ["Day1", "Month1", "Month3", "Month6"],
    Year2: ["Month1", "Month3", "Month6", "Year1"],
    Year3: ["Month1", "Month3", "Month6", "Year1"],
    Year5: ["Month1", "Month3", "Month6", "Year1"],
    Year7: ["Month1", "Month3", "Month6", "Year1"],
    AllTime: ["Day1"],
} as { [K in KeyOfTimePeriods]: KeyOfTimePeriods[] | never[] }
