import styled from "styled-components";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {setIconsStyle} from "../../features/projectSlice";


const primaryColors = [
    '#089DF8',
    '#337ab7',
    '#b97c4f',
    '#03a157',
    '#2568fc',
    '#fa4fa7',
    '#c05e5e',
    '#cb2a4d',
    '#cb882a',
    '#aeb611',
    '#7b4192',
    '#17ceb6',
    '#a80000',
    '#b50774',
]

export const themes = {
    classic: {
        name: 'Класична',
        values: {
            'table-header-bg-color': '#fff',
            'table-header-text-color': '#000',
            'table-row-even-bg-color': '#ffffff',
            'table-row-odd-bg-color': '#fff',
            'table-row-text-color': '#000',
            'table-footer-bg-color': '#fff',
            'secondary': '#002A46',
            'secondary-text': '#fff',
            'table-footer-text-color': '#000',
            'table-border-color': '#C9D5DB',
        }
    },
    dark: {
        name: 'Темна',
        values: {
            'table-header-bg-color': '#221f1f',
            'table-header-text-color': '#fff',
            'table-row-even-bg-color': '#222',
            'table-row-odd-bg-color': '#333',
            'table-row-text-color': '#eee',
            'table-footer-bg-color': '#333',
            'table-footer-text-color': '#fff',
            'table-border-color': '#000',
            'secondary': '#152026',
            'secondary-text': '#fff',
        }
    },
    black: {
        name: 'Чорна',
        values: {
            'table-header-bg-color': '#000000',
            'table-header-text-color': '#fff',
            'table-row-even-bg-color': '#111',
            'table-row-odd-bg-color': '#222',
            'table-row-text-color': '#eee',
            'table-footer-bg-color': '#000',
            'table-footer-text-color': '#fff',
            'table-border-color': '#666',
            'secondary': '#152026',
            'secondary-text': '#fff',
        }
    },
    aqua: {
        name: 'Аквамарин',
        values: {
            'table-header-bg-color': '#122750',
            'table-header-text-color': '#fff',
            'table-row-even-bg-color': '#293957',
            'table-row-odd-bg-color': '#384e77',
            'table-row-text-color': '#eee',
            'table-footer-bg-color': '#202d45',
            'table-footer-text-color': '#fff',
            'table-border-color': '#000000',
            'secondary': '#0f3b3c',
            'secondary-text': '#fff',
        }
    },

    peach: {
        name: 'Персик',
        values: {
            'table-header-bg-color': '#d95b63',
            'table-header-text-color': '#312f2f',
            'table-row-even-bg-color': '#f8a8ad',
            'table-row-odd-bg-color': '#f8a8ad',
            'table-row-text-color': '#312f2f',
            'table-footer-bg-color': '#d95b63',
            'table-footer-text-color': '#312f2f',
            'table-border-color': '#ae5b72',
            'secondary': '#b2797b',
            'secondary-text': '#fff',
        }
    },

    coffeine: {
        name: 'Пісок',
        values: {
            'table-header-bg-color': '#bd8223',
            'table-header-text-color': '#fff',
            'table-row-even-bg-color': '#908f6a',
            'table-row-odd-bg-color': '#696752',
            'table-row-text-color': '#eee',
            'table-footer-bg-color': '#655d37',
            'table-footer-text-color': '#fff',
            'table-border-color': '#201f1c',
            'secondary': '#6a491a',
            'secondary-text': '#fff',
        }
    },


} as {
    [key: string]: {
        name: string
        values: {
            [key: string]: string
        }
    }
}

export function setCssProp(prop: string, value: string) {
    document.documentElement.style.setProperty(prop, value);
}

function setTheme(theme: string) {
    Object.entries(themes[theme].values).forEach(([prop, val]) => {
        setCssProp('--' + prop, val)
    })
    localStorage.setItem('theme', theme)
}

function setPrimaryColor(color: string) {
    setCssProp('--table-action-color', color)
    localStorage.setItem('primary-color', color)
}

export default function ThemeChanger() {

    let iconStyle = useAppSelector(state => state.project.iconsStyle);
    const dispatch = useAppDispatch();

    function onIconStyleChange(style: string) {
        localStorage.setItem('iconStyle', style)
        dispatch(setIconsStyle(style))
    }

    return <Container>
        <div className={'text'}>Тема</div>
        <div className="themes">
            {Object.entries(themes).map(([key, theme]) => <button className="theme" key={key}
                                                                  onClick={() => setTheme(key)}>
                <div className="name">{theme.name}</div>
                <div className="color" style={{backgroundColor: theme.values["table-row-odd-bg-color"]}}/>
            </button>)}
        </div>
        <div className={'text'}>Основний колір</div>
        <div className="prime">
            {primaryColors.map(c => <button style={{backgroundColor: c}} key={c}
                                            onClick={() => setPrimaryColor(c)}></button>)}
        </div>
        {/*<div>*/}
        {/*    <div>Стиль іконок</div>*/}
        {/*    <form>*/}
        {/*        <div>*/}
        {/*            <label htmlFor="">Win95</label>*/}
        {/*            <input type="radio" value={'win95'}*/}
        {/*                   onChange={(e) => onIconStyleChange(e.currentTarget.value)}*/}
        {/*                   checked={iconStyle === 'win95'}/>*/}
        {/*        </div>*/}
        {/*        <div>*/}
        {/*            <label htmlFor="">FontAwesome</label>*/}
        {/*            <input type="radio" value={'fontAwesome'}*/}
        {/*                   onChange={(e) => onIconStyleChange(e.currentTarget.value)}*/}
        {/*                   checked={iconStyle === 'fontAwesome'}/>*/}
        {/*        </div>*/}
        {/*    </form>*/}
        {/*</div>*/}
    </Container>
}


const Container = styled.div`

    display: flex;
    flex-flow: column;
    gap: .5rem;
    border-top: 2px solid var(--table-action-color);
    border-bottom: 2px solid var(--table-action-color);
    padding-top: .4rem;
    padding-bottom: .4rem;

    & .text {
        font-size: 1.2rem;

    }

    & .themes {
        display: flex;
        flex-wrap: wrap;
        gap: .5rem;

        & .theme {
            padding: .4rem;
            border-radius: .2rem;
            background-color: var(--table-row-even-bg-color);
            border: none;
            cursor: pointer;
            display: flex;
            gap: .4rem;
            color: var(--table-row-text-color);
            align-items: center;
            font-size: 1.2rem;

            &:hover {
                filter: brightness(.85);
            }

            & .color {
                width: 1.5rem;
                height: 1.5rem;
                border-radius: 50%;
                border: 2px solid white;

            }
        }
    }

    & .prime {
        display: flex;
        flex-wrap: wrap;
        gap: .5rem;

        button {
            width: 1.5rem;
            height: 1.5rem;
            border-radius: 50%;
            border: 2px solid white;
            cursor: pointer;

            &:hover {
                filter: brightness(.85);
            }
        }
    }
`
