import {ChartEntry} from "../../../Types";
import {LANG} from "../../CONSTANTS";
import styled from "styled-components";
import {DataContext} from "@visx/xychart";
import {useContext, useEffect, useState} from "react";

interface LegendI {
    colorFunction: (key: string) => (string | null)
    entries: ChartEntry[]
}

export default function Legend({entries, colorFunction}: LegendI) {

    const {colorScale,} = useContext(DataContext);

    const [colors, setColors] = useState<string[] | undefined>(undefined)

    useEffect(() => {
        if (colorScale) {
            setColors(colorScale.range())
        }
    }, [colorScale]);

    return <Container>
        {colors && entries.filter(x => !x.hide).map((e, i) => <li key={e.key}>
            <div className={'square'} style={{background: colorFunction(e.key) || colors[i % colors.length]}}/>
            <div>{e.lang[LANG]}</div>
        </li>)}
    </Container>
}

const Container = styled.ul`
    display: flex;
    gap: .5rem;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: .5rem;

    & li {

        display: flex;
        gap: .2rem;
        align-items: center;

        & .square {
            width: 1rem;
            height: 1rem;
        }
    }
`
