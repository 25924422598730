import {FieldMap, FieldOperations, OpsCategories, SqlOperation, TableI} from "../../Types";
import {nanoid} from "@reduxjs/toolkit";


let fieldOperations: FieldOperations = {
    id: [...OpsCategories.eq, ...OpsCategories.range, ...OpsCategories.in,],
    dt: [...OpsCategories.range],
    msisdn: [...OpsCategories.eq, ...OpsCategories.like, ...OpsCategories.in,],
    prize_id: [...OpsCategories.in],
    rate: [...OpsCategories.range],
};

export function ordersGrouped287DefFilters() {
    return [
        {
            nanoid: nanoid(),
            field: 'msisdn',
            type: 'tel',
            filterOp: SqlOperation.LIKE,
            locked: true,
            value: ''
        }
    ]
}

export const OrdersGroupedTable: TableI = {
    backend: "/287/",
    title: {
        ua: "Замовлення групові",
        en: "Замовлення",
    },
    pk: 'id',
    hidden: true,
    defaultOrderedBy: "id",
    defaultOrderedWay: "DESC",
    defaultPerPage: 20,
    orderByFields: {
        "id": {ua: "ID", en: "№"},
        "dt": {ua: "Створено", ru: "Дата"},
        "rate": {ua: "Ціна", ru: "Уровень"},
    },
    fieldOperations: fieldOperations,
    defaultFilters: 'ordersGrouped287DefFilters',
    fields: [
        {
            key: "n",
            inline: true,
            title: {ua: "№", ru: "№"}, type: "number",
            applyTransform: true
        }, {
            key: "id",
            detailed: true,
            title: {ua: "ID", ru: "№"},
            type: "number",
            applyTransform: true
        },
        {
            key: "dt",
            title: {ua: "Дата", ru: "Дата"},
            type: "datetime",
            datetimeFormat: "DD MMMM YYYY HH:mm",
            applyTransform: true,
            detailed: true,
            inline: true,
        },
        {
            key: "msisdn", type: 'tel',
            applyTransform: true,
            detailed: true, inline: true, title: {ua: "Телефон", ru: "Телефон"}
        },
        {
            key: "prize_id",
            title: {ua: "Подарунок", ru: "Уровень"},
            detailed: true,
            inline: true,
            applyTransform: true,
            type: "map",
            map: {
                async: 'all_prizes_grouped'
            } as FieldMap,
        },
        {key: "rate", type: "number", title: {ua: "Ціна", ru: "Балів"}, detailed: true, inline: true},
        {key: "balls_sum", type: "number", title: {ua: "Сума балів", ru: "Балів"}, detailed: true, inline: true},
        {key: "msisdn_count", type: "number", title: {ua: "Учасників", ru: "Балів"}, detailed: true, inline: true},
        // {
        //     key: "dt_completed",
        //     title: {ua: "Дата формування", ru: "Дата формирования"},
        //     type: "datetime",
        //     datetimeFormat: "DD MMMM YYYY HH:mm",
        //     applyTransform: true,
        //     detailed: true,
        //     inline: true,
        // },
    ]
}
