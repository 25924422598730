import MyXyChart from "./XY/MyXyChart";
import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {useAppDispatch, useAppSelector} from "../../hooks";
import EmptyPane from "../graphs/EmptyPane";
import {ChartInternalTypes} from "../../Types";
import {LANG} from "../CONSTANTS";
import {PaneI} from "../graphs/GraphLayout";
import MyPieChart from "./Pie/MyPieChart";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLineChart, faPieChart} from "@fortawesome/free-solid-svg-icons";
import {fetchChartData} from "../../features/chartsSlice";

export default function ExChart({mountDelay = 0, pane}: {
    mountDelay?: number,
    pane: PaneI
}) {
    const containerRef = useRef(null);
    const [dimensions, setDimensions] = useState<{ width: number, height: number }>({width: 0, height: 0});
    const [isReady, setIsReady] = useState(mountDelay === 0)
    const isDashboardLowRender = useAppSelector(state => state.layout.isDashboardLowRender);
    const chartStatus = useAppSelector(state => state.charts.status[pane.id].status);
    const chartData = useAppSelector(state => state.charts.data[pane.id]);
    const name = useAppSelector(state => state.charts.entries[pane.id].lang)
    const height = useAppSelector(state => state.charts.entries[pane.id].height)
    const type = useAppSelector(state => state.charts.entries[pane.id].type)

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (isReady && chartStatus === 'idle') {
            dispatch(fetchChartData({chartId: pane.id}))
        }
    }, [chartStatus, dispatch, isReady, pane.id]);

    useEffect(() => {
        let timeout: NodeJS.Timeout | undefined
        if (!isReady && dimensions.width > 0) {
            timeout = setTimeout(() => {
                setIsReady(true)
            }, mountDelay);
        }

        return () => {
            if (timeout)
                clearTimeout(timeout)
        }
    }, [dimensions.width, isReady, mountDelay]);

    useEffect(() => {
        const updateDimensions = (entries: any) => {
            for (let entry of entries) {
                const {width, height} = entry.contentRect;
                setDimensions({width, height});
            }
        };

        const observer = new ResizeObserver(updateDimensions);

        if (containerRef.current) {
            observer.observe(containerRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, [isDashboardLowRender]);

    if (isDashboardLowRender)
        return <EmptyPane slots={pane.slots} label={name[LANG]} icon={type === 'xy' ? faLineChart : faPieChart}/>

    let renderChart

    if (chartData && chartData.length > 0)
        switch (pane.type) {
            case "xyChart":
                if (chartData.length > 1)
                    renderChart =
                        <MyXyChart data={chartData} width={dimensions.width - 16} chartId={pane.id}
                                   slots={pane.slots}/>
                else
                    renderChart = <IdleChart height={height! + 60} type={type} text={'Замало даних для періоду'}/>
                break
            case "pieChart":
                renderChart =
                    <MyPieChart data={chartData} width={dimensions.width - 16} chartId={pane.id} slots={pane.slots}/>
                break
            default:
                renderChart = <div>NO SUCH CHART IMPLEMENTATION YET!</div>
        }
    if (chartData && chartData.length === 0)
        renderChart = <IdleChart height={height! + 60} type={type} text={'Замало даних для періоду'}/>

    return (
        <Container ref={containerRef}>
            {isReady && chartStatus === 'loaded' && dimensions.width > 0 && renderChart}
            {chartStatus === 'loading' && <IdleChart height={height! + 60} type={type}/>}
            {chartStatus === 'error' && <IdleChart height={height! + 60} type={type} text={'Сталась помилка'}/>}
            {!isReady && (chartStatus === 'idle') && dimensions.width > 0 &&
                <IdleChart height={height! + 60} type={type}/>}
        </Container>
    )


}
const Container = styled.div`
    height: 100%;
    min-height: 1px;
    min-width: 1px;


    .pattern-lines {
        position: absolute;
        pointer-events: none;
        opacity: 0;
        top: 0;
        left: 0;
    }

`

function IdleChart({type, height, text}: { type: ChartInternalTypes, height: number, text?: string }) {

    return <IdleContainer style={{height: height + 'px'}}>
        {text && <div>{text}</div>}
        <FontAwesomeIcon title={'line-chart'} icon={type === 'xy' ? faLineChart : faPieChart}
                         style={{width: 'auto', height: '40%'}}
                         className={'fa-color'}/>
    </IdleContainer>
}

const IdleContainer = styled.div`
    display: flex;
    flex-flow: column;
    gap: .5rem;
    justify-content: center;
    align-items: center;
    background: var(--table-row-odd-bg-color);
    padding: .5rem;
    border-radius: .5rem;
`
