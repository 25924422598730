import {useAppDispatch, useAppSelector} from "../../hooks";
import {INTERVALS_FOR_PERIODS, KeyOfTimePeriods, LANG, TIME_PERIODS} from "../CONSTANTS";
import styled from "styled-components";
import {changeChartPeriodOrInterval, fetchChartData} from "../../features/chartsSlice";
import React from "react";
import {ChartInternalTypes} from "../../Types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowsRotate} from "@fortawesome/free-solid-svg-icons";


export function selectIntervals(period: KeyOfTimePeriods) {
    const intervalsForPeriod = INTERVALS_FOR_PERIODS[period];
    return intervalsForPeriod ? intervalsForPeriod : [];
}

export default function IntervalSelection({chartId, type}: { chartId: string, type: ChartInternalTypes }) {

    const dispatch = useAppDispatch()
    const parameters = useAppSelector(state => (state.charts.parameters[chartId]))

    function changePeriodOrInterval(value: any, isPeriod = true) {
        dispatch(changeChartPeriodOrInterval({
            chartId,
            value,
            isPeriod
        }))
    }

    const intervals = selectIntervals(parameters.selectedPeriod!);


    const twoRows = parameters.periods.length > 5;
    return <div className={'interval-selection-container'}>
        <div className={'interval-selection-row-double'}>
            <div className={'interval-selection-row first ' + (!twoRows ? 'one-line' : 'two-line')}>
                <div className={'period-name'}>
                    Період:
                </div>
                {parameters.periods.slice(0, 5).map((p, i) =>
                    <PeriodBtn $selected={parameters.selectedPeriod === p} className={'period'} key={p}
                               onClick={() => changePeriodOrInterval(p)}>
                        {TIME_PERIODS[p].lang[LANG]}
                    </PeriodBtn>)}
            </div>
            <div className={'interval-selection-row second'}>
                {parameters.periods.slice(5).map((p, i) =>
                    <PeriodBtn $selected={parameters.selectedPeriod === p} className={'period'} key={p}
                               onClick={() => changePeriodOrInterval(p)}>
                        {TIME_PERIODS[p].lang[LANG]}
                    </PeriodBtn>)}
            </div>
        </div>
        {type === 'xy' && <div className={'interval-selection-row first one-line'}>
            <div className={'period-name'}>
                Інтервал:
            </div>
            {intervals.map((p, i) =>
                <PeriodBtn $selected={parameters.selectedInterval === p} className={'period'} key={p}
                           onClick={() => changePeriodOrInterval(p, false)}>
                    {TIME_PERIODS[p as KeyOfTimePeriods].lang[LANG]}
                </PeriodBtn>)}
        </div>}
        <button className="update-btn" onClick={() => dispatch(fetchChartData({chartId}))}>
            <span>Оновити</span>
            <FontAwesomeIcon title={'update'} icon={faArrowsRotate} style={{width: '1.2rem', height: '1.2rem'}}
                             className='fa-secondary-color-text'/>
        </button>
    </div>
}

const PeriodBtn = styled.button<{ $selected?: boolean }>`
    color: ${({$selected}) => $selected ? 'var(--secondary-text)' : 'var(--table-row-text-color)'};
    background: ${({$selected}) => $selected ? 'var(--table-action-color)' : 'var(--table-row-odd-bg-color)'};

    border: none;
    padding: .3rem .4rem;
`
