import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft, faAngleRight, faAnglesLeft, faAnglesRight} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import styled from "styled-components";
import {useAppDispatch} from "../../hooks";
import {changePage} from "../../features/tableSlice";

export default function PaginateTable({
                                          maxPages,
                                          currentPage,
                                          smaller = false,
                                          disable = false,
                                          secondaryColor = false
                                      }: {
    maxPages: number,
    currentPage: number,
    smaller?: boolean
    disable?: boolean
    secondaryColor?: boolean
}) {
    const dispatch = useAppDispatch()

    if (maxPages <= 0)
        return null

    function onPageChange(pageIndex: number) {
        dispatch(changePage(pageIndex))
    }

    // Calculate the range of pages to display, with a maximum of 5 pages
    const pageRange = 2; // Display two pages before and after the current page
    let startPage = Math.max(currentPage - pageRange, 1); // Ensure we don't go below 1
    let endPage = Math.min(currentPage + pageRange, maxPages); // Ensure we don't go above maxPages

    // Adjust the range if the current page is too close to the edges
    if (currentPage <= pageRange) {
        endPage = Math.min(5, maxPages); // Show the first 5 pages when near the start
    } else if (currentPage >= maxPages - pageRange) {
        startPage = Math.max(maxPages - 4, 1); // Show the last 5 pages when near the end
    }

    const pages = Array.from({length: endPage - startPage + 1}, (_, i) => startPage + i);

    return <PagesList $smaller={smaller}>
        {maxPages > 5 && <li>
            <Btn $secondary={secondaryColor} onClick={() => onPageChange(1)} disabled={disable}>
                <FontAwesomeIcon title={'sort'} icon={faAnglesLeft}
                                 className={secondaryColor ? 'fa-secondary-color-text' : 'fa-color-text'}/>
            </Btn>
        </li>}
        <li>
            <Btn $secondary={secondaryColor} onClick={() => onPageChange(currentPage - 1)}
                 disabled={disable || currentPage === 1}>
                <FontAwesomeIcon title={'sort'} icon={faAngleLeft}
                                 className={secondaryColor ? 'fa-secondary-color-text' : 'fa-color-text'}/>
            </Btn>
        </li>
        {pages.map(p => (
            <li key={p}>
                <Btn $secondary={secondaryColor} $active={currentPage === p} onClick={() => onPageChange(p)}
                     disabled={disable || currentPage === p}>{p}</Btn>
            </li>
        ))}
        <li>
            <Btn $secondary={secondaryColor} onClick={() => onPageChange(currentPage + 1)}
                 disabled={disable || currentPage === maxPages}>
                <FontAwesomeIcon title={'sort'} icon={faAngleRight}
                                 className={secondaryColor ? 'fa-secondary-color-text' : 'fa-color-text'}/>
            </Btn>
        </li>
        {maxPages > 5 && <li>
            <Btn $secondary={secondaryColor} onClick={() => onPageChange(maxPages)} disabled={disable}>
                <FontAwesomeIcon title={'sort'} icon={faAnglesRight}
                                 className={secondaryColor ? 'fa-secondary-color-text' : 'fa-color-text'}/>
            </Btn>
        </li>}
    </PagesList>
}


const Btn = styled.button<{ $secondary: boolean, $active?: boolean }>`
    cursor: pointer;
    border: none;
    background: transparent;
    font-weight: ${props => props.$active ? 'bold' : 'normal'};
    color: ${props => props.$active ? 'var(--table-action-color)' : props.$secondary ? 'var(--secondary-text)' : 'var(--table-row-text-color)'};
    padding-top: .5rem;
    padding-bottom: .5rem;

    &:hover {
        filter: brightness(.85);
    }
`


const PagesList = styled.ul<{ $smaller: boolean }>`
    list-style: none;
    margin: 0;
    padding: 0;

    display: inline-flex;
    align-items: center;
    gap: 0;

    & ${Btn} {
        width: ${props => props.$smaller ? '2.6em' : '3em'};
    }


    & > li {
        margin: 0;
        //border-right: 2px solid var(--table-border-color);
        //border-bottom: 2px solid var(--table-border-color);
        //border-top: 2px solid var(--table-border-color);

        & button {

        }
    }


    & li:first-of-type {
        //border-left: 2px solid var(--table-border-color);
        //border-top-left-radius: 1rem;
        //border-bottom-left-radius: 1rem;
        overflow: hidden;

    }

    & li:last-of-type {
        overflow: hidden;

        //border-top-right-radius: 1rem;
        //border-bottom-right-radius: 1rem;

    }
`
