import React, {CSSProperties, useEffect} from 'react';
import './App.css';
import moment from "moment";
import 'moment/locale/uk';
import {useAppDispatch, useAppSelector} from "./hooks";
import TablesPage from "./components/table/TablesPage";
import {functions} from "./config";
import styled from "styled-components";
import {PageI, setActivePage, setIsUserAdmin} from "./features/projectSlice";
import GraphsPage from "./components/graphs/GraphsPage";
import AsideHeader from "./components/header/AsideHeader";
import {toggleUseSideHeader} from "./features/layoutSlice";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/free-solid-svg-icons";
import useTheme from "./components/hooks/useTheme";
import useLoadTableSchema from "./components/hooks/useLoadTableSchema";
import useDocDimensions from "./components/hooks/useDocDimensions";
import {ASIDE_HEADER_WIDTH, BOTTOM_NAV_HEIGHT, SIDE_BAR_THRESHOLD_WIDTH, TOP_BAR_HEIGHT} from "./components/CONSTANTS";
import BottomNav from "./components/header/BottomNav";
import TopBar from "./components/header/TopBar";
import hljs from 'highlight.js/lib/core';
import json from 'highlight.js/lib/languages/json';

// Then register the languages you need
hljs.registerLanguage('json', json);

function App() {

    const useAsideHeader = useAppSelector(state => state.layout.useSideHeader);
    const {activePage, pages, name} = useAppSelector(state => state.project)

    const dispatch = useAppDispatch()

    useTheme()
    useEffect(() => {
        moment.locale('uk')
    }, [])
    const {width} = useDocDimensions();

    useEffect(() => {
        const item = localStorage.getItem('project');
        if (item) {
            dispatch(setIsUserAdmin(JSON.parse(item).userId))
        }
    }, [dispatch]);

    const isStateLoaded = useLoadTableSchema(name)

    const desktop_orientation = width > SIDE_BAR_THRESHOLD_WIDTH;
    const renderSide = !!useAsideHeader && desktop_orientation

    const mainStyle: CSSProperties = {
        marginLeft: renderSide ? ASIDE_HEADER_WIDTH + 'px' : '0',
        paddingBottom: desktop_orientation ? '.5rem' : `calc(${BOTTOM_NAV_HEIGHT}px + .5rem)`,
        paddingTop: desktop_orientation ? '0' : `calc(${TOP_BAR_HEIGHT}px + .5rem)`,
    }

    return (
        <AppWrapper className={'app-container'} $flex={renderSide}>
            {/*{!useAsideHeader && <Header project={projectName}/>}*/}
            {renderSide && <AsideHeader projectName={name}/>}
            {width > 0 && !desktop_orientation && <TopBar/>}
            <main style={mainStyle}>
                {width > 0 && desktop_orientation &&
                    <List pages={pages} active={activePage} renderSideBarBtn={desktop_orientation}/>}
                {isStateLoaded && pages[activePage].name === 'table' && <TablesPage projectName={name}/>}
                {isStateLoaded && pages[activePage].name === 'graph' && <GraphsPage projectName={name}/>}
                {isStateLoaded && !!pages[activePage].func && functions[pages[activePage].func!]()}
            </main>
            {width > 0 && !desktop_orientation && <BottomNav projectName={name} pages={pages} active={activePage}/>}
        </AppWrapper>
    )
}

export default App;

const lang = 'ua'

function List({pages, active, renderSideBarBtn}: { pages: PageI[], active: number, renderSideBarBtn: boolean }) {
    const dispatch = useAppDispatch();
    return <UL>
        {renderSideBarBtn && <li className={'toggle-side-bar'}>
            <Btn onClick={() => dispatch(toggleUseSideHeader())}>
                <FontAwesomeIcon title={'side-menu'} icon={faBars} style={{width: '1.2rem', height: '1.2rem'}}
                                 className={'fa-color'}/>
            </Btn>
        </li>}
        {pages.map((p, i) => <li key={p.name}>
            <Btn $active={i === active} disabled={p.disabled} onClick={() => dispatch(setActivePage(i))}>
                {p.lang[lang]}
            </Btn>
        </li>)}
        {renderSideBarBtn && <li className={'fake'}/>}
    </UL>

}

const AppWrapper = styled.div<{ $flex: boolean }>`
    display: ${props => props.$flex ? 'flex' : 'block'};
`

const UL = styled.ul`

    padding: .4rem 0;
    display: flex;
    gap: .5rem;
    flex-wrap: wrap;
    justify-content: center;

    //border-bottom: 2px solid var(--table-action-color);

    & .toggle-side-bar {
        margin-right: auto;
    }

    & .fake {
        margin-left: auto;
    }

    & > li {
        white-space: nowrap;
        list-style: none;

        @media (max-width: 500px) {
            flex-basis: 100%;

        }
    }

    & .fake {
        width: 2.4rem;
    }



`

const Btn = styled.button<any>`
    appearance: none;
    background: transparent;
    background: var(--table-row-odd-bg-color);
    padding: .2rem .6rem;
    color: var(--table-row-text-color);
    cursor: pointer;
    width: 100%;
    font-size: 1rem;
    border: none;

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: .2rem;
    text-align: center;

    ${props => props.$active && `border-bottom: 3px solid var(--table-action-color);`}
    ${props => !props.$active && `border-bottom: 3px solid var(--table-border-color);`}
    &:hover {
        filter: brightness(.85);
    }

    &:disabled {
        filter: grayscale(.6);
    }
`
