import {ChartI, XyChartI} from "../../Types";
import {GREEN_COLOR} from "../../components/CONSTANTS";

const orderCount: ChartI<XyChartI> = {
    id: "orderCount",
    queryId: "orderCount",
    lang: {
        ua: 'Кількість замовлень'
    },
    type: "xy",
    height: 450,
    parameters: {
        periods: ["Month1", "Month3", "Month6","Year1", "Year2", "Year3", "Year5", "Year7"],
        selectedPeriod: "Year1",
        selectedInterval: "Month1",
        filters: {
            from: {
                lang: {ua: 'З'},
                key: 'from',
                type: 'datetime'
            },
            to: {
                lang: {ua: 'До'},
                key: 'to',
                type: 'datetime'
            }
        }
    },
    settings: {
        usePie: false,
        pieWhole: false,
        usePieNumberLabel: false,
        usePieNumberPercentage: true,
        pieShortNames: {
            count: 'Замовлень',
        },
        entries: [
            {
                key: 'count',
                lang: {
                    ua: 'Кількість замовлень'
                }
            }
        ],
        colors: {
            count: GREEN_COLOR,
        },
        glyphs: [],
        barType: "bar",
        lineType: "none",
        tooltipGlyphComponent: "🐋"
    }
}

export default orderCount
