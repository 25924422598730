import {useEffect, useState} from "react";

export default function useDocDimensions() {
    const [dimensions, setDimensions] = useState<{ width: number, height: number }>({width: 0, height: 0});

    useEffect(() => {
        const updateDimensions = (entries: any) => {
            for (let entry of entries) {
                const {width, height} = entry.contentRect;
                setDimensions({width, height});
            }
        };

        const observer = new ResizeObserver(updateDimensions);

        observer.observe(window.document.documentElement);

        return () => {
            observer.disconnect();
        };
    }, []);

    return dimensions
}
