import {FieldOperations, OpsCategories, SqlOperation, TableI} from "../../Types";
import {nanoid} from "@reduxjs/toolkit";
import moment from "moment";


let fieldOperations: FieldOperations = {
    dt: [...OpsCategories.range],
    id: [...OpsCategories.eq, ...OpsCategories.range, ...OpsCategories.in,],
    msisdn: [...OpsCategories.eq, ...OpsCategories.like, ...OpsCategories.in,],
};

export const SpamsTable: TableI = {
    backend: "/287/",
    title: {
        ua: "Спам Viber",
    },
    pk: 'id',
    forAdmin: true,
    action: 'act287sendSpam',
    defaultOrderedBy: "id",
    defaultOrderedWay: "DESC",
    defaultPerPage: 20,
    orderByFields: {
        "id": {ua: "ID"},
        "dt": {ua: "Створено"}
    },
    fieldOperations: fieldOperations,
    defaultFilters: 'spam287DefFilters',
    fields: [
        {
            key: "n",
            inline: true,
            title: {ua: "#"},
            type: "number",
            applyTransform: true
        },
        {
            key: "msisdn",
            type: 'tel',
            applyTransform: true,
            detailed: true, inline: true,
            title: {ua: "Телефон",}
        },
        {
            key: "dt",
            title: {ua: "Створено"},
            type: "datetime",
            datetimeFormat: "DD MMMM YYYY HH:mm",
            applyTransform: true,
            inline: true,
            detailed: true
        },
        {
            key: "message",
            title: {ua: "Повідомлення"},
            detailed: true,
            inline: false,
            type: 'string'
        },
        {
            key: "response",
            title: {ua: "Відповідь"},
            detailed: true,
            inline: false,
            type: 'string'
        },
        {
            key: "id",
            detailed: true,
            title: {ua: "ID"},
            type: "number",
            applyTransform: true
        },
        {
            key: "user_id",
            detailed: true,
            title: {ua: "User Viber ID"},
            type: "string",
        },
    ]
}

export function spam287DefFilters() {
    return [ {
        nanoid: nanoid(),
        field: 'dt',
        type: 'datetime',
        filterOp: SqlOperation.GREATER_THAN_OR_EQUAL,
        locked: true,
        value: moment().subtract(3, 'days').startOf('day').format('yyyy-MM-DD HH:mm:ss')
    },]
}
