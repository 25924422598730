import {useEffect, useState} from "react";
import {useAppDispatch} from "../../hooks";
import {createInitialStateForTables, createSchema} from "../../features/init";
import {STATIC_SCHEMAS} from "../../staticSchemas";
import {schemas} from "../../config";
import {setInitialTableState} from "../../features/tableSlice";
import {handleFetchResponseStatus} from "../util";
import {SchemaI} from "../../Types";

export default function useLoadTableSchema(projectName: string) {
    const [isStateLoaded, setIsStateLoaded] = useState<boolean>(false)

    const dispatch = useAppDispatch();

    useEffect(() => {
        const loadSchema = async () => {
            let schema;
            if (process.env.NODE_ENV === 'development') {
                schema = createSchema(STATIC_SCHEMAS[projectName])
            } else
                schema = await fetch(`/schemas/tables/${projectName}.json`).then(r => r.json());

            schema = await addExtraFields(schema, projectName);

            if (schema) {
                let state = createInitialStateForTables(schema);
                schemas[projectName] = schema
                dispatch(setInitialTableState(state))
            }
        };
        loadSchema().then(_ => setIsStateLoaded(true));
    }, [projectName, dispatch]);

    return isStateLoaded

}


async function addExtraFields(schema: SchemaI, project: string) {
    if (!schema.extraFields)
        return schema

    const extraFields = await fetch(
        `${schema.backend}/runQuery.php?project=${project}&query_key=extra_table_fields`,
        {credentials: 'include'})
        .then(handleFetchResponseStatus)
        .then(r => r.json()) as {
        data: any[]
    };
    // @ts-ignore
    const groupBy = Object.groupBy(extraFields.data, ({table_name}) => table_name);

    Object.entries(groupBy).forEach(([k, v]) => {
        if (schema.tables[k]) { // @ts-ignore
            const filter = v.filter(x => x.visible).map(x => JSON.parse(x.body));
            filter.forEach((f: { key: string; }) => {
                const exist = schema.tables[k].fields.findIndex(x => x.key === f.key);
                if (exist !== -1) {
                    schema.tables[k].fields.splice(exist, 1)
                }
            })
            schema.tables[k].fields.push(...filter)
        }
    })
    return schema
}
