import {configureStore} from '@reduxjs/toolkit'
import tablesReducer from '../features/tableSlice'
import projectSlice from '../features/projectSlice'
import chartsSlice from '../features/chartsSlice'
import layoutSlice from "../features/layoutSlice";
import genericDataSlice from "../features/genericDataSlice";

export const store = configureStore({
    reducer: {
        tables: tablesReducer,
        project: projectSlice,
        charts: chartsSlice,
        layout: layoutSlice,
        genericData: genericDataSlice
    },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
