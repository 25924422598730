import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ChartI, ChartParamsI, NormalObject, PieChartI, XyChartI} from "../Types";
import userPrefersReducedMotion, {userPrefersDarkTheme} from "../components/charts/userPrefersReducedMotion";
import {darkTheme, lightTheme} from "@visx/xychart";
import {API_BACK_URL, KeyOfTimePeriods, TIME_PERIODS} from "../components/CONSTANTS";
import {handleFetchResponseStatus} from "../components/util";
import {selectIntervals} from "../components/charts/IntervalSelection";
import {MIN_SLOT_WIDTH} from "../components/graphs/GraphLayout";
import {toast} from "react-toastify";
import {RootState} from "../store/store";
import moment from "moment/moment";


type ChartStatusI = {
    status: 'idle' | 'loaded' | 'loading' | 'error',
    tickTimeFormat?: {
        format: string,
        width: number
    }
};

export interface ChartSliceInitialStateI {
    loaded: boolean
    entries: { [key: string]: ChartI };
    parameters: { [key: string]: ChartParamsI }
    data: { [p: string]: NormalObject[] | undefined };
    status: { [p: string]: ChartStatusI }
}

export const CHART_VERSION = 5

export function saveChartLocally(chart: ChartI, projectName: string, chartId: string) {
    const o = JSON.stringify({version: CHART_VERSION, data: chart})
    localStorage.setItem(`${projectName}-chart-${chartId}`, o);
}

export function restoreChart(projectName: string, chartId: string) {
    localStorage.removeItem(`${projectName}-chart-${chartId}`);
    toast.success("Для набуття змін перезавантажте сторінку")
}

export const chartsSlice = createSlice({
    name: 'chart',
    initialState: {
        loaded: false
    } as ChartSliceInitialStateI,
    reducers: {
        setInitialChartsState: (state, action) => ({...action.payload}),
        changeChartSetting(state, action: PayloadAction<ChangeSettingPayloadI>) {
            const {chartId, property, value} = action.payload;
            (state.entries[chartId].settings as any)[property] = value
        },
        changeIndexedChartSetting(state, action: PayloadAction<ChangeIndexedSettingPayloadI>) {
            const {chartId, property, value, index, indexedName} = action.payload;
            (state.entries[chartId].settings as any)[indexedName][index][property] = value
        },
        changeChartHeight(state, action: PayloadAction<ChangeHeightPayloadI>) {
            const {chartId, value} = action.payload;
            state.entries[chartId].height = value
        },
        changeChartName(state, action: PayloadAction<ChangeChartNamePayloadI>) {
            const {chartId, value, lang} = action.payload;
            state.entries[chartId].lang[lang] = value
        },
        changeChartPeriodOrInterval(state, action: PayloadAction<ChangeChartPeriodOrIntervalPayloadI>) {
            const {chartId, value, isPeriod = true} = action.payload;
            let prevIntervalIsDate = TIME_PERIODS[state.parameters[chartId].selectedInterval!].date
            if (isPeriod) {
                state.parameters[chartId].selectedPeriod = value
                const intervals = selectIntervals(value);
                if (intervals.length > 0)
                    state.parameters[chartId].selectedInterval = intervals[intervals.length - 1]
            } else {
                state.parameters[chartId].selectedInterval = value
            }
            if (TIME_PERIODS[state.parameters[chartId].selectedInterval!].date) {
                state.parameters[chartId].filters!.to.value = moment(state.parameters[chartId].filters!.to.value)
                    .startOf('day').add(!prevIntervalIsDate ? 1 : 0, 'day').format("YYYY-MM-DD HH:mm:ss")
                state.parameters[chartId].filters!.from.value = moment(state.parameters[chartId].filters!.to.value)
                    .startOf('day').add(!prevIntervalIsDate ? 1 : 0, 'day')
                    .subtract(TIME_PERIODS[state.parameters[chartId].selectedPeriod!].intervalVals[0],
                        TIME_PERIODS[state.parameters[chartId].selectedPeriod!].intervalVals[1] as any)
                    .format("YYYY-MM-DD HH:mm:ss")
            } else {
                const [from, to] =
                    getFromAndToForCharts(state.parameters[chartId].selectedInterval!, state.parameters[chartId].selectedPeriod!);
                state.parameters[chartId].filters!.from.value = from.format('yyyy-MM-DD HH:mm:ss')
                state.parameters[chartId].filters!.to.value = to.format('yyyy-MM-DD HH:mm:ss')
            }


        },
        setChartParameterToPeriod(state, action: PayloadAction<SetChartParameterToI>) {
            const {chartId, value} = action.payload;
            const to = moment(value)
            let from = to.clone()
                .subtract(TIME_PERIODS[state.parameters[chartId].selectedPeriod!].intervalVals[0],
                    TIME_PERIODS[state.parameters[chartId].selectedPeriod!].intervalVals[1] as any)
            state.parameters[chartId].filters!.from.value = from.format("YYYY-MM-DD HH:mm:ss")
            state.parameters[chartId].filters!.to.value = to.format("YYYY-MM-DD HH:mm:ss")
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchChartData.pending, (state, action) => {
                state.status[action.meta.arg.chartId!].status = 'loading'
            })
            .addCase(fetchChartData.fulfilled, (state, action) => {
                const {query, result} = action.payload;
                const chartId = query.chartId

                if (result.status === 'error') {
                    state.status[chartId].status = 'error'
                } else {
                    state.status[chartId].status = 'loaded'
                    const type = state.entries[chartId].type;
                    if (type === 'xy') {
                        const keys = state.entries[chartId].settings.entries.map(e => e.key);
                        const filteredData = removeFirstAllZeroEntry(result.data, keys);
                        const [format, width] = selectAxisTickTimeFormat(state.parameters[chartId].selectedPeriod!, state.parameters[chartId].selectedInterval!);
                        state.status[chartId].tickTimeFormat!.width = width
                        state.status[chartId].tickTimeFormat!.format = format
                        const realTimekey = 'time_window_start'
                        state.data[chartId] = filteredData.map((d: any) => ({
                            ...d,
                            date: moment(d[realTimekey]).toDate().getTime()
                        })) as unknown as NormalObject[]
                    }
                    if (type === 'pie') {
                        if (!(state.entries[chartId].settings as PieChartI).dynamicEntries) {
                            state.data[chartId] = Object.entries(result.data[0]).map(([k, v]) => ({
                                label: k,
                                value: v
                            })) as any
                        } else {
                            state.entries[chartId].settings.entries =
                                state.entries[chartId].settings.entries.map(e => {
                                    return {
                                        ...e,
                                        lang: {
                                            ...e.lang,
                                            ua: result.data.find((x: any) => x.key === e.key)?.label ?? e.lang.ua
                                        }

                                    }
                                })
                            state.data[chartId] = result.data.map((x: any) => {

                                return {
                                    label: x.key,
                                    value: x.value
                                }
                            }) as any
                        }

                    }
                }
            })
            .addCase(fetchChartData.rejected, (state, action: any) => {
                state.status[action.meta.arg.chartId!].status = 'error'
                toast.error(action.payload?.result?.message || "Сталась помилка при завантаженні графіка")
            });
    }
})

export const {
    setInitialChartsState,
    changeChartSetting,
    changeIndexedChartSetting,
    changeChartHeight,
    changeChartName,
    changeChartPeriodOrInterval,
    setChartParameterToPeriod
} = chartsSlice.actions

export default chartsSlice.reducer

interface ChartDataFetchDataI {
    chartId: string,
    chartQueryId: string,
    params: {
        name: string,
        value: any
    }[],
    interval?: KeyOfTimePeriods | null
}

export const fetchChartData = createAsyncThunk<{
    query: ChartDataFetchDataI,
    result: any
}, Partial<ChartDataFetchDataI>, {
    state: RootState
}>(
    'chart/fetchChartData',
    async (payload, thunkAPI) => {
        let state = thunkAPI.getState();
        const chartId = payload.chartId!;
        const chartQueryId = state.charts.entries[chartId].queryId;
        const project = state.project.name
        const chartType = state.charts.entries[chartId].type;
        const filters = state.charts.parameters[chartId].filters;
        const interval = state.charts.parameters[chartId].selectedInterval;
        const period = state.charts.parameters[chartId].selectedPeriod;
        let query = {
            chartId: chartId,
            chartQueryId: chartQueryId,
            params: filters ? Object.values(filters).map(p => ({name: p.key, value: p.value})) : [],
            interval: chartType === 'xy' ? interval : (chartType === 'pie' && period === 'AllTime') ? period : null
        } as ChartDataFetchDataI

        const uri = API_BACK_URL + '/getChartData.php?project=' + encodeURIComponent(project) +
            '&query=' + encodeURIComponent(JSON.stringify(query));
        const response = await fetch(uri, {credentials: 'include'})
            .then(handleFetchResponseStatus)
            .then(r => r.json());

        if (response.status === 'error')
            return thunkAPI.rejectWithValue({
                query: query,
                result: response
            })

        return {
            query: query,
            result: response
        }
    },
)

export const createInitialStateForCharts = async (schema: {
    [key: string]: ChartI
}, projectName: string): Promise<ChartSliceInitialStateI> => {
    const entries = {} as { [key: string]: ChartI }
    const data = {} as { [p: string]: NormalObject[] | undefined }
    const parameters = {} as { [p: string]: ChartParamsI }
    const status = {} as { [p: string]: ChartStatusI }

    for (const [key, chart] of Object.entries(schema)) {

        const localChart = localStorage.getItem(`${projectName}-chart-${key}`);
        if (localChart !== null) {
            const parsed = JSON.parse(localChart);
            if (parsed.version === CHART_VERSION) {
                entries[key] = parsed.data
            }
        }
        if (!entries[key])
            switch (chart.type) {
                case "xy":
                    entries[key] = await completeXy(chart as ChartI<XyChartI>)
                    break
                case "pie":
                    entries[key] = await completePie(chart as ChartI<PieChartI>)
                    break
                default:
                    break;
            }

        parameters[key] = chart.parameters
        if ('from' in parameters[key].filters! && 'to' in parameters[key].filters!) {
            const [from, to] =
                getFromAndToForCharts(chart.parameters.selectedInterval!, chart.parameters.selectedPeriod!);
            parameters[key].filters!['from'].value = from.format('yyyy-MM-DD HH:mm:ss')
            parameters[key].filters!['to'].value = to.format('yyyy-MM-DD HH:mm:ss')
        }


        let tickTimeFormat
        if (chart.type === 'xy') {
            const [format, width] = selectAxisTickTimeFormat(chart.parameters.selectedPeriod!, chart.parameters.selectedInterval!)
            tickTimeFormat = {
                format,
                width
            }
        }

        status[key] = {status: 'idle', tickTimeFormat: tickTimeFormat}
        data[key] = undefined
    }

    return {
        loaded: true,
        parameters,
        entries,
        data,
        status
    }

}

export const getFromAndToForCharts = (selectedInterval: KeyOfTimePeriods, selectedPeriod: KeyOfTimePeriods) => {
    let to = TIME_PERIODS[selectedInterval].getToNow()
    let from = to.clone().subtract(TIME_PERIODS[selectedPeriod].intervalVals[0],
        TIME_PERIODS[selectedPeriod].intervalVals[1] as any)

    if (process.env.NODE_ENV === 'development') {
        to = to.subtract(12, 'month')
        from = from.subtract(12, 'month')
    }

    return [from, to]
}


const completeXy = async (chart: ChartI<XyChartI>): Promise<ChartI<XyChartI>> => {

    const chartCopy = chart
    let settings = chart.settings;

    chartCopy.height = chart.height ?? 450

    settings.xAxisName = settings.xAxisName ?? ''
    settings.yAxisName = settings.yAxisName ?? ''

    const entries = settings.entries
    const oldGlyphs = settings.glyphs

    settings.glyphs = entries.map(e => {
        let find = oldGlyphs.find(g => g.key === e.key);
        return find ? {
            ...e,
            ...find
        } : {
            ...e,
            value: 'circle',
            hide: true
        };
    })
    settings.sharedTooltip = settings.sharedTooltip ?? true
    settings.lineType = settings.lineType ?? 'line'
    settings.barType = settings.barType ?? 'none'
    settings.renderHorizontally = settings.renderHorizontally ?? false
    settings.animationTrajectory = settings.animationTrajectory ?? 'center'
    settings.useAnimatedComponents = settings.useAnimatedComponents ?? !userPrefersReducedMotion()
    settings.theme = settings.theme ?? (userPrefersDarkTheme() ? darkTheme : lightTheme)
    settings.gridProps = settings.gridProps ?? [false, false]
    settings.xAxisOrientation = settings.xAxisOrientation ?? 'bottom'
    settings.yAxisOrientation = settings.yAxisOrientation ?? 'right'
    settings.showTooltip = settings.showTooltip ?? true
    settings.showVerticalCrosshair = settings.showVerticalCrosshair ?? true
    settings.showHorizontalCrosshair = settings.showHorizontalCrosshair ?? false
    settings.snapTooltipToDatumX = settings.snapTooltipToDatumX ?? true
    settings.snapTooltipToDatumY = settings.snapTooltipToDatumY ?? true
    settings.tooltipGlyphComponent = settings.tooltipGlyphComponent ?? 'circle'

    settings.supportPie = settings.supportPie ?? true


    const actualSlots = Math.max(Math.floor(window.document.documentElement.offsetWidth / MIN_SLOT_WIDTH), 1)

    settings.usePie = (settings.usePie && actualSlots > 2) ?? false
    settings.pieWhole = settings.pieWhole ?? false
    settings.usePieNumberLabel = settings.usePieNumberLabel ?? (settings.pieShortNames && true)
    settings.usePieNumberPercentage = settings.usePieNumberPercentage ?? (!settings.pieShortNames && true)

    chartCopy.settings = settings

    return chartCopy
}

const completePie = async (chart: ChartI<PieChartI>): Promise<ChartI<PieChartI>> => {

    const chartCopy = chart
    let settings = chart.settings;

    chartCopy.height = chart.height ?? 450

    const entries = settings.entries
    const oldGlyphs = settings.glyphs

    settings.glyphs = entries.map(e => {
        let find = oldGlyphs.find(g => g.key === e.key);
        return find ? {
            ...e,
            ...find
        } : {
            ...e,
            value: 'circle'
        };
    })

    settings.usePieNumberLabel = settings.usePieNumberLabel ?? (settings.pieShortNames && true)
    settings.usePieNumberPercentage = settings.usePieNumberPercentage ?? (!settings.pieShortNames && true)
    settings.pieWhole = settings.pieWhole ?? false

    settings.dynamicEntries = settings.dynamicEntries ?? false

    chartCopy.settings = settings

    return chartCopy
}


interface ChangeSettingPayloadI {
    chartId: string,
    property: string,
    value: any
}

interface ChangeHeightPayloadI {
    chartId: string,
    value: number
}

interface ChangeChartNamePayloadI {
    chartId: string,
    lang: string
    value: string
}

interface ChangeChartPeriodOrIntervalPayloadI {
    chartId: string,
    value: KeyOfTimePeriods,
    isPeriod?: boolean
}

interface SetChartParameterToI {
    chartId: string,
    value: string,
}

type ChangeIndexedSettingPayloadI = { index: string | number, indexedName: string } & ChangeSettingPayloadI


const selectAxisTickTimeFormat = (
    selectedPeriod: keyof typeof TIME_PERIODS,
    selectedInterval: keyof typeof TIME_PERIODS
): [string, number] => {
    const period = TIME_PERIODS[selectedPeriod];
    const interval = TIME_PERIODS[selectedInterval];

    if (!period || !interval) {
        throw new Error(`Invalid period or interval: ${selectedPeriod}, ${selectedInterval}`);
    }

    const isIntervalDateBased = interval.date;

    if ((selectedPeriod === 'Day7' || selectedPeriod === 'Day14') && (selectedInterval === 'Day1' || selectedInterval === 'Day2'))
        return ['dd DD.MM', 95]

    if (!isIntervalDateBased) {
        return ['DD.MM HH:mm', 95]; // Time-based format for intervals without dates
    }

    if (selectedInterval.includes('Day')) {
        return ['DD.MM', 40]
    }

    if (selectedInterval.includes('Month')) {
        return ['MMM YY', 50]
    }

    if (selectedInterval.includes('Year')) {
        return ['YYYY', 70]; // Year only for yearly intervals
    }

    return ['DD.MM.YYYY', 100];
};

function removeFirstAllZeroEntry(array: NormalObject[], keys: string[]) {
    let arr = [...array]
    for (let i = 0; i < arr.length; i++) {
        const datum = arr[i];
        // Check if all keys in the current datum have values of 0
        const allZero = keys.every(key => datum[key] === 0);

        if (!allZero) {
            arr = array.slice(i)
            break
        }
        if (i === arr.length - 1) {
            arr = []
        }
    }

    for (let i = arr.length - 1; i >= 0; i--) {
        const datum = arr[i];
        // Check if all keys in the current datum have values of 0
        const allZero = keys.every(key => datum[key] === 0);
        if (!allZero) {
            arr = arr.slice(0, i + 1)
            break
        }
    }

    return arr;
}
