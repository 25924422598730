import {TOP_BAR_HEIGHT} from "../CONSTANTS";
import React from "react";


export default function TopBar() {

    return <div className={'top-bar'} style={{height: TOP_BAR_HEIGHT + 'px'}}>
        <img src="/icon.png" alt="icon" width={40} height={40}/>
        <span>Engine</span>
    </div>
}
