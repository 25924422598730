import {useAppDispatch, useAppSelector} from "../../hooks";
import {MAX_SLOTS, PaneI} from "./GraphLayout";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useState} from "react";
import {faMinus, faPlus, faUpDownLeftRight, faXmark} from "@fortawesome/free-solid-svg-icons";
import {
    addPaneSlotSize,
    confirmLayoutShift,
    restoreInitialLayout,
    setLayoutDashboard
} from "../../features/layoutSlice";
import Icon from "../misc/Icon";
import {GREEN_COLOR} from "../CONSTANTS";


export default function PaneReordering({panes}: { panes: PaneI[] }) {
    const dispatch = useAppDispatch()
    const [draggingId, setDraggingId] = useState<string | null>(null);
    const [initial] = useState(panes);
    const projectName = useAppSelector(state => state.project.name);
    const handleDragStart = (id: string) => {
        setDraggingId(id);
    };

    const handleDragOver = (event: React.DragEvent<HTMLLIElement>, targetId: string) => {
        event.preventDefault();

        // onReorder(updatedPanes);
    };

    const handleDragEnd = (event: React.DragEvent<HTMLLIElement>) => {
        event.preventDefault();
        setDraggingId(null);
        // onReorder(updatedPanes);
    };

    const handleDrop = (event: React.DragEvent<HTMLLIElement>, targetId: string) => {
        event.preventDefault();
        if (!draggingId || draggingId === targetId) {
            return;
        }

        const draggingIndex = panes.findIndex((pane) => pane.id.toString() === draggingId);
        const targetIndex = panes.findIndex((pane) => pane.id.toString() === targetId);

        const updatedPanes = [...panes];
        updatedPanes[draggingIndex] = {
            ...panes[targetIndex],
            order: panes[draggingIndex].order
        }
        updatedPanes[targetIndex] = {
            ...panes[draggingIndex],
            order: panes[targetIndex].order
        }
        dispatch(setLayoutDashboard(updatedPanes))
    };

    function cancel() {
        dispatch(setLayoutDashboard(initial))
        dispatch(confirmLayoutShift(false))
    }


    return <Container>
        <div className={'flex fl-ju-ce'}>Міняйте порядок перетягуванням</div>
        <div className="upper">
            <button className="confirm" onClick={() => {
                dispatch(confirmLayoutShift(projectName))
            }}>Зберегти
            </button>
            <button className="reject" onClick={cancel}>
                <FontAwesomeIcon title={'minus'} icon={faXmark} style={{width: '1rem', height: '1rem'}}
                                 className={'fa-color-text'}/>
            </button>
        </div>
        {panes.map((p, i) => <Item key={i} $isDragging={p.id.toString() === draggingId}
                                   $highlight={draggingId !== null && p.id.toString() !== draggingId}
                                   onDrop={(e) => handleDrop(e, p.id.toString())}
                                   onDragOver={(e) => handleDragOver(e, p.id.toString())}
                                   onDragEnd={handleDragEnd}
                                   draggable
                                   onDragStart={() => handleDragStart(p.id.toString())}
        >
            <div
                className="name">
                <div>{p.name}</div>
                <Icon src={"/win-icons/Mail stamp (move).ico"}
                      title={'Додати фільтр'} alt={'Додати фільтр'}
                      width={'1rem'}
                      height={'1rem'}
                      component={faUpDownLeftRight}
                />
            </div>
            <div className="slots">
                <button className="minus" disabled={p.slots <= 1 || p.slots <= (p.minSlots!)}
                        onClick={() => dispatch(addPaneSlotSize({index: i, value: -1}))}
                >
                    <FontAwesomeIcon title={'minus'} icon={faMinus} style={{width: '1rem', height: '1rem'}}
                                     className={'fa-color'}/>
                </button>
                <div className={'val'}>{p.slots}</div>
                <button className="plus" disabled={p.slots >= MAX_SLOTS || p.slots >= (p.maxSlots!)}
                        onClick={() => dispatch(addPaneSlotSize({index: i, value: 1}))}
                >
                    <FontAwesomeIcon title={'minus'} icon={faPlus} style={{width: '1rem', height: '1rem'}}
                                     className={'fa-color'}/>
                </button>
            </div>
        </Item>)}
        <button className="restore" onClick={() => restoreInitialLayout(projectName)}>
            Скинути до початкового
        </button>
    </Container>
}

const Item = styled.li<{ $isDragging: boolean, $highlight: boolean }>`
    box-shadow: ${(props) => (props.$isDragging ? "0 4px 8px rgba(0,0,0,0.2)" : "none")};
    outline: ${({$highlight}) => $highlight ? `2px solid ${GREEN_COLOR}` : 'none'};
    opacity: ${({$isDragging}) => $isDragging ? '.6' : '1'};
    display: flex;
    flex-flow: column;
    gap: .5rem;
    background: var(--table-row-odd-bg-color);
    //background: var(--table-action-color);
    padding: .5rem;
    border-radius: .5rem;
    color: var(--table-row-text-color);
`

const Container = styled.ul`
    display: flex;
    flex-direction: column;
    gap: .5rem;
    padding-left: .5rem;
    padding-right: .5rem;

    & .restore {
        background: var(--table-action-color);
        color: var(--secondary-text);
        border: none;
        padding: .4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: .4rem;
    }

    & .upper {
        display: flex;
        gap: .5rem;
        align-items: stretch;

        & button {
            background: var(--table-action-color);
            color: var(--secondary-text);
            border: none;
            padding: .4rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: .4rem;

        }

        & .confirm {
            flex-grow: 1;
        }
    }


    & li {

    }

    & .name {
        display: flex;
        justify-content: center;
        cursor: move;
        gap: .5rem;
    }

    & .slots {
        display: flex;
        justify-content: center;
        gap: .5rem;
        align-items: center;

        & button {
            background: var(--secondary);
            color: var(--secondary-text);
            border: none;
            padding: .4rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: .4rem;
        }
    }
`;
