import {useAppSelector} from "../../../hooks";
import {ChartInternalTypes} from "../../../Types";
import {LANG} from "../../CONSTANTS";
import IntervalSelection from "../IntervalSelection";
import ChartPeriod from "../ChartPeriod";
import React, {useState} from "react";
import {faChevronDown, faChevronUp, faLineChart, faPieChart} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function ChartSideInfo({chartId, type}: { chartId: string, type: ChartInternalTypes }) {

    const [isOpen, setIsOpen] = useState(false)
    const lang = useAppSelector(state => (state.charts.entries[chartId]).lang)

    return <div className={'xy-side-container'}>
        <button className={'name'} onClick={() => setIsOpen(!isOpen)}>
            <div className={'flex gap-5'}>
                <FontAwesomeIcon title={'line-chart'} icon={type === 'xy' ? faLineChart : faPieChart}
                                 style={{width: '1rem', height: '1rem'}}
                                 className={'fa-color'}/>
                <span>{lang[LANG]}</span>
            </div>
            <FontAwesomeIcon title={'minus'} icon={!isOpen ? faChevronDown : faChevronUp}
                             style={{width: '1rem', height: 'rem'}}
                             className={'fa-secondary-color-text'}/>
        </button>
        {isOpen && <IntervalSelection chartId={chartId} type={type}/>}
        <ChartPeriod chartId={chartId} showPicker={isOpen}/>
    </div>
}
