import GraphLayout from "./GraphLayout";
import React, {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {STATIC_CHARTS} from "../../staticSchemas";
import {createInitialStateForCharts, setInitialChartsState} from "../../features/chartsSlice";
import './css/graphs.css'


export default function GraphsPage({projectName}: { projectName: string }) {
    const loaded = useAppSelector(state => state.charts.loaded);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!loaded)
            (async () => {

                let schema;
                // if (process.env.NODE_ENV === 'development') {
                //     schema = STATIC_CHARTS[projectName]
                // } else
                //     schema = await fetch(`/schemas/charts/${projectName}.json`).then(r => r.json());
                schema = STATIC_CHARTS[projectName]
                if (schema) {
                    let state = await createInitialStateForCharts(schema, projectName);
                    dispatch(setInitialChartsState(state))
                }

            })();
    }, [projectName, dispatch, loaded]);

    return loaded ? <div>
        {/*<div className="flex fl-al-ce soon">*/}
        {/*    НЕЗАБАРОМ!*/}
        {/*</div>*/}
        <GraphLayout projectName={projectName}/>
    </div> : null
}
