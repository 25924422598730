import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {darkTheme, DataProvider, lightTheme, XYChartTheme} from '@visx/xychart';
import {AnimationTrajectory} from '@visx/react-spring/lib/types';
import {GlyphCircle, GlyphCross, GlyphDiamond, GlyphSquare, GlyphStar, GlyphTriangle, GlyphWye} from '@visx/glyph';
import {curveCardinal, curveLinear, curveStep} from '@visx/curve';
import getAnimatedOrUnanimatedComponents from '../getAnimatedOrUnanimatedComponents';
import styled from "styled-components";
import {ChartI, KeyOfXyChartI, NormalObject, XyChartI} from "../../../Types";
import {useAppDispatch, useAppSelector} from "../../../hooks";
import {
    changeChartHeight,
    changeChartName,
    changeChartSetting,
    changeIndexedChartSetting,
    restoreChart,
    saveChartLocally
} from "../../../features/chartsSlice";
import {MIN_SLOT_WIDTH} from "../../graphs/GraphLayout";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faGear, faPieChart} from "@fortawesome/free-solid-svg-icons";
import Icon from "../../misc/Icon";
import Legend from "./Legend";
import {GREEN_COLOR, RED_COLOR, XY_GAP} from "../../CONSTANTS";

const dateScaleConfig = {type: 'time', paddingInner: 0.3, zero: true} as const;
const linearScaleConfig = {type: 'linear', paddingInner: 0.3, zero: true} as const;
const bandScaleConfig = {type: 'band', paddingInner: 0.3, zero: true} as const;

const lang = 'ua'

const lineSeries = [
    {
        name: 'Ламана',
        value: 'line'
    },
    {
        name: 'Площа',
        value: 'area'
    },
    {
        name: 'Площа накопич.',
        value: 'areastack'
    }, {
        name: 'Жодна',
        value: 'none'
    },
]

const barSeries = [
    {
        name: 'Стовпчик',
        value: 'bar'
    },
    {
        name: 'Стовпчики накопич.',
        value: 'barstack'
    },
    {
        name: 'Група стовпчиків',
        value: 'bargroup'
    }, {
        name: 'Жодна',
        value: 'none'
    },
]

const lineCurveShapes = [
    {
        name: 'Пряма',
        value: 'linear'
    },
    {
        name: 'Крива',
        value: 'cardinal'
    },
    {
        name: 'Сходинкова',
        value: 'step'
    },
]

const animationTrajectories = [
    {
        name: 'З центру',
        value: 'center'
    },
    {
        name: 'Ззовні',
        value: 'outside'
    },
    {
        name: 'З мінімума',
        value: 'min'
    }, {
        name: 'З максимума',
        value: 'max'
    },
]

const tooltipCheckboxOptions = [
    {
        name: 'Показувати підказку',
        value: 'showTooltip'
    },
    {
        name: 'Вертикальне перехрестя',
        value: 'showVerticalCrosshair'
    },
    {
        name: 'Горизонтальне перехрестя',
        value: 'showHorizontalCrosshair'
    }, {
        name: 'Прив`язати по X',
        value: 'snapTooltipToDatumX'
    }, {
        name: 'Прив`язати по Y',
        value: 'snapTooltipToDatumY'
    }, {
        name: 'Спільна підказка',
        value: 'sharedTooltip'
    },
] as { name: string, value: KeyOfXyChartI }[]

const stackOffsetVariants = [
    {
        name: 'Авто (zero-baseline)',
        value: undefined
    },
    {
        name: 'Розширити (сума = 1)',
        value: 'expand'
    },
    {
        name: 'Wiggle',
        value: 'wiggle'
    }, {
        name: 'Diverging',
        value: 'diverging'
    }, {
        name: 'Silhouette',
        value: 'silhouette'
    },
] as { name: string, value: "wiggle" | "expand" | "diverging" | "silhouette" | undefined }[]

const getDate = (d: NormalObject, key: string, dateKey: string) => (d[dateKey] as string | number);
const getData = (d: NormalObject, key: string, dateKey: string) => (d[key] as string | number);

type Accessor = (d: NormalObject, key: string, dateKey: string) => number | string;

type DataKey = string;

type SimpleScaleConfig = { type: 'band' | 'linear' | 'time'; paddingInner?: number };

type ProvidedProps = {
    accessors: {
        x: Accessor;
        y: Accessor;
        date: Accessor;
    };
    occupySlots: number,
    usePie: boolean,
    animationTrajectory?: AnimationTrajectory;
    colorAccessorFactory: (key: DataKey) => ((d: NormalObject) => string | null);
    colorAccessorFactoryForLines: (key: DataKey) => (string | null);
    config: {
        x: SimpleScaleConfig;
        y: SimpleScaleConfig;
    };
    curve: typeof curveLinear | typeof curveCardinal | typeof curveStep;
    renderAreaSeries: boolean;
    renderAreaStack: boolean;
    renderBarGroup: boolean;
    renderBarSeries: boolean;
    renderBarStack: boolean;
    renderGlyphSeries: boolean;
    // renderTooltipGlyph: React.FC<RenderTooltipGlyphProps<NormalObject>>;
    renderHorizontally: boolean;
    renderLineSeries: boolean;
    sharedTooltip: boolean;
    showGridColumns: boolean;
    showGridRows: boolean;
    showHorizontalCrosshair: boolean;
    showTooltip: boolean;
    showVerticalCrosshair: boolean;
    snapTooltipToDatumX: boolean;
    snapTooltipToDatumY: boolean;
    stackOffset?: 'wiggle' | 'expand' | 'diverging' | 'silhouette';
    theme: XYChartTheme;
    xAxisOrientation: 'top' | 'bottom';
    yAxisOrientation: 'left' | 'right';
} & ReturnType<typeof getAnimatedOrUnanimatedComponents>;

type ControlsProps = {
    children: (props: ProvidedProps) => React.ReactNode;
    chartId: string
    slots: number,
    width: number,
    isHovered: boolean
};


export default function XyControls({children, chartId, slots, width, isHovered}: ControlsProps) {

    const isEditing = useAppSelector(state => (state.layout.isDashboardPaneEditing))
    const projectName = useAppSelector(state => (state.project.name))
    const chart = useAppSelector(state => (state.charts.entries[chartId]) as ChartI<XyChartI>)
    const {settings, height} = chart
    const name = chart.lang[lang]
    const heightInputRef = useRef<HTMLInputElement>(null)
    const nameInputRef = useRef<HTMLInputElement>(null)
    const xAxisNameInputRef = useRef<HTMLInputElement>(null)
    const yAxisNameInputRef = useRef<HTMLInputElement>(null)
    const dispatch = useAppDispatch();

    const [showSettings, setShowSettings] = useState(false)

    const [showGridRows, showGridColumns] = settings.gridProps!;

    // for series that support it, return a colorAccessor which returns a custom color if the datum is selected

    const colorAccessorFactoryForLines = useCallback(
        (dataKey: DataKey) => settings.colors ? settings.colors[dataKey] ?? null : null,
        [settings.colors],
    );

    const accessors = useMemo(
        () => ({
            x: settings.renderHorizontally ? getData : getDate,
            y: settings.renderHorizontally ? getDate : getData,
            date: getDate,
        }),
        [settings.renderHorizontally],
    );

    const colorAccessorFactory = useCallback(
        (dataKey: string) => (d: NormalObject) => settings.colors ? settings.colors[dataKey] ?? null : null,
        [settings.colors],);




    const config = useMemo(
        () => ({
            x: settings.renderHorizontally ? linearScaleConfig : (settings.barType !== 'none' ? bandScaleConfig : dateScaleConfig),
            y: settings.renderHorizontally ? (settings.barType !== 'none' ? bandScaleConfig : dateScaleConfig) : linearScaleConfig,
        }),
        [settings.barType, settings.renderHorizontally],
    );

    function changeHeight() {
        let number = Number.parseInt(heightInputRef.current?.value!);
        let value = Number.isNaN(number) ? 500 : number;
        dispatch(changeChartHeight({chartId, value: value}))
        if (heightInputRef.current)
            heightInputRef.current.value = value.toString()
    }

    function changeName() {
        dispatch(changeChartName({chartId, value: nameInputRef.current?.value!, lang: lang}))
    }

    function changeSetting(field: string, value: any) {
        dispatch(changeChartSetting({
            chartId: chartId,
            property: field,
            value: value
        }))
    }

    function changeIndexedSetting(field: string, value: any, index: string | number, indexedName: string) {
        dispatch(changeIndexedChartSetting({
            chartId: chartId,
            property: field,
            value: value,
            index: index,
            indexedName: indexedName
        }))
    }

    // cannot snap to a stack position
    const canSnapTooltipToDatum =
        settings.barType !== 'barstack' && settings.lineType !== 'areastack';

    const actualSlots = Math.max(Math.floor(width / MIN_SLOT_WIDTH), 1)

    const [opacity, setOpacity] = useState(0);


    useEffect(() => {
        setOpacity(1);
    }, []);

    return (
        <Container style={{opacity: opacity}}>
            <DataProvider theme={settings.theme} xScale={config.x}
                          yScale={config.y}>
                    <div className={'chart-head'}>
                        <h3 className={'chart-name flex gap-2 fl-al-ce'}>
                            {name}
                        </h3>
                        <div className="chart-controls">
                            <button className={'chart-head-controls-button'}
                                    onClick={() => changeSetting('usePie', !settings.usePie)}>
                                <FontAwesomeIcon title={'line-chart'} icon={faPieChart}
                                                 style={{width: '1.2rem', height: '1.2rem'}}
                                                 className={'fa-color'}/>
                            </button>
                            {isEditing && <button className={'chart-head-controls-button'}
                                                  onClick={() => setShowSettings(!showSettings)}>
                                <Icon src={"/win-icons/Settings.ico"}
                                      title={'Налаштування'} alt={'Налаштування'}
                                      width={'1.2rem'}
                                      height={'1.2rem'}
                                      component={faGear}
                                />
                            </button>}
                        </div>
                    </div>
                    <div className="xy-chart-container flex fl-ju-ce fl-al-ce">
                        {children({
                            accessors,
                            occupySlots: actualSlots - (settings.usePie && actualSlots > 2 ? 1 : 0),
                            usePie: !!settings.usePie && actualSlots > 2,
                            animationTrajectory: settings.animationTrajectory,
                            colorAccessorFactory,
                            colorAccessorFactoryForLines,
                            config,
                            curve:
                                (settings.curveShape === 'cardinal' && curveCardinal) ||
                                (settings.curveShape === 'step' && curveStep) ||
                                curveLinear,
                            renderBarGroup: settings.barType === 'bargroup',
                            renderBarSeries: settings.barType === 'bar',
                            renderBarStack: settings.barType === 'barstack',
                            renderGlyphSeries: !settings.hideGlyphs && !(settings.barType === 'barstack' ||
                                settings.lineType === 'areastack' || settings.barType === 'bargroup' || settings.barType === 'bar'),
                            // renderTooltipGlyph,
                            renderHorizontally: !!settings.renderHorizontally,
                            renderAreaSeries: settings.lineType === 'area',
                            renderAreaStack: settings.lineType === 'areastack',
                            renderLineSeries: settings.lineType === 'line',
                            sharedTooltip: !!settings.sharedTooltip,
                            showGridColumns,
                            showGridRows,
                            showHorizontalCrosshair: settings.showHorizontalCrosshair!,
                            showTooltip: settings.showTooltip!,
                            showVerticalCrosshair: settings.showVerticalCrosshair!,
                            snapTooltipToDatumX: canSnapTooltipToDatum && settings.snapTooltipToDatumX!,
                            snapTooltipToDatumY: canSnapTooltipToDatum && settings.snapTooltipToDatumY!,
                            stackOffset: settings.stackOffset,
                            theme: settings.theme!,
                            xAxisOrientation: settings.xAxisOrientation!,
                            yAxisOrientation: settings.yAxisOrientation!,
                            ...getAnimatedOrUnanimatedComponents(settings.useAnimatedComponents && !isHovered),
                        })}

                    </div>

                    <Legend entries={settings.entries} colorFunction={colorAccessorFactoryForLines}/>

                    {/** This style is used for annotated elements via colorAccessor. */}
                    {/*<svg className="pattern-lines">*/}
                    {/*    <PatternLines*/}
                    {/*        id={selectedDatumPatternId}*/}
                    {/*        width={6}*/}
                    {/*        height={6}*/}
                    {/*        orientation={['diagonalRightToLeft']}*/}
                    {/*        stroke={settings.theme?.axisStyles.x.bottom.axisLine.stroke}*/}
                    {/*        strokeWidth={1.5}*/}
                    {/*    />*/}
                    {/*</svg>*/}
                    {showSettings && <Wrapper $useTwoCols={width / MIN_SLOT_WIDTH > 2} className={'xy-settings'}>

                        <div className="first-controls-col flex fl-col gap-5">
                            <div className={'flex fl-al-ce gap-5 fl-wrap save-btns'}>
                                <button className="save"
                                        onClick={() => {
                                            saveChartLocally(chart, projectName, chartId)
                                            setShowSettings(false)
                                        }}>Зберегти налаштування
                                </button>
                                <button className="restore" onClick={() => {
                                    restoreChart(projectName, chartId)
                                    setShowSettings(false)
                                }}>Скинути
                                </button>
                            </div>
                            <div className={'flex fl-al-ce gap-5 fl-wrap'}>
                                <strong>Назва графіка:</strong>
                                <label>
                                    <input
                                        className={'chart-axis-name-input'}
                                        type="text"
                                        ref={nameInputRef}
                                        defaultValue={name}
                                    />
                                </label>
                                <button className={'chart-head-controls-button'}
                                        onClick={changeName}>
                                    <Icon src={"/win-icons/Check mard.ico"}
                                          title={'Налаштування'} alt={'Налаштування'}
                                          width={'1.2rem'}
                                          height={'1.2rem'}
                                          component={faCheck}
                                    />
                                </button>
                            </div>

                            {/** series */}
                            {/** orientation */}
                            {/*entries*/}
                            <div className={'flex fl-col gap-2 fl-wrap'}>
                                <strong>Значення:</strong>
                                {settings.entries.map((e, i) =>
                                    <div className={'flex fl-al-ce gap-5 ml-4 fl-wrap'} key={e.key}>
                                        <span
                                            style={{color: settings.colors?.[e.key!] || 'unset'}}>{e.lang![lang]}</span>
                                        <label className={'flex fl-al-ce gap-2'}>
                                            <input
                                                type="checkbox"
                                                onChange={() => changeIndexedSetting('hide', !e.hide, i, 'entries')}
                                                checked={!e.hide}
                                            />
                                            показувати
                                        </label>
                                    </div>
                                )}
                            </div>


                            {/** glyph */}
                            <div className={'flex fl-col gap-2 fl-wrap'}>
                                <label>
                                    <strong className={'mr-2'}>Гліфи:</strong>
                                    <input type="checkbox" checked={!settings.hideGlyphs}
                                           onChange={() => changeSetting('hideGlyphs', !settings.hideGlyphs)}/>
                                    <span className={'ml-2'}>показувати</span>
                                </label>
                                {settings.glyphs.map((g, i) => <div key={g.key}
                                                                    className={'flex fl-al-ce fl-wrap gap-5 ml-4 fl-wrap'}>
                                    <div className={'flex fl-al-ce gap-2'}>
                                        <input type="checkbox" checked={!settings.glyphs[i].hide}
                                               onChange={() => changeIndexedSetting('hide', !g.hide, i, 'glyphs')}/>
                                        <span
                                            style={{color: settings.colors?.[g.key!] || 'unset'}}>{g.lang![lang]}</span>
                                    </div>
                                    <label>
                                        <input className={'glyph-input'} type="text" width={'3em'}
                                               disabled={!!settings.hideGlyphs}
                                               onChange={(e) => changeIndexedSetting('value', e.target.value, i, 'glyphs')}
                                               value={g.value}/>
                                    </label>
                                    <select
                                        onChange={(e) => changeIndexedSetting('value', e.target.value, i, 'glyphs')}>
                                        {glyphsNames.map(g => <option value={g.value} key={g.value}>{g.name}</option>)}
                                    </select>
                                </div>)}
                            </div>


                            <div className={'flex fl-al-ce gap-5 fl-wrap'}>
                                <strong>Серія прямих:</strong>
                                <select value={settings.lineType} onChange={(e) => {
                                    changeSetting('lineType', e.target.value)
                                    changeSetting('barType', 'none')
                                }}>
                                    {lineSeries.map(g => <option value={g.value} key={g.value}>{g.name}</option>)}
                                </select>
                            </div>

                            {settings.lineType !== 'none' && <div className={'flex fl-al-ce gap-5 fl-wrap'}>
                                <strong>Форма прямих:</strong>
                                <select value={settings.curveShape}
                                        onChange={(e) => changeSetting('curveShape', e.target.value)}>
                                    {lineCurveShapes.map(g => <option value={g.value} key={g.value}>{g.name}</option>)}
                                </select>
                            </div>}

                            <div className={'flex fl-al-ce gap-5 fl-wrap'}>
                                <strong>Серія стовчиків:</strong>
                                <select value={settings.barType}
                                        onChange={(e) => {
                                            changeSetting('lineType', 'none')
                                            changeSetting('barType', e.target.value)
                                        }}>
                                    {barSeries.map(g => <option value={g.value} key={g.value}>{g.name}</option>)}
                                </select>
                            </div>

                            <div className={'flex fl-al-ce gap-5 fl-wrap'}>
                                <strong>Зміщення серії стека:</strong>
                                <select value={settings.stackOffset}
                                        onChange={(e) => changeSetting('stackOffset', e.target.value)}>
                                    {stackOffsetVariants.map(g => <option value={g.value}
                                                                          key={g.value ?? 'undefined'}>{g.name}</option>)}
                                </select>
                            </div>
                        </div>

                        <div className="second-controls-col flex fl-col gap-5">
                            <div className={'flex fl-al-ce gap-5 fl-wrap'}>
                                <strong>Орієнтація серій:</strong>
                                <label className={'flex fl-al-ce gap-2'}>
                                    <input
                                        type="radio"
                                        onChange={() => changeSetting('renderHorizontally', !settings.renderHorizontally)}
                                        checked={!settings.renderHorizontally}
                                    />
                                    вертикальна
                                </label>
                                <label className={'flex fl-al-ce gap-2'}>
                                    <input
                                        type="radio"
                                        onChange={() => changeSetting('renderHorizontally', !settings.renderHorizontally)}
                                        checked={settings.renderHorizontally}
                                    />
                                    горизонтальна
                                </label>
                            </div>

                            <div className={'flex fl-al-ce gap-5 fl-wrap'}>
                                <strong>Тема:</strong>
                                <label className={'flex fl-al-ce gap-2'}>
                                    <input
                                        type="radio"
                                        onChange={() => changeSetting('theme', lightTheme)}
                                        checked={settings.theme === lightTheme}
                                    />
                                    світла
                                </label>
                                <label className={'flex fl-al-ce gap-2'}>
                                    <input
                                        type="radio"
                                        onChange={() => changeSetting('theme', darkTheme)}
                                        checked={settings.theme === darkTheme}
                                    />
                                    темна
                                </label>
                            </div>

                            {/** tooltip */}
                            <div className={'flex fl-al-ce gap-5 fl-wrap'}>
                                <strong>Підказка:</strong>
                                {tooltipCheckboxOptions.slice(0, 5).map(t => <label key={t.value}
                                                                                    className={'flex fl-al-ce gap-2'}>
                                    <input
                                        type="checkbox"
                                        onChange={() => changeSetting(t.value, !settings[t.value])}
                                        checked={!!settings[t.value]}
                                    />
                                    {t.name}
                                </label>)}
                            </div>

                            <div className={'flex fl-al-ce gap-5 fl-wrap'}>
                                <strong>Гліфи підказки:</strong>
                                <label>
                                    <input
                                        className={'glyph-input'}
                                        type="text"
                                        width={'3em'}
                                        onChange={(e) => changeSetting('tooltipGlyphComponent', e.target.value)}
                                        value={settings.tooltipGlyphComponent}
                                    />
                                </label>
                                <select name="tooltip-glyps" id="tooltip-glyps"
                                        onChange={(e) => changeSetting('tooltipGlyphComponent', e.target.value)}>
                                    {glyphsNames.map(g => <option value={g.value} key={g.value}>{g.name}</option>)}
                                </select>
                                {tooltipCheckboxOptions.slice(5, 6).map(t => <label key={t.value}
                                                                                    className={'flex fl-al-ce gap-2'}>
                                    <input
                                        type="checkbox"
                                        onChange={() => changeSetting(t.value, !settings[t.value])}
                                        checked={!!settings[t.value]}
                                    />
                                    {t.name}
                                </label>)}
                            </div>

                            {/** axes */}
                            <div className={'flex fl-al-ce gap-5 fl-wrap'}>
                                <strong>Вісі:</strong>
                                <label>
                                    <input
                                        type="radio"
                                        onChange={() => changeSetting('xAxisOrientation', 'bottom')}
                                        checked={settings.xAxisOrientation === 'bottom'}
                                    />
                                    Низ
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        onChange={() => changeSetting('xAxisOrientation', 'top')}
                                        checked={settings.xAxisOrientation === 'top'}
                                    />
                                    Верх
                                </label>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <label>
                                    <input
                                        type="radio"
                                        onChange={() => changeSetting('yAxisOrientation', 'left')}
                                        checked={settings.yAxisOrientation === 'left'}
                                    />
                                    Ліво
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        onChange={() => changeSetting('yAxisOrientation', 'right')}
                                        checked={settings.yAxisOrientation === 'right'}
                                    />
                                    Право
                                </label>
                            </div>

                            {/** grid */}
                            <div className={'flex fl-al-ce gap-5 fl-wrap'}>
                                <strong>Сітка:</strong>
                                <label>
                                    <input
                                        type="radio"
                                        onChange={() => changeSetting('gridProps', [true, false])}
                                        checked={showGridRows && !showGridColumns}
                                    />
                                    рядки
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        onChange={() => changeSetting('gridProps', [false, true])}
                                        checked={!showGridRows && showGridColumns}
                                    />
                                    стовпці
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        onChange={() => changeSetting('gridProps', [true, true])}
                                        checked={showGridRows && showGridColumns}
                                    />
                                    р + с
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        onChange={() => changeSetting('gridProps', [false, false])}
                                        checked={!showGridRows && !showGridColumns}
                                    />
                                    нічого
                                </label>
                            </div>
                            {/** animation trajectory */}

                            <div className={'flex fl-al-ce gap-5 fl-wrap'}>
                                <label className={'flex fl-al-ce gap-2'}>
                                    <input
                                        type="checkbox"
                                        onChange={() => changeSetting('useAnimatedComponents', !settings.useAnimatedComponents)}
                                        checked={settings.useAnimatedComponents}
                                    />
                                    Анімовані компоненти
                                </label>
                            </div>
                            <div className={'flex fl-al-ce gap-5 fl-wrap'}>
                                {settings.useAnimatedComponents && (<>
                                        <strong>Анімація осей та сітки:</strong>
                                        {animationTrajectories.map(s => <label className={'flex fl-al-ce gap-2'}
                                                                               key={s.value}>
                                            <input type="radio" checked={settings.animationTrajectory === s.value}
                                                   onChange={() => changeSetting('animationTrajectory', s.value)}/>
                                            {s.name}
                                        </label>)}
                                    </>
                                )}
                            </div>

                            <div className={'flex fl-al-ce gap-5 fl-wrap'}>
                                <strong>Висота графіка:</strong>
                                <label>
                                    <input
                                        ref={heightInputRef}
                                        className={'chart-height-input'}
                                        type="number"
                                        defaultValue={height}
                                    />
                                </label>
                                <button className={'chart-head-controls-button'} onClick={changeHeight}>
                                    <Icon src={"/win-icons/Check mard.ico"}
                                          title={'Налаштування'} alt={'Налаштування'}
                                          width={'1.2rem'}
                                          height={'1.2rem'}
                                          component={faCheck}
                                    />
                                </button>
                            </div>
                            <div className={'flex fl-al-ce gap-5 fl-wrap'}>
                                <strong>Назва вісі X:</strong>
                                <label>
                                    <input
                                        className={'chart-axis-name-input'}
                                        type="text"
                                        ref={xAxisNameInputRef}
                                        defaultValue={settings.xAxisName}
                                    />
                                </label>
                                <button className={'chart-head-controls-button'}
                                        onClick={() => changeSetting('xAxisName', xAxisNameInputRef.current?.value)}>
                                    <Icon src={"/win-icons/Check mard.ico"}
                                          title={'Налаштування'} alt={'Налаштування'}
                                          width={'1.2rem'}
                                          height={'1.2rem'}
                                          component={faCheck}
                                    />
                                </button>
                            </div>
                            <div className={'flex fl-al-ce gap-5 fl-wrap'}>
                                <strong>Назва вісі Y:</strong>
                                <label>
                                    <input
                                        className={'chart-axis-name-input'}
                                        type="text"
                                        ref={yAxisNameInputRef}
                                        defaultValue={settings.yAxisName}
                                    />
                                </label>
                                <button className={'chart-head-controls-button'}
                                        onClick={() => changeSetting('yAxisName', yAxisNameInputRef.current?.value)}>
                                    <Icon src={"/win-icons/Check mard.ico"}
                                          title={'Налаштування'} alt={'Налаштування'}
                                          width={'1.2rem'}
                                          height={'1.2rem'}
                                          component={faCheck}
                                    />
                                </button>
                            </div>
                            <div className={'flex fl-al-ce gap-5 fl-wrap xy-settings'}>
                                <strong>Кругова діаграма: </strong>
                                <label className={'flex fl-al-ce gap-2'}>
                                    <input
                                        type="checkbox"
                                        onChange={() => changeSetting('usePieNumberLabel', !settings.usePieNumberLabel)}
                                        checked={settings.usePieNumberLabel}
                                    />
                                    Показувати чисельне значення
                                </label>
                                <label className={'flex fl-al-ce gap-2'}>
                                    <input
                                        type="checkbox"
                                        onChange={() => changeSetting('usePieNumberPercentage', !settings.usePieNumberPercentage)}
                                        checked={settings.usePieNumberPercentage}
                                    />
                                    Показувати відсоткове значення
                                </label>
                                <label className={'flex fl-al-ce gap-2'}>
                                    <input
                                        type="checkbox"
                                        onChange={() => changeSetting('pieWhole', !settings.pieWhole)}
                                        checked={settings.pieWhole}
                                    />
                                    Форма - ціла
                                </label>
                            </div>
                        </div>
                    </Wrapper>}
            </DataProvider>
        </Container>
    )
}

const Container = styled.div`
    background-color: hsl(from var(--table-row-even-bg-color) h s l / 0.3);
    background-color: var(--table-row-even-bg-color);
    border-radius: .5rem;
    padding: 8px;

    opacity: 0;
    transition: opacity .4s ease;

    & .xy-chart-container {
        display: flex;
        gap: ${XY_GAP}px;
    }

    & .save-btns {
        & .save {
            background: ${GREEN_COLOR};
            color: var(--secondary-text);
            border: none;
            padding: .2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: .2rem;
            font-size: 1rem;
        }

        & .restore {
            font-size: 1rem;
            background: ${RED_COLOR};
            color: var(--secondary-text);
            border: none;
            padding: .2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: .2rem;
        }
    }
`


const Wrapper = styled.div<any>`
    display: flex;

    flex-flow: ${props => props.$useTwoCols ? 'row' : 'column'};

    gap: .5rem;
    padding-bottom: .5rem;
    padding-top: .5rem;

    & .first-controls-col {

        flex-basis: ${props => props.$useTwoCols ? 'auto' : '100%'};
        flex-shrink: 0;
    }

    & .second-controls-col {
        flex-basis: ${props => props.$useTwoCols ? 'auto' : '100%'};
    }
`

export const Glyphs = {
    circle: GlyphCircle,
    cross: GlyphCross,
    diamond: GlyphDiamond,
    star: GlyphStar,
    triangle: GlyphTriangle,
    square: GlyphSquare,
    wye: GlyphWye,
} as const;

export const glyphsNames = [
    {name: 'Круг', value: 'circle'},
    {name: 'Хрест', value: 'cross'},
    {name: 'Діамант', value: 'diamond'},
    {name: 'Зірка', value: 'star'},
    {name: 'Трикутник', value: 'triangle'},
    {name: 'Квадрат', value: 'square'},
    {name: 'Y-гліф', value: 'wye'}
] as { name: string, value: KeyOfGlyphs }[]

export type KeyOfGlyphs = keyof typeof Glyphs;

