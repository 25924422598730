import React from "react";
import styled from "styled-components";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {changePerPage, fetchTableByQuery} from "../../features/tableSlice";

const options = [{
    label: '20',
    value: 20
}, {
    label: '50',
    value: 50
}, {
    label: '100',
    value: 100
}, {
    label: '250',
    value: 250
}, {
    label: '500',
    value: 500
}] as readonly ({ label: string; value: number; })[]

const TablePerPage = ({tableName}: { tableName: string }) => {

    const limit = useAppSelector(state => state.tables.queries[tableName].current.limit)

    const dispatch = useAppDispatch();
    const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        dispatch(changePerPage(parseInt(e.currentTarget.value)))
        dispatch(fetchTableByQuery({clean: true, pageIndex: 1}))
    }

    return <Container>
        <div>Показувати на сторінці:</div>
        <select value={limit} onChange={e => onChange(e)}>
            {options.map(({label, value}) => {
                return <option key={value} value={value}>{label}</option>
            })}
        </select>
    </Container>
}

export default TablePerPage

const Container = styled.div`
    display: flex;
    padding: .2rem 0;
    gap: .2rem;
    align-items: center;
`
