import React, {useEffect, useState} from 'react';
import chroma from "chroma-js";

const ThreeCircles: React.FC = () => {

    const [color, setColor] = useState('#123')

    useEffect(() => {
        const primary = window.getComputedStyle(window.document.documentElement)
            .getPropertyValue('--table-action-color');
        setColor(primary)
    }, []);


    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 16" width="32" height="16">
            <circle cx="4" cy="8" r="4" fill={chroma(color).brighten(1).hex()}/>
            <circle cx="16" cy="8" r="4" fill={color}/>
            <circle cx="28" cy="8" r="4" fill={chroma(color).darken(1).hex()}/>
        </svg>
    );
};

export default ThreeCircles;
