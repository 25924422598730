import {
    AnimatedAreaSeries,
    AnimatedAreaStack,
    AnimatedAxis,
    AnimatedBarGroup,
    AnimatedBarSeries,
    AnimatedBarStack,
    AnimatedGrid,
    AnimatedLineSeries,
    AreaSeries,
    AreaStack,
    Axis,
    BarGroup,
    BarSeries,
    BarStack,
    GlyphSeries,
    Grid,
    LineSeries,
    Tooltip,
    XYChart,
} from '@visx/xychart';

export default function getAnimatedOrUnanimatedComponents(animated?: boolean) {
    return animated
        ? {
            AreaSeries: AnimatedAreaSeries,
            AreaStack: AnimatedAreaStack,
            Axis: AnimatedAxis,
            BarGroup: AnimatedBarGroup,
            BarSeries: AnimatedBarSeries,
            BarStack: AnimatedBarStack,
            GlyphSeries: GlyphSeries,
            Grid: AnimatedGrid,
            LineSeries: AnimatedLineSeries,
            Tooltip,
            XYChart,
        }
        : {
            AreaSeries,
            AreaStack,
            Axis,
            BarGroup,
            BarSeries,
            BarStack,
            GlyphSeries,
            Grid,
            LineSeries,
            Tooltip,
            XYChart,
        };
}
