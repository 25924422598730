import {ChartI, XyChartI} from "../../Types";
import {GREEN_COLOR, RED_COLOR} from "../../components/CONSTANTS";

const inmsgByTypes: ChartI<XyChartI> = {
    id: "inmsgByTypes",
    queryId: "inmsgByTypes",
    lang: {
        ua: 'Реєстрації за тиждень (Усі)'
    },
    type: "xy",
    height: 450,
    parameters: {
        periods: ["Day1", "Day2", "Day3", "Day7", "Day14", "Month1", "Month3"],
        selectedPeriod: "Day7",
        selectedInterval: "Day1",
        filters: {
            from: {
                lang: {ua: 'З'},
                key: 'from',
                type: 'datetime'
            },
            to: {
                lang: {ua: 'До'},
                key: 'to',
                type: 'datetime'
            }
        }
    },
    settings: {
        usePie: false,
        pieWhole: false,
        usePieNumberLabel: false,
        usePieNumberPercentage: true,
        pieShortNames: {
            correct: 'Кор',
            incorrect: 'Некор',
            duplicate: 'Дубль',
        },
        entries: [
            {
                key: 'incorrect',
                lang: {
                    ua: 'Некоректні'
                }
            }, {
                key: 'duplicate',
                lang: {
                    ua: 'Дублікат'
                }
            }, {
                key: 'correct',
                lang: {
                    ua: 'Коректні'
                }
            },
        ],
        colors: {
            correct: GREEN_COLOR,
            incorrect: RED_COLOR,
            duplicate: '#148aff',
        },
        glyphs: [],
        barType: "bargroup",
        lineType: "none",
        gridProps: [true, true],
        tooltipGlyphComponent: "🐋"
    }
}

export default inmsgByTypes
