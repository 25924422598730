import {FieldOperations, OpsCategories, SqlOperation, TableI} from "../../Types";
import moment from "moment";
import {nanoid} from "@reduxjs/toolkit";


let fieldOperations: FieldOperations = {
    id: [...OpsCategories.eq, ...OpsCategories.range, ...OpsCategories.in,],
    dt: [...OpsCategories.range],
    title: [...OpsCategories.like],
    start_timestamp: [...OpsCategories.range],
    end_timestamp: [...OpsCategories.range],
};

export function seminars287DefFilters() {
    return [
        {
            nanoid: nanoid(),
            field: 'start_timestamp',
            type: 'datetime',
            filterOp: SqlOperation.GREATER_THAN_OR_EQUAL,
            locked: true,
            value: moment("2024-10-01").startOf('day').format('yyyy-MM-DD HH:mm:ss')
        },
        {
            nanoid: nanoid(),
            field: 'start_timestamp',
            type: 'datetime',
            filterOp: SqlOperation.LESS_THAN_OR_EQUAL,
            locked: true,
            value: moment().add(21, 'days').endOf('day').locale('en').format('yyyy-MM-DD HH:mm:ss')
        }
    ]
}

export const SeminarsTable: TableI = {
    backend: "/287/",
    title: {
        ua: "Семінари",
    },
    pk: 'id',
    hidden: true,
    defaultOrderedBy: "start_timestamp",
    defaultOrderedWay: "DESC",
    defaultPerPage: 20,
    orderByFields: {
        "id": {ua: "ID", en: "№"},
        "dt": {ua: "Створено", ru: "Дата"},
        "start_timestamp": {ua: "Початок", ru: "Уровень"},
        "end_timestamp": {ua: "Кінець", ru: "Подтвержденый"},
    },
    fieldOperations: fieldOperations,
    action: 'act287createSeminarComponent',
    defaultFilters: 'seminars287DefFilters',
    fields: [
        {
            key: "n",
            inline: true,
            title: {ua: "№", ru: "№"}, type: "number",
            applyTransform: true
        },
        {
            key: "title",
            title: {ua: "Тема", ru: "Фамилия И.П."},
            type: 'string',
            detailed: true,
            editable: true,
            inline: true,
            required: true,
        },
        {
            key: "description",
            title: {ua: "Опис", ru: "Фамилия И.П."},
            type: 'string',
            detailed: true,
            editable: true,
            inline: false,
        },
        {
            key: "start_timestamp",
            title: {ua: "Початок", ru: "Телефон"},
            type: "datetime",
            datetimeFormat: "DD MMMM YYYY HH:mm",
            applyTransform: true,
            editable: true,
            detailed: true,
            inline: true,
        }, {
            key: "end_timestamp",
            title: {ua: "Кінець", ru: "Email"},
            type: "datetime",
            datetimeFormat: "DD MMMM YYYY HH:mm",
            applyTransform: true,
            editable: true,
            detailed: true,
            inline: true,
        },
        {
            key: "reg_count",
            title: {ua: "Реєстрацій", ru: "Тема"},
            type: 'string',
            detailed: true,
            inline: true,
            required: true,
        },
        {
            key: "presenter",
            title: {ua: "Доповідач", ru: "Тема"},
            type: 'string',
            detailed: true,
            editable: true,
            inline: true,
        }, {
            key: "video_url",
            title: {ua: "Відео", ru: "Нова Пошта"},
            type: 'url',
            applyTransform: true,
            detailed: true,
            editable: true,
            inline: false,
        }, {
            key: "program_url",
            title: {ua: "Програма", ru: "Дата"},
            type: 'url',
            detailed: true,
            editable: true,
            inline: false,
        },
        {
            key: "max_regs",
            title: {ua: "Максимум місць", ru: "Дата"},
            type: "number",
            detailed: true,
            editable: true,
            inline: false
        },
        {
            key: "dt",
            title: {ua: "Створено", ru: "Дата"},
            type: "datetime",
            datetimeFormat: "DD MMMM YYYY HH:mm",
            applyTransform: true,
            detailed: true,
            inline: false,
        },
        {
            key: "id",
            detailed: true,
            title: {ua: "ID", ru: "№"},
            type: "number",
            applyTransform: true,
        },
    ]
}
