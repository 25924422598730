import React from 'react';
import styled from "styled-components";
import Icon from "../misc/Icon";
import {faFloppyDisk} from "@fortawesome/free-solid-svg-icons";

function UpdateFieldButton({disabled, onClick}: { disabled: boolean, onClick: () => void }) {

    return <Button disabled={disabled} className={'save-edit-button'} onClick={onClick}>
        <Icon src={"/win-icons/Diskette.ico"}
              title={'SAVE'} alt={'SAVE'}
              width={'1.5rem'}
              height={'1.5rem'}
              component={faFloppyDisk}
        />
    </Button>
}

export default UpdateFieldButton;

const Button = styled.button`
    display: flex;
    
    justify-content: center;
    align-items: center;

    //margin: .5em auto auto;
    //width: 80%;
    //min-width: 200px;
    border-radius: .2em;
    padding: .2em;
    line-height: 1.2;
    font-size: 1rem;
    //background: var(--table-action-color);
    background: none;
    border: none;
    //background: unset;
    margin: 0;
    
    & img {
        width: 1.5rem;
        height: 1.5rem;
    }
    
    &:hover {
        filter: brightness(.85);
    }

    &[disabled] {
        filter: grayscale(.5);
        cursor: not-allowed;
    }
    
    &:not([disabled]) {
        cursor: pointer;
    }
`
