import {ChartI, XyChartI} from "../../Types";

const inmsgBalls: ChartI<XyChartI> = {
    id: "inmsgBalls",
    queryId: "inmsgBalls",
    lang: {
        ua: 'Балів за коди (2 тиж)'
    },
    type: "xy",
    height: 450,
    parameters: {
        periods: ["Day1", "Day2", "Day3", "Day7", "Day14", "Month1", "Month3", "Month6", "Year1", "Year2", "Year3", "Year5", "Year7"],
        selectedPeriod: "Day14",
        selectedInterval: "Day1",
        filters: {
            from: {
                lang: {ua: 'З'},
                key: 'from',
                type: 'datetime'
            },
            to: {
                lang: {ua: 'До'},
                key: 'to',
                type: 'datetime'
            }
        }
    },
    settings: {
        usePie: false,
        pieWhole: false,
        usePieNumberLabel: false,
        usePieNumberPercentage: true,
        pieShortNames: {
            count: 'Балів',
        },
        entries: [
            {
                key: 'count',
                lang: {
                    ua: 'Кількість балів'
                }
            }
        ],
        colors: {
            count: '#148aff',
        },
        glyphs: [],
        barType: "bar",
        lineType: "none",
        gridProps: [true, true],
        tooltipGlyphComponent: "🐋"
    }
}

export default inmsgBalls
