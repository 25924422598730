import {FieldOperations, OpsCategories, TableI} from "../../Types";


let fieldOperations: FieldOperations = {
    dt: [...OpsCategories.range],
    id: [...OpsCategories.eq, ...OpsCategories.range, ...OpsCategories.in,],
    enabled: [...OpsCategories.eq],
};

export const ViberMapTable: TableI = {
    backend: "/287/",
    title: {
        ua: "Розкладка Viber",
    },
    pk: 'id',
    forAdmin: true,
    defaultOrderedBy: "id",
    defaultOrderedWay: "DESC",
    defaultPerPage: 20,
    orderByFields: {
        "id": {ua: "ID", en: "№"},
        "dt": {ua: "Створено", ru: "Створено"},
    },
    fieldOperations: fieldOperations,
    action: 'act287createViberSpam',
    defaultFilters: 'viberMap287DefFilters',
    fields: [
        {
            key: "n",
            inline: true,
            title: {ua: "#"},
            type: "number",
            applyTransform: true
        },
        {
            key: "request",
            type: 'string',
            detailed: true, inline: true,
            editable: true,
            title: {ua: "Назва",}
        },
        {
            key: "dt",
            title: {ua: "Створено"},
            type: "datetime",
            datetimeFormat: "DD MMMM YYYY HH:mm",
            applyTransform: true,
            inline: true,
            detailed: true
        },
        {
            key: "response",
            type: 'json',
            applyTransform: true,
            detailed: true, inline: false,
            editable: true,
            title: {ua: "Відповідь",}
        },
        {
            key: "response_menu",
            type: 'json',
            applyTransform: true,
            detailed: true, inline: false,
            editable: true,
            title: {ua: "Меню відповіді",}
        },
        {
            key: "response_pos",
            type: 'string',
            detailed: true, inline: false,
            title: {ua: "Поз. відповіді",}
        },
        {
            key: "enabled",
            type: 'boolean',
            applyTransform: true,
            detailed: true, inline: true,
            editable: true,
            title: {ua: "Активно",}
        },

        {
            key: "id",
            detailed: true,
            title: {ua: "ID", ru: "№"},
            type: "number",
            applyTransform: true
        },
    ]
}

export function viberMap287DefFilters() {
    return []
}
