import Icon from "../misc/Icon";
import {faEdit, faUpDownLeftRight} from "@fortawesome/free-solid-svg-icons";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {setDashboardShifting, toggleDashboardPaneEditing} from "../../features/layoutSlice";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";


export const DASHBOARD_CONTROLS_HEIGHT = 32

export default function DashboardControls() {

    const dispatch = useAppDispatch()
    const isShifting = useAppSelector(state => (state.layout.isDashboardShifting))

    return <div className={'dashboard-controls-container'} style={{height: DASHBOARD_CONTROLS_HEIGHT + 'px'}}>
        <button className="cntrl" onClick={() => dispatch(setDashboardShifting(true))} disabled={isShifting}>
            <Icon src={"/win-icons/Mail stamp (move).ico"}
                  title={'Додати фільтр'} alt={'Додати фільтр'}
                  width={'1rem'}
                  height={'1rem'}
                  component={faUpDownLeftRight}
            />
        </button>
        <button className="cntrl" onClick={() => dispatch(toggleDashboardPaneEditing())}>
            <FontAwesomeIcon title={'line-chart'} icon={faEdit}
                             style={{width: '1rem', height: '1rem'}}
                             className={'fa-color'}/>
        </button>
    </div>
}
