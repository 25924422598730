import {ChartI, XyChartI} from "../../Types";
import {GREEN_COLOR, RED_COLOR} from "../../components/CONSTANTS";

const inmsgDayActivity: ChartI<XyChartI> = {
    id: "inmsgDayActivity",
    queryId: "inmsgDayActivity",
    lang: {
        ua: 'Добова активність реєстрацій (2 тиж)'
    },
    type: "xy",
    height: 450,
    parameters: {
        periods: ["Day1", "Day2", "Day3", "Day7", "Day14", "Month1", "Month3"],
        selectedPeriod: "Day14",
        selectedInterval: "Day1",
        filters: {
            from: {
                lang: {ua: 'З'},
                key: 'from',
                type: 'datetime'
            },
            to: {
                lang: {ua: 'До'},
                key: 'to',
                type: 'datetime'
            }
        }
    },
    settings: {
        usePie: false,
        pieWhole: false,
        usePieNumberLabel: false,
        usePieNumberPercentage: true,
        pieShortNames: {
            night: 'Ніч',
            morning: 'Ранок',
            day: 'День',
            evening: 'Вечір',
        },
        entries: [
            {
                key: 'night',
                lang: {
                    ua: 'Ніч 00-06'
                }
            },
            {
                key: 'morning',
                lang: {
                    ua: 'Ранок 06-12'
                }
            },
            {
                key: 'day',
                lang: {
                    ua: 'День 12-18'
                }
            }, {
                key: 'evening',
                lang: {
                    ua: 'Вечір 18-24'
                }
            },
        ],
        colors: {
            night: '#0a43a1',
            morning: '#cabc1c',
            day: GREEN_COLOR,
            evening: RED_COLOR,
        },
        glyphs: [],
        renderHorizontally: true,
        barType: "barstack",
        lineType: "none",
        stackOffset: 'expand',
        showHorizontalCrosshair: true,
        showVerticalCrosshair: false,
        gridProps: [true, false],
        yAxisOrientation: 'left',
        tooltipGlyphComponent: "🐋"
    }
}

export default inmsgDayActivity
