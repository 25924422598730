import styled from "styled-components";
import {faEnvelopeOpen} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function EmptyPane({slots, label, icon = faEnvelopeOpen}: {
    slots?: number,
    label?: string,
    icon?: IconDefinition
}) {

    return <EmptyContainer>
        <FontAwesomeIcon title={'line-chart'} icon={icon}
                         style={{width: '1.2rem', height: '1.2rem'}}
                         className={'fa-color'}/>
        <div>{label || 'Порожня комірка'}</div>
        <div>Слотів - {slots || 1}</div>
    </EmptyContainer>
}

export const PaneContainer = styled.div<{ $hide?: boolean }>`
    color: var(--table-row-text-color);
    height: 100%;
    visibility: ${props => props.$hide ? 'hidden' : 'visible'};
`

const EmptyContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    gap: .5rem;
    border-radius: .5rem;
    padding: .5rem;
    background-color: var(--table-row-odd-bg-color);
    height: 100%;
    width: 100%;
    min-height: 250px;
`
