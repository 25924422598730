import {useMemo} from "react";
import {schemas} from "../../config";
import {PjTa} from "../../Types";


export default function useTableFiltersSchema({tableName, projectName}: PjTa) {
    return useMemo(() => {
        let filtersSchema = schemas[projectName].filterSchemas[tableName];
        const allFields = Object.keys(filtersSchema).map((field) => ({
            label: filtersSchema[field].title.ua,
            value: field
        }));
        return {
            allFields,
            filtersSchema
        }
    }, [projectName, tableName])
}
