import React, {useEffect} from "react";
import './header.css'
import AsideProjectInfo from "./AsideProjectInfo";
import {useAppDispatch} from "../../hooks";
import {setSideHeaderReady} from "../../features/layoutSlice";
import {ASIDE_HEADER_WIDTH, ASIDE_HEADER_WIDTH_MOBILE} from "../CONSTANTS";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import Icon from "../misc/Icon";


export default function AsideHeader({projectName, mobile = false, closeMenu}: {
    projectName: string,
    mobile?: boolean,
    closeMenu?: () => void
}) {

    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(setSideHeaderReady(true))

        return () => {
            dispatch(setSideHeaderReady(false))
        }
    }, [dispatch]);

    return <aside className={'aside-header'} onClick={(e) => e.stopPropagation()}
                  style={{width: mobile ? ASIDE_HEADER_WIDTH_MOBILE : ASIDE_HEADER_WIDTH + 'px'}}>
        <AsideProjectInfo projectName={projectName}/>
        <div id="side-head-content"/>
        {closeMenu && <button className="close-btn" onClick={closeMenu}>
            <span>Закрити</span>
            <Icon src={"/win-icons/Cross.ico"}
                  title={'Вийти'} alt={'Вийти'}
                  width={'1.5rem'}
                  height={'1.5rem'}
                  component={faXmark}/>
        </button>}
    </aside>
}
