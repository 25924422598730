import {ChartI, XyChartI} from "../../Types";
import {GREEN_COLOR, RED_COLOR} from "../../components/CONSTANTS";

const inmsgPerUser: ChartI<XyChartI> = {
    id: "inmsgPerUser",
    queryId: "inmsgPerUser",
    lang: {
        ua: 'Успішних реєстрацій на користувача'
    },
    type: "xy",
    height: 450,
    parameters: {
        periods: ["Day1", "Day2", "Day3", "Day7", "Day14", "Month1", "Month3"],
        selectedPeriod: "Day7",
        selectedInterval: "Day1",
        filters: {
            from: {
                lang: {ua: 'З'},
                key: 'from',
                type: 'datetime'
            },
            to: {
                lang: {ua: 'До'},
                key: 'to',
                type: 'datetime'
            }
        }
    },
    settings: {
        usePie: false,
        pieWhole: false,
        usePieNumberLabel: false,
        usePieNumberPercentage: true,
        pieShortNames: {
            one: '1',
            two: '2,3',
            four: '4,5',
            five: '>5',
        },
        entries: [
            {
                key: 'one',
                lang: {
                    ua: '1'
                }
            }, {
                key: 'two',
                lang: {
                    ua: '2,3'
                }
            },
            {
                key: 'four',
                lang: {
                    ua: '4,5'
                }
            },
            {
                key: 'five',
                lang: {
                    ua: '>5'
                }
            },
        ],
        colors: {
            one: GREEN_COLOR,
            two: RED_COLOR,
            four: '#8018c6',
            five: '#2e40fb',
        },
        glyphs: [
            {
                key: 'one',
                value: '1️⃣'
            },
            {
                key: 'two',
                value: '2️⃣'
            },
            {
                key: 'four',
                value: '4️⃣'
            },
            {
                key: 'five',
                value: '6️⃣'
            },
        ],
        barType: "none",
        lineType: "area",
        curveShape: 'cardinal',
        xAxisOrientation: 'top',
        tooltipGlyphComponent: "🐋"
    }
}

export default inmsgPerUser
