import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {useAppSelector} from "../../hooks";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


export default function Icon({title = '', src, alt = '', component, className = '', width, height}: {
    title?: string,
    src?: string,
    alt?: string,
    component?: IconProp,
    className?: string,
    width?: string,
    height?: string
}) {

    let iconsStyle = useAppSelector(state => state.project.iconsStyle);

    if (iconsStyle === 'win95' && src !== undefined) {
        return <img
            className={className}
            title={title}
            style={{width: width, height: height}}
            src={src}
            alt={alt}/>
    }

    if (iconsStyle === 'fontAwesome' && component !== undefined) {
        return <FontAwesomeIcon title={title} icon={component} style={{width: width, height: height}}
                                className={'fa-color ' + className}/>
    }

    return <>
        No icon
    </>
}
