import {countI, Query, tableInfoI, TableSliceInitialStateI} from "./tableSlice";
import {FieldMap, PrimitiveObject, SchemaI} from "../Types";
import {FilterSchema, MapFilterSchema} from "../287/tables/UsersTable";
import {functions} from "../config";

export const createInitialStateForTables = (schema: SchemaI): TableSliceInitialStateI => {
    const pages = {} as { [key: string]: { [key: string]: PrimitiveObject[] } }
    const tableInfo = {} as { [key: string]: tableInfoI }
    const queries = {} as { [key: string]: Query }
    const names = {} as { [key: string]: { [key: string]: string } }
    const count = {} as { [key: string]: countI }

    let lht = localStorage.getItem(`${schema.actName}-hidden-tables`);
    let hiddenTables = [] as string[];
    if (lht) {
        hiddenTables = JSON.parse(lht)
    }

    Object.entries(schema.tables).forEach(([tableName, table]) => {
        pages[tableName] = {}
        tableInfo[tableName] = {
            current_page: 1,
            prev_page: 1,
            max_pages: 0,
            name: {...table.title},
            forAdmin: !!table.forAdmin,
            hidden: hiddenTables.includes(tableName) || (lht === null && !!table.hidden),
            loading: "idle",
            frozen: {},
            asyncMaps: {
                loading: 'idle',
                data: []
            }
        }
        let keys = table.fields.filter(x => !!x.map?.async).map(x => ({key: x.key, queryKey: x.map?.async!}));
        tableInfo[tableName].asyncMaps = {
            loading: keys.length === 0 ? 'loaded' : 'idle',
            data: keys
        }

        names[tableName] = {...table.title}
        count[tableName] = {
            total: undefined,
            inCondition: undefined
        }
        queries[tableName] = {
            prev: {
                limit: table.defaultPerPage,
                orderBy: table.defaultOrderedBy,
                orderWay: table.defaultOrderedWay,
                filters: table.defaultFilters ? functions[table.defaultFilters]() : []
            }, current: {
                limit: table.defaultPerPage,
                orderBy: table.defaultOrderedBy,
                orderWay: table.defaultOrderedWay,
                filters: table.defaultFilters ? functions[table.defaultFilters]() : []
            },
        }
        // if (process.env.NODE_ENV !== 'development')
        //     table.defaultFilters = undefined
    })


    return {
        pages: {...pages},
        queries: {...queries} as { [key: string]: Query },
        activeTable: schema.defaultTable,
        tableInfo: {...tableInfo} as { [key: string]: tableInfoI },
        names: {...names},
        count: {...count}
    } as any;
}


export function createSchema(schema: SchemaI) {

    const filtersSchema = {} as { [p: string]: { [p: string]: FilterSchema } }

    Object.entries(schema.tables).forEach(([tableName, table]) => {
        filtersSchema[tableName] = {}
        if (table.fieldOperations)
            Object.entries(table.fieldOperations).forEach(([field, ops]) => {
                let x = schema.tables[tableName].fields.find(x => x.key === field);
                if (!x)
                    return null
                filtersSchema[tableName][field] = {
                    title: x.title,
                    type: x.type,
                    operations: ops,
                    datetimeFormat: x.datetimeFormat,
                    mapSchema: (x.map && !x.map.async) ? createMapFilterSchema(x.map) : undefined
                } as FilterSchema
            })
        if (process.env.NODE_ENV !== 'development')
            table.fieldOperations = undefined
    })

    schema.filterSchemas = filtersSchema

    return schema
}

export function createMapFilterSchema(map: FieldMap): MapFilterSchema {

    const groupMap: Record<string, number> = {};

    const options = Object.entries(map.values).map(([key, e], i) => {
        groupMap[key] = i;
        return {
            label: e.lang,
            value: e.rawValue,
            color: e.color,
        };
    });

    const groupOptions = map.groups?.map((g: any) => ({
        ...g,
        options: g.values.map((gName: string) => options[groupMap[gName]]),
    }));

    return {
        multiValue: map.multiValue,
        groups: groupOptions,
        options: options,
        isColored: !!options[0]?.color,
    } as MapFilterSchema;
}




