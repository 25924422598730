import {PaneI} from "../graphs/GraphLayout";
import GraphAsidePaneInfo from "../header/GraphAsidePaneInfo";
import './charts.css'
import DashboardControls, {DASHBOARD_CONTROLS_HEIGHT} from "./DashboardControls";
import {useAppSelector} from "../../hooks";
import PaneReordering from "../graphs/PaneReordering";
import {useEffect, useState} from "react";


interface AsideDashI {
    panes: PaneI[]
}

export default function AsideDashboardFunc({panes}: AsideDashI) {

    const isShifting = useAppSelector(state => (state.layout.isDashboardShifting))

    const [opacity, setOpacity] = useState(0);

    useEffect(() => {
        setOpacity(1);
    }, []);

    return <>
        <div className={'aside-dashboard-container'}
             style={{opacity: opacity, maxHeight: `calc(100% - ${DASHBOARD_CONTROLS_HEIGHT}px - 1rem)`}}>
            {!isShifting && panes.map((p, i) => <GraphAsidePaneInfo key={i} id={p.id} type={p.type}/>)}
            {isShifting && <PaneReordering panes={panes}/>}
        </div>
        <DashboardControls/>
    </>
}
