import {useAppDispatch, useAppSelector} from "../../hooks";
import {TIME_PERIODS} from "../CONSTANTS";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useRef} from "react";
import {faArrowRightLong, faCalendar} from "@fortawesome/free-solid-svg-icons";
import {setChartParameterToPeriod} from "../../features/chartsSlice";


export default function ChartPeriod({chartId, showPicker}: { chartId: string, showPicker?: boolean }) {
    const filters = useAppSelector(state => (state.charts.parameters[chartId]).filters!)
    const selectedInterval = useAppSelector(state => (state.charts.parameters[chartId]).selectedInterval)
    const selectedPeriod = useAppSelector(state => (state.charts.parameters[chartId]).selectedPeriod)

    const dispatch = useAppDispatch()

    const to = moment(filters.to.value)
    const from = moment(filters.from.value)
    const INTERVAL = TIME_PERIODS[selectedInterval!]
    // if (timeInterval.date)
    //     from = from.startOf('day').add(1, 'day')

    const format = determineDateFormat(selectedPeriod!, selectedInterval!)

    const dateRef = useRef<HTMLInputElement | null>(null);

    function openPicker() {
        dateRef.current?.showPicker()
    }

    function onDateChange(e: React.ChangeEvent<HTMLInputElement>) {
        dispatch(setChartParameterToPeriod({
            chartId: chartId,
            value: e.target.value
        }))
    }

    return <div className={'chart-period-container'}>
        <div className={'chart-period'}>
            {selectedPeriod !== 'AllTime' && <>
                <div className={'val'}>
                    {from.format(format)}
                </div>
                <FontAwesomeIcon title={'arrow-right'} icon={faArrowRightLong} style={{width: '24px'}}
                                 className={'fa-color-text '}/>
                <div className={'val'}>
                    {to.format(format)}
                </div>
            </>}
            {selectedPeriod === 'AllTime' && <div className="val">
                За весь час
            </div>}
        </div>
        {showPicker && INTERVAL.date && <div className={'date-picker'}>
            <button className={'date-btn'} onClick={openPicker}>
                <FontAwesomeIcon title={'calendar'} icon={faCalendar} style={{width: '1rem', height: '1rem'}}
                                 className={'fa-color-text'}/>
            </button>
            <input type="date" ref={dateRef} onChange={onDateChange}/>
        </div>}
    </div>
}

export const determineDateFormat = (
    selectedPeriod: keyof typeof TIME_PERIODS,
    selectedInterval: keyof typeof TIME_PERIODS
): string => {
    const period = TIME_PERIODS[selectedPeriod];
    const interval = TIME_PERIODS[selectedInterval];

    if (!period || !interval) {
        throw new Error(`Invalid period or interval: ${selectedPeriod}, ${selectedInterval}`);
    }

    const isIntervalDateBased = interval.date;
    const isPeriodDateBased = period.date;

    if (selectedPeriod === 'Day7' && selectedInterval === 'Day1')
        return 'dd DD.MM | HH.mm'

    if (true)
        return 'DD.MM.YYYY | HH.mm'; // Day, month, and year for daily intervals
    // General format logic based on interval and period types
    if (!isIntervalDateBased) {
        return 'DD.MM HH:mm'; // Time-based format for intervals without dates
    }

    if (isIntervalDateBased && !isPeriodDateBased) {
        return 'dd HH:mm'; // Day of week and time for mixed types
    }

    if (selectedInterval === 'Day1') {
        return 'DD.MM.YYYY'; // Day, month, and year for daily intervals
    }

    if (selectedInterval.includes('Month')) {
        return 'MMM YYYY'; // Month and year for monthly intervals
    }

    if (selectedInterval.includes('Year')) {
        return 'YYYY'; // Year only for yearly intervals
    }

    if (selectedInterval.includes('Day')) {
        return 'dd MM.DD'; // Day of week, month, and day
    }

    // Default fallback format
    return 'DD.MM.YYYY';
};
