import styled from "styled-components";
import React from "react";
import TablePerPage from "./TablePerPage";
import PaginateTable from "./PaginateTable";

function TableBottomPagination({maxPages, currentPage, cols, inConditionRecords, tableName}: {
    maxPages: number,
    currentPage: number,
    cols: number,
    inConditionRecords: number | undefined,
    tableName: string
}) {

    return (
        <td colSpan={cols}>
            <Wrapper>
                <PaginateTable maxPages={maxPages} currentPage={currentPage}/>
                <div className={'per-page'}>
                    <TablePerPage tableName={tableName}/>
                </div>
                <div className={'total'}>Усього записів - {inConditionRecords}</div>
            </Wrapper>
        </td>
    )
        ;
}

export default TableBottomPagination;

const Wrapper = styled.div`
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    

    & .total {
        display: flex;
        align-items: center;
        margin-right: .5rem;
        margin-left: auto;
    }
    
    & .per-page {
        display: flex;
        align-items: center;
    }

    & .pages {
        display: inline-flex;
        align-items: center;
        gap: .2rem;
        margin-left: .5rem;
        & img {
            width: 32px;
            height: 32px;
        }
    }
`

const PagesList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;

    display: inline-flex;
    align-items: center;
    gap: 0;
    


    & > li {
        margin: 0;
        //border-right: 2px solid var(--table-border-color);
        //border-bottom: 2px solid var(--table-border-color);
        //border-top: 2px solid var(--table-border-color);

        & button {

        }
    }


    & li:first-of-type {
        //border-left: 2px solid var(--table-border-color);
        //border-top-left-radius: 1rem;
        //border-bottom-left-radius: 1rem;
        overflow: hidden;
        
    }

    & li:last-of-type {
        overflow: hidden;
        
        //border-top-right-radius: 1rem;
        //border-bottom-right-radius: 1rem;

    }
`

const Btn = styled.button<any>`
    cursor: pointer;
    padding: .4rem;
    border: none;
    background: var(--table-row-even-bg-color);
    background: transparent;
    font-weight: ${props => props.$active ? 'bold' : 'normal'};
    color: ${props => props.$active ? 'var(--table-action-color)' : 'var(--table-row-text-color)'};
    width: 3em;
    
    &:hover {
        filter: brightness(.85);
    }
`
