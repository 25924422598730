import React, {useState} from 'react';
import {FieldType, OptionI} from '../../Types';
import styled from "styled-components";
import {MultiValue} from "react-select";
import CreatableSelect from 'react-select/creatable';
import {FilterSchema} from "../../287/tables/UsersTable";
import {useAppDispatch} from "../../hooks";
import {setFilterValue} from "../../features/tableSlice";

const lang = 'ua'

function CreateSelectFilter({index, schema, type, textCenter = false}: {
    index: number,
    schema: FilterSchema,
    type: FieldType,
    textCenter?: boolean
}) {

    const dispatch = useAppDispatch();
    let [newValue, setNewValue] = useState<OptionI[]>([]);


    function onChange(newValue: MultiValue<OptionI>) {
        const options = newValue.filter(x => type === "number" ? !isNaN(parseFloat(x.value)) : true)
        setNewValue(options)
        const value = options.map(x => type === "number" ? parseFloat(x.value) : x.value) as (string | number)[]
        dispatch(setFilterValue({index, value: value}));
    }

    return <Container>
        <CreatableSelect
            className={'select-map'}
            value={newValue}
            isMulti
            isClearable
            onChange={(newValue) => onChange(newValue)}
        />
    </Container>
}

export default CreateSelectFilter;

const Container = styled.div`

    .select-map {
        border-radius: 0;
        color: black;
    }

    & .wrapper {
        display: flex;
        gap: .5rem;
    }


`
