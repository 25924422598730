import styled from "styled-components";
import React, {useEffect, useState} from "react";
import moment from "moment";
import {schemas} from "../config";
import {toast} from "react-toastify";
import Loader from "../components/Loader";
import {LoaderContainer} from "../components/table/Table";
import Icon from "../components/misc/Icon";
import {faArrowsRotate} from "@fortawesome/free-solid-svg-icons";


const localeMap = {
    msisdn: {
        ua: 'Користувачів'
    },
    msisdn_new: {
        ua: 'Нових користувачів'
    },
    codes: {
        ua: 'Всього кодів в акції (за весь час)'
    },
    codes_used: {
        ua: 'Використано кодів (за весь час)'
    },
    inmsg: {
        ua: 'Отримано повідомлень'
    },
    inmsg_correct: {
        ua: 'Коректних повідомлень'
    },

}
const lang = 'ua'

function Act287SummaryPage() {

    let [value, setValue] = useState({
        from: moment().subtract(2, 'days').locale('en').format('yyyy-MM-DDTHH:mm'),
        to: moment().locale('en').format('yyyy-MM-DDTHH:mm'),
        loading: 'idle',
        data: null
    });
    //todo change def

    function onChange(name: string, val: string) {
        setValue(prevState => ({...prevState, [name]: val}))
    }

    function fetchSummary(from: string, to: string) {
        setValue(prevState => ({...prevState, loading: 'loading'}))
        fetch(schemas['act287'].backend + '/287/getSummary.php?query=' + encodeURIComponent(JSON.stringify({
            from: from,
            to: to
        })), {credentials: 'include'})
            .then(r => r.json())
            .catch(e => toast.error(e.toString()))
            .then(r => setValue(prevState => ({...prevState, data: r.data, loading: 'loaded'})))
    }

    useEffect(() => {
        if (value.loading === 'idle')
            fetchSummary(value.from, value.to)
    }, [value.from, value.loading, value.to]);

    return <Container>
        <div className={'inputs'}>
            <div className={'input'}>
                <label htmlFor="from">Період з: </label>
                <input type='datetime-local'
                       id='from'
                       name={'from'}
                       className="edit-input-datetime"
                       value={value.from}
                       onChange={(e) => onChange('from', e.currentTarget.value)}/>
            </div>
            <div className={'input'}>
                <label htmlFor="to">По: </label>
                <input type='datetime-local'
                       id='to'
                       name={'to'}
                       className="edit-input-datetime"
                       value={value.to}
                       onChange={(e) => onChange('to', e.currentTarget.value)}/>
            </div>
            <button className={'show'} onClick={() => fetchSummary(value.from, value.to)}>
                <span>Оновити</span>
                <Icon src={"/win-icons/Mail stamp (move).ico"}
                      title={'Додати фільтр'} alt={'Додати фільтр'}
                      width={'1.2rem'}
                      height={'1.2rem'}
                      component={faArrowsRotate}
                />
            </button>
        </div>
        <table>
            <thead>
            <tr style={{background: 'var(--table-header-bg-color)'}}>
                <th align={"center"} colSpan={2}>Статистика</th>
            </tr>
            </thead>
            <tbody>
            {value.data && Object.entries(localeMap).map(([key, val]) => {
                return <tr key={key}>
                    <td>{val[lang]}</td>
                    <td>{value.data![key]}</td>
                </tr>
            })}
            </tbody>
        </table>
        {value.loading === 'loading' && <LoaderContainer>
            <Loader/>
        </LoaderContainer>}
    </Container>
}

export default Act287SummaryPage


const Container = styled.section`

    color: var(--table-row-text-color);
    font-size: 1rem;
    margin-top: .5rem;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    
    & .show {
        background: var(--table-row-odd-bg-color);
        padding: .2rem;
        color: var(--table-row-text-color);
        cursor: pointer;
        font-size: 1rem;
        font-weight: bold;
        border-radius: .2rem;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: .4rem;
        
        &:hover {
            filter: brightness(.85);
        }
    }


    & .inputs {
        display: flex;
        flex-wrap: wrap;
        gap: .5rem;
        align-items: center;

        & .input {
            display: flex;
            align-items: stretch;

            & label {
                background-color: var(--table-row-odd-bg-color);
                padding: .2rem;
                border-radius: .2rem 0 0 .2rem;
            }
        }
    }

    table {
        margin-top: .5rem;
        width: 100%;
        max-width: 575px;
        border-collapse: separate;
        text-align: left;
        color: var(--table-row-text-color);
        border: none;

        thead {
            background-color: var(--table-header-bg-color);
            color: var(--table-header-text-color);
            font-weight: bold;
        }

        th, td {
            padding: 0.3rem;
            border: none;
        }

        tbody {
            tr:nth-child(even) {
                background-color: var(--table-row-even-bg-color);
            }

            tr:nth-child(odd) {
                background-color: var(--table-row-odd-bg-color);
            }
        }
    }

`
