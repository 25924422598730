import React, {useEffect, useState} from 'react';
import {API_BACK_URL, GREEN_COLOR, RED_COLOR} from "../../components/CONSTANTS";
import styled from "styled-components";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {setGenericStateFeature, updateGenericState} from "../../features/genericDataSlice";
import {handleFetchResponseStatus} from "../../components/util";
import {toast} from "react-toastify";
import {faMinusSquare, faPlusSquare} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import hljs from "highlight.js/lib/core";


const feature = 'act287sendViberSpam'

const STATIC_PHONES: PhoneI[] = [
    {
        phone: '380660263070',
        isSuccess: false,
        isError: false
    }, {
        phone: '380504482332',
        isSuccess: false,
        isError: false
    }, {
        phone: '380633151542',
        isSuccess: false,
        isError: false
    },
]

const SendViberSpam: React.FC = () => {
    const isStreaming = useAppSelector(state => state.genericData[feature].isStreaming)
    const isFinished = useAppSelector(state => state.genericData[feature].isFinished)
    const isBlocked = useAppSelector(state => state.genericData[feature].isBlocked)
    const error = useAppSelector(state => state.genericData[feature].error)
    const errorJSON = useAppSelector(state => state.genericData[feature].errorJSON)
    const selectedPhoneList = useAppSelector(state => state.genericData[feature].selectedPhoneList as PhoneI[])
    const spamList = useAppSelector(state => state.genericData[feature].spamList as SpamI[])
    const selectedSpam = useAppSelector(state => state.genericData[feature].selectedSpam as SpamI | undefined)
    const dispatch = useAppDispatch()

    function reset() {
        dispatch(setGenericStateFeature({
            feature: feature,
            value: {
                isStreaming: undefined,
            }
        }))
    }

    useEffect(() => {
        if (isStreaming === undefined) {
            const initial = {
                isStreaming: false,
                isBlocked: false,
                isFinished: false,
                error: null,
                selectedPhoneList: [],
                spamList: [],
                selectedSpam: undefined,
                errorJSON: undefined
            };
            fetch(
                `${API_BACK_URL}/runQuery.php?project=act287&query_key=getSpamList`,
                {credentials: 'include'})
                .then(handleFetchResponseStatus)
                .then(r => r.json())
                .then(r => {
                    if (r.status === 'success') {
                        let spams: SpamI[] = r.data.map((r: any) => {
                            let o = {} as any

                            try {
                                o = JSON.parse(r.response.replace(/:\s*"((?:[^"\\]|\\.)*?)"/g, (match: any, value: string) => {
                                    const fixedValue = value.replace(/\n/g, '\\n'); // Escape unescaped newlines inside values
                                    return `: "${fixedValue}"`;
                                }))
                            } catch (e) {
                                console.log('failed to parse json for spam', r.request)
                                console.log(r.response)
                                return {
                                    id: r.id,
                                    name: r.request,
                                    image: '',
                                    text: ''
                                }
                            }
                            let list = o.list
                            const imageFind = list.find((x: {
                                json: { type: string, media: string };
                            }) => x.json.type === 'picture');
                            const textFind = list.find((x: {
                                json: { type: string, media: string };
                            }) => x.json.type === 'text');
                            return {
                                id: r.id,
                                name: r.request,
                                image: imageFind ? imageFind.json.media : '',
                                text: textFind ? textFind.json.text : ''
                            }
                        })
                        dispatch(setGenericStateFeature({
                            feature: feature,
                            value: {
                                ...initial,
                                spamList: spams,
                                selectedSpam: spams.length > 0 ? spams[0] : undefined
                            }
                        }))
                    } else {
                        dispatch(setGenericStateFeature({
                            feature: feature,
                            value: {
                                ...initial,
                                error: r.message || 'Сталась помилка'
                            }
                        }))
                        toast.error(r.message || 'Сталась помилка')
                    }
                })
        }

    }, [dispatch, isStreaming]);


    async function fetchAllNumbers() {
        dispatch(updateGenericState({
            feature,
            property: 'isBlocked',
            value: true
        }))
        const res = await fetch(
            `${API_BACK_URL}/runQuery.php?project=act287&query_key=allViberNumbers`,
            {credentials: 'include'})
            .then(handleFetchResponseStatus)
            .then(r => r.json())

        if (res.status === 'success') {
            dispatch(updateGenericState({
                feature,
                property: 'selectedPhoneList',
                value: res.data.map((p: any) => ({phone: p.msisdn, isError: false, isSuccess: false}))
            }))
            dispatch(updateGenericState({
                feature,
                property: 'isBlocked',
                value: false
            }))
        } else {
            toast.error(res.message || 'Сталась помилка')
        }
    }

    //
    // dispatch(updateGenericState({
    //     feature,
    //     property: 'error',
    //     value: r.message || 'Сталась помилка'
    // }))
    const startStream = async () => {
        dispatch(updateGenericState({
            feature,
            property: 'isStreaming',
            value: true
        }))

        try {
            const response = await fetch(API_BACK_URL + '/287/sendViberSpam.php', {
                method: 'POST',
                credentials: 'include',
                body: JSON.stringify({
                    phones: selectedPhoneList.map(x => x.phone),
                    spam_name: selectedSpam?.name
                })
            });
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const reader = response.body?.getReader();
            if (!reader) {
                throw new Error('Readable stream is not supported');
            }

            const decoder = new TextDecoder();

            while (true) {
                const {done, value} = await reader.read();
                if (done) {
                    dispatch(updateGenericState({
                        feature,
                        property: 'isFinished',
                        value: true
                    }))
                    toast.success('Розсилку завершено!')
                    break;
                }
                // Decode and add to buffer
                const data = JSON.parse(decoder.decode(value));
                let isError = data.error && data.error === true
                dispatch(updateGenericState({
                    feature,
                    property: 'selectedPhoneList.' + data.index,
                    value: {
                        phone: data.phone,
                        isError: isError,
                        isSuccess: !isError
                    }
                }))
                if (isError) {
                    dispatch(updateGenericState({
                        feature,
                        property: 'errorJSON',
                        value: data.errorBody
                    }))
                    dispatch(updateGenericState({
                        feature,
                        property: 'isFinished',
                        value: true
                    }))
                    toast.error('Сталась помилка при розсилці')
                    break;
                }
            }

            // setIsStreaming(false);
        } catch (err: any) {
            console.dir(err)
            if (err instanceof SyntaxError) {
                console.error("JSON parse error:", err.message);
                dispatch(updateGenericState({
                    feature,
                    property: 'error',
                    value: 'Помилка парсингу. Виконання розсилки продовжується. Перевірити виконання можна звіривши кількість нових записів у таблиці.'
                }))
                // Handle JSON parsing error specifically
            } else {
                console.error("Unexpected error:", err.message);
                dispatch(updateGenericState({
                    feature,
                    property: 'error',
                    value: err.message
                }))
            }
            dispatch(updateGenericState({
                feature,
                property: 'isFinished',
                value: true
            }))

            // setIsStreaming(false);
        }
    };

    function setSelectedSpam(spam: SpamI) {
        dispatch(updateGenericState({
            feature,
            property: 'selectedSpam',
            value: spam
        }))
    }

    function addNumberToSelected(phone: PhoneI) {
        dispatch(updateGenericState({
            feature,
            property: 'selectedPhoneList',
            value: [...selectedPhoneList, phone]
        }))
    }

    function removeNumberFromSelected(index: number) {
        dispatch(updateGenericState({
            feature,
            property: 'selectedPhoneList',
            value: selectedPhoneList.filter((_, i) => i !== index)
        }))
    }

    function clearNumbers() {
        dispatch(updateGenericState({
            feature,
            property: 'selectedPhoneList',
            value: []
        }))
    }

    const disableAll = isStreaming || isBlocked


    if (isStreaming === undefined)
        return <Container>
            <section className={'send-viber-spam'}>
                <div className="menu">
                </div>
            </section>
        </Container>

    return (
        <Container>
            <h2>Розсилка спаму</h2>
            {error && <div className="error">
                {error}
            </div>}
            {errorJSON && <pre style={{width: '100%', height: '300px', maxWidth: '1000px'}}><code
                style={{overflow: 'auto', height: '300px'}}
                dangerouslySetInnerHTML={{__html: hljs.highlight(JSON.stringify(errorJSON, null ,2), {language: 'json'}).value}}
                className={'language-json hljs'}></code></pre>}
            <section className={'send-viber-spam'}>
                <div className="menu">
                    {selectedSpam && <select name="spam" id="1">
                        {spamList.map((s, i) =>
                            <option value={s.name} key={i} onClick={() => setSelectedSpam(s)}>
                                {s.name}
                            </option>)}
                    </select>}
                    {selectedSpam && <>
                        <div className="image">
                            <img src={selectedSpam.image} alt="image"/>
                        </div>
                        <textarea className={'spam-text'} name="spamtext" id="spamtext" rows={20}
                                  value={selectedSpam.text} disabled/>
                    </>}

                </div>
                <div className="phones">
                    <div className="add-phone">
                        <ul className="static">
                            {STATIC_PHONES.map((p, i) => <li key={i}>
                                <PhoneEntry phone={p} isSelected={false} index={i} blocked={disableAll}
                                            removeNumberFromSelected={removeNumberFromSelected}
                                            addNumberToSelected={addNumberToSelected}/>
                            </li>)}
                        </ul>
                        {/*<div className="add-new-phone">*/}
                        {/*    <input className={'add-phone-input'} type="tel" placeholder={'Додати новий номер'}/>*/}
                        {/*    <button className="add-new-btn">*/}
                        {/*        <FontAwesomeIcon title={'side-menu'} icon={faPlusSquare}*/}
                        {/*                         style={{width: '1rem', height: '1rem'}}*/}
                        {/*                         className={'fa-color'}/>*/}
                        {/*    </button>*/}
                        {/*</div>*/}
                        <button onClick={fetchAllNumbers} disabled={disableAll}
                                className="fetch-all action-btn">Завантажити всі номери
                        </button>
                        <button onClick={clearNumbers} disabled={disableAll}
                                className="fetch-all action-btn">Очистити всі
                        </button>
                    </div>

                    {!isStreaming && <div className="number">Обрано {selectedPhoneList.length} номерів</div>}
                    {isStreaming && <div
                        className="number">Надіслано {selectedPhoneList.filter(x => x.isSuccess).length}/{selectedPhoneList.length}</div>}

                    <ul className="selected">
                        {selectedPhoneList.map((p, i) => <li key={i}>
                            <PhoneEntry phone={p} isSelected={true} index={i} blocked={disableAll}
                                        removeNumberFromSelected={removeNumberFromSelected}
                                        addNumberToSelected={addNumberToSelected}/>
                        </li>)}
                    </ul>
                    <button disabled={(disableAll || selectedPhoneList.length === 0) && (!isFinished)}
                            onClick={() => {
                                (!isFinished && error === null) ?
                                    startStream() :
                                    reset()
                            }}
                            className="send-btn action-btn">{isFinished ? 'Скинути на початок' : 'Почати розсилку'}
                    </button>
                </div>
            </section>
        </Container>
    );
};

function PhoneEntry({phone, isSelected, blocked, index, removeNumberFromSelected, addNumberToSelected}: {
    phone: PhoneI,
    isSelected: boolean
    index: number,
    blocked: boolean
    removeNumberFromSelected: (index: number) => void,
    addNumberToSelected: (phone: PhoneI) => void,
}) {

    const state = phone.isSuccess ? 'success' : phone.isError ? 'error' : 'normal'

    function handler() {
        isSelected ? removeNumberFromSelected(index) : addNumberToSelected(phone)
    }

    return (
        <Phone $state={state}>

            <div className={'val'}>{phone.phone}</div>
            {!blocked &&
                <button onClick={handler}>{isSelected ? <FontAwesomeIcon title={'side-menu'} icon={faMinusSquare}

                                                                         style={{width: '1rem', height: '1rem'}}
                                                                         className={'fa-color'}/> :
                    <FontAwesomeIcon title={'side-menu'} icon={faPlusSquare}
                                     style={{width: '1rem', height: '1rem'}}
                                     className={'fa-color'}/>}</button>}
        </Phone>
    )
}

export default SendViberSpam;

const Phone = styled.div<{ $state: 'normal' | 'error' | 'success' }>`
    background: var(--table-row-odd-bg-color);
    ${({$state}) => {
        switch ($state) {
            case "normal":
                return 'background: wheat'
            case "success":
                return `background: ${GREEN_COLOR}`
            case "error":
                return `background: ${RED_COLOR}`
        }
    }};
    color: black;
    padding: .2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 1.8rem;

    & .val {
        font-family: monospace;
        font-size: 1rem;
        font-weight: bold;
    }

    & button {
        background: var(--table-row-odd-bg-color);
        border: none;
        display: flex;
        padding: .2rem;
        border-radius: .2rem;
    }
`

const Container = styled.section`
    margin-top: .5rem;
    margin-left: auto;
    margin-right: auto;
    color: var(--table-row-text-color);
    display: flex;
    flex-flow: column;
    gap: .5rem;
    padding: .5rem;
    background: var(--table-row-odd-bg-color);
    border-radius: .5rem;
    position: relative;
    max-width: 800px;

    & select {
        font-size: 1rem;
    }

    & .spam-text {
        width: 100%;
        text-indent: 0;
        flex-grow: 1;
        resize: vertical;
        color: black;
        border-top: 2px solid var(--table-border-color);
        border-bottom: 2px solid var(--table-border-color);
        background: white;
    }

    & .number {
        text-align: center;
        color: var(--table-row-text-color);
    }

    & .fetch-all {
        margin-top: .5rem;
    }

    & .add-new-phone {
        display: flex;
        justify-content: space-between;
        gap: .4rem;
        padding-right: .2rem;
        padding-left: .2rem;
        background: wheat;
        padding-bottom: .2rem;
        padding: .2rem;
        margin-top: .5rem;

        & .add-phone-input {
            flex-grow: 1;
            text-indent: 0;
            font-family: monospace;
            font-size: 1rem;
        }

        & .add-new-btn {
            background: var(--table-row-odd-bg-color);
            border: none;
            display: flex;
            padding: .2rem;
            border-radius: .2rem;
        }
    }


    & .image {
        width: 100%;
        height: 200px;
        display: flex;
        justify-content: center;

        & img {
            height: 100%;
            width: auto;
        }
    }

    & .error {
        color: ${RED_COLOR};
    }

    & h2 {
        text-align: center;
        font-size: 1.2rem;
    }

    & .send-viber-spam {
        display: flex;
        align-items: stretch;
        flex-wrap: wrap;
        gap: .5rem;

        & .menu {
            display: flex;
            flex-flow: column;
            gap: .5rem;
            flex-basis: 400px;
            width: 100%;
            flex-grow: 1;
            //border-right: 3px solid var(--table-border-color);
            //padding-right: .5rem;
            overflow-y: auto;
            min-height: 700px;
        }

        & .selected {
            display: flex;
            flex-flow: column;
            gap: .5rem;
            background: wheat;
            overflow-y: auto;
            flex-grow: 20;
        }

        & .phones {
            flex-basis: 240px;
            width: 100%;
            flex-grow: 1;
            flex-shrink: 0;

            display: flex;
            flex-flow: column;
            gap: .5rem;
            height: 100%;
            min-height: 700px;
            max-height: 700px;

        }
    }
`

interface SpamI {
    id: number,
    name: string,
    image: string,
    text: string
}

interface PhoneI {
    phone: string,
    isError: boolean,
    isSuccess: boolean
}
