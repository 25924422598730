import {FieldMap, FieldOperations, OpsCategories, SqlOperation, TableI} from "../../Types";
import {nanoid} from "@reduxjs/toolkit";


let fieldOperations: FieldOperations = {
    dt: [...OpsCategories.range],
    id: [...OpsCategories.eq, ...OpsCategories.range, ...OpsCategories.in,],
    order: [...OpsCategories.eq, ...OpsCategories.range, ...OpsCategories.in,],
    version: [...OpsCategories.eq, ...OpsCategories.range, ...OpsCategories.in,],
    visible: [...OpsCategories.eq],
    table_name: [...OpsCategories.eq, ...OpsCategories.in,],
};

export const ExtraFieldsTable: TableI = {
    backend: "/287/",
    title: {
        ua: "Динамічні поля",
    },
    pk: 'id',
    forAdmin: true,
    defaultOrderedBy: "dt",
    defaultOrderedWay: "DESC",
    defaultPerPage: 20,
    orderByFields: {
        "id": {ua: "ID"},
        "dt": {ua: "Створено"},
        "sort_order": {ua: "Порядок"},
    },
    fieldOperations: fieldOperations,
    // action: 'act287createViberSpam',
    defaultFilters: 'extraFields287DefFilters',
    fields: [
        {
            key: "n",
            inline: true,
            title: {ua: "#"},
            type: "number",
            applyTransform: true
        },
        {
            key: "table_name",
            type: "map",
            map: {
                async: 'all_dynamic_tables'
            } as FieldMap,
            detailed: true, inline: true,
            editable: true,
            title: {ua: "Таблиця"}
        },
        {
            key: "field_name",
            type: "string",
            detailed: true, inline: true,
            title: {ua: "Поле"}
        },
        {
            key: "visible",
            type: 'boolean',
            applyTransform: true,
            detailed: true, inline: true,
            editable: true,
            title: {ua: "Активне",}
        },
        {
            key: "sort_order",
            type: 'number',
            applyTransform: true,
            detailed: true, inline: true,
            editable: true,
            title: {ua: "Порядок",}
        },
        {
            key: "dt",
            title: {ua: "Створено"},
            type: "datetime",
            datetimeFormat: "DD MMMM YYYY HH:mm",
            applyTransform: true,
            inline: true,
            detailed: true
        },
        {
            key: "body",
            type: 'json',
            applyTransform: true,
            jsonHeight: 240,
            detailed: true, inline: false,
            editable: true,
            title: {ua: "Сигнатура",}
        },
        {
            key: "version",
            type: 'number',
            applyTransform: true,
            detailed: true,
            title: {ua: "Версія",}
        },
        {
            key: "id",
            detailed: true,
            title: {ua: "ID", ru: "№"},
            type: "number",
            applyTransform: true
        },
    ]
}

export function extraFields287DefFilters() {
    return [
        {
            nanoid: nanoid(),
            field: 'table_name',
            type: 'map',
            filterOp: SqlOperation.IN,
            locked: true,
            value: []
        }
    ]
}
