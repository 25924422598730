import {ChartI, NormalObject, PieChartI} from "../../../Types";
import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../hooks";
import styled from "styled-components";
import {GREEN_COLOR, LANG, RED_COLOR, XY_GAP} from "../../CONSTANTS";
import {faGear} from "@fortawesome/free-solid-svg-icons";
import Icon from "../../misc/Icon";
import PieView, {normalizePercentages, PieEntry} from "./PieView";
import {fullNamesForEntries} from "../XY/MyXyChart";
import {changeChartSetting, restoreChart, saveChartLocally} from "../../../features/chartsSlice";
import {MIN_SLOT_WIDTH} from "../../graphs/GraphLayout";
import ChartPeriod from "../ChartPeriod";

export type PieChartProps = {
    width: number;
    chartId: string;
    slots: number;
    data: NormalObject[]
};

const data = [
    {
        label: 'o 1',
        value: 11
    },

    {
        label: 't 2',
        value: 18
    },


    {
        label: 'lab 3',
        value: 13
    },


    // {
    //     label: 'opt 4',
    //     value: 20
    // },

] as NormalObject[]

export default function MyPieChart({width, chartId, slots, data}: PieChartProps) {

    const settings = useAppSelector(state => (state.charts.entries[chartId].settings as PieChartI))
    const height = useAppSelector(state => state.charts.entries[chartId].height!);
    const name = useAppSelector(state => state.charts.entries[chartId].lang[LANG]);
    const isEditing = useAppSelector(state => (state.layout.isDashboardPaneEditing))
    const projectName = useAppSelector(state => (state.project.name))
    const chart = useAppSelector(state => (state.charts.entries[chartId]) as ChartI<PieChartI>)
    const [showSettings, setShowSettings] = useState(false)

    const dispatch = useAppDispatch();

    function changeSetting(field: string, value: any) {
        dispatch(changeChartSetting({
            chartId: chartId,
            property: field,
            value: value
        }))
    }

    const actualSlots = Math.max(Math.floor(width / MIN_SLOT_WIDTH), 1)
    const pieWidth = actualSlots >= 2 ? ((width - XY_GAP) * (actualSlots - 1) / actualSlots) : width
    const sideLegendWidth = (width - XY_GAP) / actualSlots

    const [percentages, sum] = normalizePercentages(data as PieEntry[])


    const names = fullNamesForEntries(settings.entries);
    const getLegendText = (label: string) => {
        const find = data.find(x => x.label === label);
        if (find && percentages[label])
            return `: ${find.value} (${percentages[label]})`;
        return ': 0'
    };

    const [opacity, setOpacity] = useState(0);

    useEffect(() => {
        setOpacity(1);
    }, []);

    return (
        <Container style={{opacity: opacity}}>
            <div className={'flex fl-al-ce fl-ju-sb chart-head'}>
                <h3 className={'chart-name flex gap-2 fl-al-ce'}>
                    {name}
                </h3>
                <div className="chart-controls">
                    {isEditing &&
                        <button className={'chart-head-controls-button'} onClick={() => setShowSettings(!showSettings)}>
                            <Icon src={"/win-icons/Settings.ico"}
                                  title={'Налаштування'} alt={'Налаштування'}
                                  width={'1.2rem'}
                                  height={'1.2rem'}
                                  component={faGear}
                            />
                        </button>}
                </div>
            </div>
            <div className={'flex fl-ju-ce gap-5 fl-al-ce'}>
                <PieView width={pieWidth}
                         useNumberLabel={settings.usePieNumberLabel!}
                         usePieNumberPercentage={settings.usePieNumberPercentage!}
                         shortNames={settings.pieShortNames}
                         names={names}
                         height={height} data={data as PieEntry[]} colors={settings.colors!} whole={settings.pieWhole}/>
                {actualSlots >= 2 && <div className={'pie-side-legend'} style={{flexBasis: sideLegendWidth + 'px'}}>
                    <ul className={'side-legend'}>
                        {settings.entries.map((e, i) => <li key={i}>
                            <div className={'square'} style={{background: settings.colors![e.key]}}/>
                            <div>{e.lang[LANG]}{getLegendText(e.key)}</div>
                        </li>)}
                    </ul>
                </div>}
            </div>
            <div className={'flex fl-ju-ce'}>
                <ChartPeriod chartId={chartId}/>
            </div>
            {actualSlots <= 1 && <ul className={'legend'}>
                {settings.entries.map((e, i) => <li key={i}>
                    <div className={'square'} style={{background: settings.colors![e.key]}}/>
                    <div>{e.lang[LANG]}{getLegendText(e.key)}</div>
                </li>)}
            </ul>}
            {showSettings && <div className={'flex fl-al-ce gap-5 fl-wrap xy-settings'}>
                <div className={'flex fl-al-ce gap-5 fl-wrap save-btns'}>
                    <button className="save"
                            onClick={() => {
                                saveChartLocally(chart, projectName, chartId)
                                setShowSettings(false)
                            }}>Зберегти налаштування
                    </button>
                    <button className="restore" onClick={() => {
                        restoreChart(projectName, chartId)
                        setShowSettings(false)
                    }}>Скинути
                    </button>
                </div>
                <div className={'flex fl-al-ce gap-5 fl-wrap xy-settings'}>
                    <label className={'flex fl-al-ce gap-2'}>
                        <input
                            type="checkbox"
                            onChange={() => changeSetting('usePieNumberLabel', !settings.usePieNumberLabel)}
                            checked={settings.usePieNumberLabel}
                        />
                        Показувати чисельне значення
                    </label>
                    <label className={'flex fl-al-ce gap-2'}>
                        <input
                            type="checkbox"
                            onChange={() => changeSetting('usePieNumberPercentage', !settings.usePieNumberPercentage)}
                            checked={settings.usePieNumberPercentage}
                        />
                        Показувати відсоткове значення
                    </label>
                    <label className={'flex fl-al-ce gap-2'}>
                        <input
                            type="checkbox"
                            onChange={() => changeSetting('pieWhole', !settings.pieWhole)}
                            checked={settings.pieWhole}
                        />
                        Форма - ціла
                    </label>
                </div>
            </div>}
        </Container>
    )
}


const Container = styled.div`
    display: flex;
    flex-flow: column;
    gap: .5rem;

    opacity: 0;
    transition: opacity .4s ease;

    background-color: hsl(from var(--table-row-even-bg-color) h s l / 0.3);
    background-color: var(--table-row-even-bg-color);
    border-radius: .5rem;
    padding: 8px;

    & h3 {

    }

    & .pie-side-legend {
        display: flex;
        justify-content: center;
    }

    & .side-legend {
        display: flex;
        flex-flow: column;
        gap: .5rem;
        justify-content: center;

        & li {
            display: flex;
            gap: .2rem;
            align-items: center;
        }
    }

    & .legend {
        display: flex;
        gap: .5rem;
        flex-wrap: wrap;
        justify-content: center;

        & li {
            display: flex;
            gap: .2rem;
            align-items: center;
        }
    }

    & .square {
        width: 1rem;
        height: 1rem;
    }

    & .save-btns {
        & .save {
            background: ${GREEN_COLOR};
            color: var(--secondary-text);
            border: none;
            padding: .2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: .2rem;
            font-size: 1rem;
        }

        & .restore {
            font-size: 1rem;
            background: ${RED_COLOR};
            color: var(--secondary-text);
            border: none;
            padding: .2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: .2rem;
        }
    }

`
