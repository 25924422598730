import {useEffect} from "react";
import {setIconsStyle} from "../../features/projectSlice";
import {setCssProp, themes} from "../settings/ThemeChanger";
import {useAppDispatch} from "../../hooks";

const currentSettingsVersion = 6

export default function useTheme() {

    const dispatch = useAppDispatch();

    useEffect(() => {
        let version = localStorage.getItem('theme-version');
        if (version === null || Number(version) < currentSettingsVersion) {
            localStorage.setItem('theme-version', currentSettingsVersion.toString())
            localStorage.removeItem('theme')
            localStorage.removeItem('primary-color')
            localStorage.removeItem('iconStyle')
        }

        let themeKey = localStorage.getItem('theme') || 'classic';
        let color = localStorage.getItem('primary-color') || '#089DF8';
        // localStorage.getItem('iconStyle') || 'fontAwesome';
        let icons = 'fontAwesome';

        dispatch(setIconsStyle(icons))

        if (themeKey && themes[themeKey]) {
            Object.entries(themes[themeKey].values).forEach(([prop, val]) => {
                setCssProp('--' + prop, val)
            })
        }
        if (color)
            setCssProp('--table-action-color', color)
    }, [dispatch]);

}
