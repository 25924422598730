import {act287Schema} from "./287/Act287";
import {ChartI, SchemaI} from "./Types";
import {Act287Charts} from "./287/Act287Charts";

const schemas = {
    'act287': act287Schema
} as { [k: string]: SchemaI }

const charts_schemas = {
    'act287': Act287Charts
} as { [key: string]: { [key: string]: ChartI } }

export {schemas as STATIC_SCHEMAS, charts_schemas as STATIC_CHARTS}
