import React, {useMemo, useState} from 'react';
import {Field, OptionI} from '../../Types';
import styled from "styled-components";
import {default as Select, GroupBase, MultiValue, OptionsOrGroups} from "react-select";
import {selectMapColourStyles} from "../filters/FilterMultiMapValue";
import UpdateFieldButton from "../table/UpdateFieldButton";
import {useAppDispatch} from "../../hooks";
import {updateSingleTableField} from "../../features/tableSlice";
import {createMapFilterSchema} from "../../features/init";

const lang = 'ua'

function EditSelectMap({value, schema, pk, closeEditing}: {
    value: string | number,
    schema: Field,
    pk: string | number,
    closeEditing: () => void
}) {

    let mapFilterSchema = createMapFilterSchema(schema.map!);
    let so
    if (value !== null) {
        so = mapFilterSchema.options?.find(x => x.value == value);
        if (so === undefined)
            so = {
                label: {
                    'ua': 'Значення не знайдено',
                    'en': 'Empty value',
                },
                value: undefined,
                disabled: true
            }
    } else
        so = {
            label: {
                'ua': 'Порожнє значення',
                'en': 'Empty value',
            },
            value: null,
            disabled: true
        }
    let initial = {
        ...so,
        label: so!.label[lang]
    } as OptionI

    let [isChanged, setIsChanged] = useState(false);
    let [newValue, setNewValue] = useState<OptionI | GroupBase<OptionI>>(initial!);

    const options = useMemo(() => {
        let groups = mapFilterSchema.groups?.map(o => ({
            label: o.label[lang],
            options: o.options.map(opt => ({...opt, label: opt.label[lang]}))
        }));
        let opts = mapFilterSchema.options?.map(o => ({
            ...o,
            label: o.label[lang],
        }));
        return ((groups || opts) as OptionsOrGroups<OptionI, GroupBase<OptionI>> | undefined);
    }, [mapFilterSchema]);

    const dispatch = useAppDispatch();

    function updateField() {
        if ("value" in newValue) {
            dispatch(updateSingleTableField({field: schema.key, value: newValue.value, id: pk}))
        }
        closeEditing()
    }

    function onChange(val: OptionI | MultiValue<any>) {
        if ("value" in val) {
            val.value === value ? setIsChanged(false) : setIsChanged(true)
        }
        setNewValue(val as OptionI)
    }

    return <Container>
        <UpdateFieldButton disabled={!isChanged} onClick={updateField}/>
        <Select
            className={'select-map'}
            value={newValue}
            onChange={(newValue) => onChange(newValue)}
            closeMenuOnSelect={true}
            placeholder={"Оберіть значення"}
            options={options}
            styles={selectMapColourStyles}
        />
    </Container>
}

export default EditSelectMap;

const Container = styled.div`

    display: flex;
    gap: .5rem;

    .select-map {
        border-radius: 0;
        color: black;
        flex-grow: 1;
    }

    & .wrapper {
        display: flex;
        gap: .5rem;
    }


`

/**
 * Extracts text content from a string or JSX element.
 * @param {React.ReactNode} content - The content to extract text from, which could be a string or JSX.
 * @returns {string} - The text content.
 */
function getTextContent(content: string | React.JSX.Element): string {
    if (typeof content === 'string') {
        // If it's already a string, return it as is.
        return content;
    }

    // If it's JSX, recursively extract text content from children
    if (React.isValidElement(content)) {
        // @ts-ignore
        const children = content.props.children;
        if (typeof children === 'string') {
            return children; // Direct string child
        }
        if (Array.isArray(children)) {
            return children.map(getTextContent).join(''); // Recursively extract text
        }
        if (React.isValidElement(children)) {
            return getTextContent(children); // Nested single child
        }
    }

    // If content is null, undefined, or not recognizable, return an empty string
    return '';
}
