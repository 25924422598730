import {ChartI, PieChartI} from "../../Types";
import {GREEN_COLOR, RED_COLOR} from "../../components/CONSTANTS";

const orderTop5Pie: ChartI<PieChartI> = {
    id: "orderTop5Pie",
    queryId: "orderTop5Pie",
    lang: {
        ua: 'Топ 5 замовлень за місяць'
    },
    type: "pie",
    height: 450,
    parameters: {
        periods: ["Day1", "Day2", "Day3", "Day7", "Day14", "Month1", "Month3"],
        selectedPeriod: "Month1",
        selectedInterval: "Day1",
        filters: {
            from: {
                lang: {ua: 'З'},
                key: 'from',
                type: 'datetime'
            },
            to: {
                lang: {ua: 'До'},
                key: 'to',
                type: 'datetime'
            }
        }
    },
    settings: {
        pieWhole: false,
        usePieNumberLabel: true,
        usePieNumberPercentage: false,
        dynamicEntries: true,
        pieShortNames: {
            one: '1',
            two: '2',
            three: '3',
            four: '4',
            five: '5',
        },
        entries: [
            {
                key: 'one',
                lang: {
                    ua: '1'
                }
            }, {
                key: 'two',
                lang: {
                    ua: '2'
                }
            },
            {
                key: 'three',
                lang: {
                    ua: '3'
                }
            },{
                key: 'four',
                lang: {
                    ua: '4'
                }
            },
            {
                key: 'five',
                lang: {
                    ua: '5'
                }
            },
        ],
        colors: {
            one: GREEN_COLOR,
            two: RED_COLOR,
            three: '#148aff',
            four: '#ff6a14',
            five: '#fc08c3',
        },
        glyphs: [],
    }
}

export default orderTop5Pie
