import {ChartI, PieChartI} from "../../Types";

const inmsgChannelPie: ChartI<PieChartI> = {
    id: "inmsgChannelPie",
    queryId: "inmsgChannel",
    lang: {
        ua: 'Успішні реєстрації за каналами'
    },
    type: "pie",
    height: 440,
    parameters: {
        periods: ["Day1", "Day2", "Day3", "Day7", "Day14", "Month1", "Month3", "Month6", "Year1", "AllTime"],
        selectedPeriod: "Month1",
        selectedInterval: "Day1",
        filters: {
            from: {
                lang: {ua: 'З'},
                key: 'from',
                type: 'datetime'
            },
            to: {
                lang: {ua: 'До'},
                key: 'to',
                type: 'datetime'
            }
        }
    },
    settings: {
        entries: [
            {
                key: 'web',
                lang: {
                    ua: 'WEB'
                }
            }, {
                key: 'viber',
                lang: {
                    ua: 'VIBER'
                }
            }
        ],
        colors: {
            'web': '#238dfe',
            'viber': '#bc30ee',
        },
        glyphs: [{
            key: 'web',
            value: '🕸',
        }, {
            key: 'viber',
            value: '🤙',
        }],
    }
}

export default inmsgChannelPie
