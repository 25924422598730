import {PaneI} from "../components/graphs/GraphLayout";
import {DashboardVariationsI} from "../features/layoutSlice";

export default {
    panes: [
        {slots: 2, id: 'inmsgSuccess', name: 'Успішні реєстрації (inmsgSuccess)', type: 'xyChart', order: 0},
        {slots: 2, id: 'inmsgBalls', name: 'Балів за коди (inmsgBalls)', type: 'xyChart', order: 1},
        {slots: 2, id: 'inmsgByTypes', name: 'Реєстрації за типами (inmsgByTypes)', type: 'xyChart', order: 2},
        {slots: 2, id: 'orderTop5Pie', name: 'Топ 5 замовлень (orderTop5Pie)', type: 'pieChart', order: 3},
        {slots: 2, id: 'orderCount', name: 'Кількість замовлень (orderCount)', type: 'xyChart', order: 4},
        {slots: 2, id: 'orderRate', name: 'Балів за замовлення (orderRate)', type: 'xyChart', order: 5},
        {slots: 2, id: 'inmsgChannelPie', name: 'Успішні реєстрації за каналами (inmsgChannelPie)', type: 'pieChart', order: 6},
        {slots: 2, id: 'inmsgDayActivity', name: 'Добова активність реєстрацій (inmsgDayActivity)', type: 'xyChart', order: 7},
        {slots: 2, id: 'inmsgPerUser', name: 'Успішних реєстрацій на користувача (inmsgPerUser)', type: 'xyChart', order: 8},
        {slots: 2, id: 'inmsgUniqueUsers', name: 'Унікальних користувачів (inmsgUniqueUsers)', type: 'xyChart', order: 9},
        {slots: 4, id: 'inmsgByTypesLong', name: 'Реєстрації за довгий період (inmsgByTypesLong)', type: 'xyChart', order: 10},
        {slots: 4, id: 'inmsgBallsLong', name: 'Балів за коди (inmsgBallsLong)', type: 'xyChart', order: 11},
        {slots: 4, id: 'inmsgChannelLong', name: 'Успішні реєстрації за каналами (inmsgChannelLong)', type: 'xyChart', order: 12},
    ],
    variations: {
        '2': [
            {i: 0, slots: 2},
            {i: 1, slots: 2},
            {i: 2, slots: 2},
            {i: 3, slots: 2},
            {i: 4, slots: 2},
            {i: 5, slots: 2},
            {i: 6, slots: 2},
            {i: 7, slots: 2},
            {i: 8, slots: 2},
            {i: 9, slots: 2},
            {i: 10, slots: 2},
            {i: 11, slots: 2},
            {i: 12, slots: 2},
        ],
        '3': [
            {i: 0, slots: 3},
            {i: 1, slots: 3},
            {i: 2, slots: 2},
            {i: 3, slots: 1},
            {i: 4, slots: 3},
            {i: 5, slots: 3},
            {i: 6, slots: 1},
            {i: 7, slots: 2},
            {i: 8, slots: 3},
            {i: 9, slots: 3},
            {i: 10, slots: 3},
            {i: 11, slots: 3},
            {i: 12, slots: 3},
        ]
    }
} as { panes: PaneI[], variations: DashboardVariationsI }
