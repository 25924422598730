import styled from "styled-components";
import React, {useEffect, useRef, useState} from "react";
import {nanoid} from "@reduxjs/toolkit";
import Pane from "./Pane";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {createPortal} from "react-dom";
import AsideDashboardFunc from "../charts/AsideDashboardFunc";
import {PaneType} from "../../Types";
import {DASHBOARD_PANE_MOUNT_DELAY} from "../CONSTANTS";
import {getLayoutInitialVariant, LAYOUT_VERSION, setInitialLayoutDashboard} from "../../features/layoutSlice";


export interface PaneI {
    slots: number
    name: string
    empty?: boolean
    id: string
    type: PaneType,
    order: number,
    minSlots?: number,
    maxSlots?: number
}

export const MAX_SLOTS = 4
export const MIN_SLOT_WIDTH = 360;

export default function GraphLayout({projectName}: { projectName: string }) {

    const containerRef = useRef(null);
    const sideHeaderReady = useAppSelector(state => state.layout.sideHeaderReady);
    const [dimensions, setDimensions] = useState<{ width: number, height: number }>({width: 0, height: 0});
    const [slotsPerRow, setSlotsPerRow] = useState<number>(0)

    const [isLayoutSelected, setIsLayoutSelected] = useState<boolean>(false)

    const panes = useAppSelector(state => state.layout.dashboardLayout);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!isLayoutSelected && dimensions.width > 0) {
            const savedLayout = localStorage.getItem(`${projectName}-dash-layout`);
            if (savedLayout !== null) {
                const parsed = JSON.parse(savedLayout);
                if (parsed.version === LAYOUT_VERSION) {
                    dispatch(setInitialLayoutDashboard(parsed.data))
                }
            } else {
                const actualSlots = Math.max(Math.floor(dimensions.width / MIN_SLOT_WIDTH), 1)
                const panes = getLayoutInitialVariant(actualSlots, projectName);
                dispatch(setInitialLayoutDashboard(panes))
            }
            setIsLayoutSelected(true)
        }
    }, [projectName, dispatch, isLayoutSelected, dimensions.width]);


    useEffect(() => {
        const updateDimensions = (entries: any) => {
            for (let entry of entries) {
                const {width, height} = entry.contentRect;
                const slots = Math.min(Math.floor(width / MIN_SLOT_WIDTH), MAX_SLOTS)
                if (slots !== slotsPerRow)
                    setSlotsPerRow(slots)
                setDimensions({width, height});
            }
        };

        const observer = new ResizeObserver(updateDimensions);

        if (containerRef.current) {
            observer.observe(containerRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, [slotsPerRow]);

    return <>
        <section ref={containerRef}>
            <div className={'flex gap-5'}>
                {dimensions.width > 0 &&
                    <PaneRows panes={panes} maxSlotsPerRow={slotsPerRow} width={dimensions.width}/>}
            </div>
        </section>
        {sideHeaderReady &&
            createPortal(<AsideDashboardFunc panes={panes}/>, document.getElementById("side-head-content")!)}
    </>
}


function PaneRows({panes, maxSlotsPerRow, width}: { panes: PaneI[], maxSlotsPerRow: number, width: number }) {

    return <PaneRowContainer>
        {groupPanesByMaxSlots(panes, maxSlotsPerRow).map((row, rowIndex) =>
            <div className="row" key={rowIndex}>
                {row.map((p, columnIndex) =>
                    <PaneSlot key={rowIndex + '-' + columnIndex} $gaps={row.length} $slots={p.slots} $width={width}>
                        <Pane pane={p}
                              mountDelay={p.order * DASHBOARD_PANE_MOUNT_DELAY}/>
                    </PaneSlot>
                )}
            </div>
        )}
    </PaneRowContainer>
}

const PaneRowContainer = styled.div`
    display: flex;
    flex-flow: column;
    gap: .5rem;
    width: 100%;

    & .row {
        display: flex;
        align-items: stretch;
        justify-content: center;
        gap: 8px;
    }
`

const PaneSlot = styled.div<any>`
        //flex: 0 1 ${props => (props.$width - (props.$slots - 1) * 8) / props.$slots}px;
    width: ${props => ((props.$width - (props.$gaps - 1) * 8) * props.$slots) / MAX_SLOTS}px;
    flex-grow: ${props => props.$slots};

    //background-color: var(--table-action-color);
`

const emptySlot: PaneI = {slots: 1, empty: true, id: nanoid(), name: 'Порожня комірка', type: 'none', order: 0};

const groupPanesByMaxSlots = (panes: PaneI[], maxSlots: number): PaneI[][] => {
    const result: PaneI[][] = [];
    let currentRow: PaneI[] = [];
    let currentSum = 0;

    panes.forEach((pane) => {
        if (currentSum + pane.slots > maxSlots) {
            // Push the current row to the result, padding it with empty panes if needed
            if (currentRow.length > 0) {
                while (currentSum < maxSlots) {
                    emptySlot.order = pane.order + 1
                    currentRow.push(emptySlot);
                    currentSum += 1;
                }
                result.push(currentRow);
            }
            // Start a new row
            currentRow = [pane];
            currentSum = pane.slots;
        } else {
            // Add the pane to the current row
            currentRow.push(pane);
            currentSum += pane.slots;
        }
    });

    // Add the last row, padding it with empty panes if needed
    if (currentRow.length > 0) {
        while (currentSum < maxSlots) {
            currentRow.push(emptySlot);
            currentSum += 1;
        }
        result.push(currentRow);
    }

    return result;
};
