import {FieldMap, FieldOperations, OpsCategories, SqlOperation, TableI} from "../../Types";
import moment from "moment";
import {nanoid} from "@reduxjs/toolkit";


let fieldOperations: FieldOperations = {
    dt: [...OpsCategories.range],
    msisdn: [...OpsCategories.eq, ...OpsCategories.like, ...OpsCategories.in,],
    status: [...OpsCategories.in],
    status_dt: [...OpsCategories.range, ...OpsCategories.null],
    id: [...OpsCategories.eq, ...OpsCategories.range, ...OpsCategories.in,],
    comment: [...OpsCategories.like, ...OpsCategories.null],
};

const photoStatusMap = {
    groups: [{
        label: {
            ua: "Нова",
            en: "START"
        },
        values: ["new"]
    }, {
        label: {
            ua: "Прийнято",
            en: "START"
        },
        values: ["accepted"]
    }, {
        label: {
            ua: "Відхилено",
            en: "START"
        },
        values: ["rejected:1", "rejected:2", "rejected:3"]
    }],
    backgroundColor: '#18111c',
    values: {
        "new": {
            rawValue: "new",
            lang: {
                ua: "Нова",
                en: "START"
            },
            color: '#ffed35'
        }, "accepted": {
            rawValue: "accepted",
            lang: {
                ua: "Прийнято",
                en: "START"
            },
            color: '#32d922'
        },
        "rejected:1": {
            rawValue: "rejected:1",
            lang: {
                ua: "Відхилено (погана якість)",
                en: "SMART"
            },
            color: '#d5060d'
        }, "rejected:2": {
            rawValue: "rejected:2",
            lang: {
                ua: "Відхилено (не відповідає умовам)",
                en: "SMART"
            },
            color: '#d5060d'
        },
        "rejected:3": {
            rawValue: "rejected:3",
            lang: {
                ua: "Відхилено (дублікат фото)",
                en: "SMART"
            },
            color: '#d5060d'
        }
    }
} as FieldMap;


export function photos287DefFilters() {
    return[
        {
            nanoid: nanoid(),
            field: 'dt',
            type: 'datetime',
            filterOp: SqlOperation.GREATER_THAN_OR_EQUAL,
            locked: true,
            value: moment().subtract(7, 'days').startOf('day').format('yyyy-MM-DD HH:mm:ss')
        },
        {
            nanoid: nanoid(),
            field: 'dt',
            type: 'datetime',
            filterOp: SqlOperation.LESS_THAN_OR_EQUAL,
            locked: true,
            value: moment().endOf('day').locale('en').format('yyyy-MM-DD HH:mm:ss')
        },
        {
            nanoid: nanoid(),
            field: 'msisdn',
            type: 'tel',
            filterOp: SqlOperation.LIKE,
            locked: true,
            value: ''
        },
        {
            nanoid: nanoid(),
            field: 'status',
            type: 'map',
            filterOp: SqlOperation.IN,
            locked: true,
            value: []
        }
    ]
}

export const PhotosTable: TableI = {
    backend: "/287/",
    title: {
        ua: "Фото",
        en: "Замовлення",
    },
    pk: 'id',
    hidden: true,
    defaultOrderedBy: "id",
    defaultOrderedWay: "DESC",
    defaultPerPage: 20,
    orderByFields: {
        "id": {ua: "ID", en: "№"},
        "dt": {ua: "Створено", ru: "Дата"},
        "status_dt": {ua: "Зміна статусу", ru: "Дата"},
    },
    fieldOperations: fieldOperations,
    defaultFilters: 'photos287DefFilters',
    fields: [
        {
            key: "n",
            inline: true,
            title: {ua: "№", ru: "№"}, type: "number",
            applyTransform: true
        },
        {
            key: "msisdn", type: 'tel',
            applyTransform: true,
            detailed: false, inline: true, title: {ua: "Телефон", ru: "Телефон"}
        },
        {
            key: "status",
            title: {ua: "Статус", ru: "Статус (Поділитися)"},
            detailed: true,
            editable: true,
            inlineEditable: true,
            inline: true,
            type: "map",
            applyTransform: true,
            map: photoStatusMap,
            updateSingleFieldPath: 'updatePhotoStatus.php'
        },
        {
            key: "dt",
            title: {ua: "Дата", ru: "Дата"},
            type: "datetime",
            datetimeFormat: "DD MMMM YYYY HH:mm",
            applyTransform: true,
            inline: true,
        },
        {
            key: "photo",
            title: {ua: "Фото", ru: "Фото"},
            type: 'image',
            imageMaxHeight: 500,
            detailed: true,
            inline: false,
            applyTransform: true,
        },
        {
            key: "status_dt",
            title: {ua: "Зміна статусу", ru: "Дата"},
            type: "datetime",
            datetimeFormat: "DD MMMM YYYY HH:mm",
            applyTransform: true,
            detailed: true,
        },
        {
            key: "comment",
            title: {ua: "Коментар", ru: "Комментарий"},
            detailed: true,
            editable: true,
            type: 'string'
        },
        {
            key: "id",
            detailed: true,
            title: {ua: "ID", ru: "№"},
            type: "number",
            applyTransform: true
        },
    ]
}
