import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {PaneI} from "../components/graphs/GraphLayout";
import {ALL_LAYOUTS} from "../config";


interface LayoutI {
    useSideHeader?: boolean
    sideHeaderReady?: boolean
    dashboardLayout: PaneI[]
    isDashboardLowRender: boolean
    isDashboardShifting: boolean,
    isDashboardPaneEditing: boolean,
}

export type DashboardVariationsI = {
    '2': { i: number, slots: number }[],
    '3': { i: number, slots: number }[],
};

export const LAYOUT_VERSION = 5


const initialState: LayoutI = {
    useSideHeader: localStorage.getItem('useSideHeader') === 'true',
    sideHeaderReady: false,
    dashboardLayout: [],
    isDashboardLowRender: false,
    isDashboardShifting: false,
    isDashboardPaneEditing: false,
}

export function getLayoutInitialVariant(slots: number, project: string): PaneI[] {
    if (slots >= 4 || slots === 1) {
        return ALL_LAYOUTS[project as any].panes.map((x, i) => {
            return {...x, order: i}
        })
    } else {
        const ini = ALL_LAYOUTS[project as any].panes
        const variation = ALL_LAYOUTS[project as any].variations[slots.toString() as '2' | '3']
        const panes = [] as PaneI[]
        variation.forEach((v, i) => {
            let pane = {...ini[v.i]}
            pane.order = i
            pane.slots = v.slots
            panes.push(pane)
        })
        return panes
    }
}

export function saveDashLayoutLocally(layout: PaneI[], projectName: string) {
    const o = JSON.stringify({version: LAYOUT_VERSION, data: layout})
    localStorage.setItem(`${projectName}-dash-layout`, o);
}

export function restoreInitialLayout(projectName: string) {
    localStorage.removeItem(`${projectName}-dash-layout`);
    window.location.reload()
}

export const layoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {
        setInitialLayoutDashboard: (state, action: PayloadAction<PaneI[]>) => {
            action.payload.forEach((x, i) => x.order = i)
            state.dashboardLayout = action.payload
        },
        toggleUseSideHeader(state) {
            const val = !state.useSideHeader;
            state.useSideHeader = val
            state.sideHeaderReady = false
            localStorage.setItem('useSideHeader', val.toString())
        },
        toggleDashboardPaneEditing(state) {
            state.isDashboardPaneEditing = !state.isDashboardPaneEditing
        },
        setUseSideHeader(state, action) {
            state.useSideHeader = action.payload
            localStorage.setItem('useSideHeader', (action.payload).toString())
        },
        setSideHeaderReady(state, action) {
            state.sideHeaderReady = action.payload
        },
        setDashboardShifting(state, action) {
            state.isDashboardShifting = action.payload
            if (action.payload)
                state.isDashboardLowRender = true
        },
        setDashboardLowRender(state, action) {
            state.isDashboardLowRender = action.payload
        },
        addPaneSlotSize(state, action: PayloadAction<AddPaneSlotSize>) {
            const {index, value} = action.payload;
            state.dashboardLayout[index].slots = state.dashboardLayout[index].slots + value
        },
        confirmLayoutShift(state, action?) {
            state.isDashboardShifting = false
            state.isDashboardLowRender = false

            if (action.payload)
                saveDashLayoutLocally(state.dashboardLayout, action.payload)
        },
        setLayoutDashboard(state, action) {
            state.dashboardLayout = action.payload
        },
    },
})

export const {
    setInitialLayoutDashboard,
    toggleUseSideHeader,
    setSideHeaderReady,
    setUseSideHeader,
    setDashboardLowRender,
    setDashboardShifting,
    addPaneSlotSize,
    confirmLayoutShift,
    setLayoutDashboard,
    toggleDashboardPaneEditing
} = layoutSlice.actions

export default layoutSlice.reducer

interface AddPaneSlotSize {
    index: number,
    value: number
}
