import {BOTTOM_NAV_HEIGHT, ICONS, LANG} from "../CONSTANTS";
import {useAppDispatch} from "../../hooks";
import React, {useEffect, useState} from "react";
import {setUseSideHeader} from "../../features/layoutSlice";
import {OverlayContainer} from "../misc/ImageViewer";
import AsideHeader from "./AsideHeader";
import {faBars} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {PageI, setActivePage} from "../../features/projectSlice";

interface NavI {
    projectName: string,
    pages: PageI[],
    active: number
}

export default function BottomNav({projectName, pages, active}: NavI) {

    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)

    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(setUseSideHeader(true))
    }, [dispatch]);

    const closeMenu = () => setIsMenuOpen(false);
    return (
        <>
            <nav style={{height: BOTTOM_NAV_HEIGHT}} className={'bottom-nav'}>
                <ul>
                    <li className="nav-item">
                        <button onClick={() => setIsMenuOpen(true)}>
                            <FontAwesomeIcon title={'Меню'} icon={faBars}
                                             style={{width: '1.2rem', height: '1.2rem'}}
                                             className={'fa-color'}/>
                            <span className={'btn-name'}>Меню</span>
                        </button>
                    </li>
                    {pages.map((p, i) => <li key={p.name} className="nav-item">
                        <button onClick={() => dispatch(setActivePage(i))}>
                            {p.icon && <FontAwesomeIcon title={p.lang[LANG]} icon={ICONS[p.icon]}
                                              style={{width: '1.2rem', height: '1.2rem'}}
                                              className={'fa-color'}/>}
                            {p.lang[LANG]}
                        </button>
                    </li>)}
                </ul>
            </nav>
            {isMenuOpen && <OverlayContainer onClick={closeMenu} $isVisible={isMenuOpen} $left>
                <AsideHeader projectName={projectName} mobile closeMenu={closeMenu}/>
            </OverlayContainer>}
        </>
    )
}
