import React, {useEffect, useState} from "react";
import ThreeCircles from "./ThreeCircles";
import HeadActions from "./HeadActions";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import Icon from "../misc/Icon";
import moment from "moment";

export default function AsideProjectInfo({projectName}: { projectName: string }) {
    const [data, setData] = useState<any>(undefined)
    const [viewActions, setViewActions] = useState<boolean>(false)

    useEffect(() => {
        if (!data) {
            let item = localStorage.getItem('project') as any;
            if (item) {
                item = JSON.parse(item)
                item.actStart = moment(item.actStart).format("DD.MM.YYYY HH.mm")
                item.actEnd = item.actEnd ? moment(item.actEnd).format("DD.MM.YYYY HH.mm") : '&'
                setData(item)
            } else {
                window.location.href = '/login.html'
            }
        }
    }, [data]);


    return <div className={'aside-head-info'}>
        <div className={'logo'}>
            <img src="/icon.png" alt="icon"/>
            <div className="name-dots">
                <div className='name'>
                    <span>Engine</span>
                    {data && <span>{data.user}</span>}
                </div>
                <div>
                    <button className="dots" onClick={() => setViewActions(!viewActions)}>
                        {!viewActions && <ThreeCircles/>}
                        {viewActions && <Icon src={"/win-icons/Cross.ico"}
                                              title={'Вийти'} alt={'Вийти'}
                                              width={'1.5rem'}
                                              height={'1.5rem'}
                                              component={faXmark}/>}
                    </button>
                </div>
            </div>
        </div>
        {viewActions && <HeadActions projectName={projectName}/>}
        {!viewActions && data &&
            <div className="info">
                <h2>{data.actName}</h2>
                <div className={'project-time'}>
                    <div className="dot"/>
                    <div className="data">{data.actStart} - {data.actEnd}</div>
                </div>
            </div>
        }
    </div>
}
