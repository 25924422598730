import React, {useState} from 'react';
import Column from "./Column";
import {Field} from "../../Types";
import {getColumnValue} from "../functions";
import styled from "styled-components";
import {LoaderContainer} from "./Table";
import Loader from "../Loader";
import {useAppSelector} from "../../hooks";

function Row({record, schema, cols, pkField, tableName}: {
    record: any,
    schema: Field[],
    cols: number,
    pkField: string,
    tableName: string
}) {

    let [isViewing, setIsViewing] = useState(false);
    let isFrozen = useAppSelector(state => state.tables.tableInfo[tableName].frozen[tableName + record[pkField]]);


    //todo ACTION select edit/view, approve image etc.
    return <>
        <tr onClick={() => setIsViewing(!isViewing)}
            style={{
                backgroundColor: isViewing ? 'var(--table-border-color)' : 'var(--table-row-odd-bg-color)'
            }}>
            {schema.map(field => {
                if (!field.inline)
                    return null
                return <td key={field.key} style={{width: field.type === 'tel' ? 'auto' : 'unset'}}>
                    {getColumnValue(record[field.key], field, true, record)}
                </td>
            })}
        </tr>
        {isViewing &&
            <tr>
                <td colSpan={1}/>
                <td className={'viewRow'} colSpan={cols - 1}>
                    <ViewCell $isFrozen={isFrozen}>
                        <ViewTable className={'edit-table'}>
                            {/*<thead>*/}
                            {/*<tr style={{background: 'var(--table-header-bg-color)'}}>*/}
                            {/*    <th align={"center"} colSpan={cols}>Перегляд/Редагування</th>*/}
                            {/*</tr>*/}
                            {/*</thead>*/}
                            <tbody>
                            {schema.map(field => {
                                if (field.detailed || !field.inline) {
                                    return <Column value={record[field.key]} schema={field} cols={cols} record={record}
                                                   key={field.key} pk={record[pkField]}/>
                                } else {
                                    return null
                                }
                            })}
                            </tbody>
                        </ViewTable>
                        {isFrozen && <LoaderContainer>
                            <Loader show={false}/>
                        </LoaderContainer>}
                    </ViewCell>
                    <HR/>
                </td>
            </tr>
        }
    </>;
}

export default Row;

const HR = styled.hr`
    height: 4px;
    background: var(--table-action-color);
    margin: .4rem 0 0;
`

const ViewCell = styled.div<any>`
    display: inline-flex;
    justify-content: left;
    width: 100%;
    position: ${props => props.$isFrozen ? 'relative' : 'unset'};
`

const ViewTable = styled.table`
    max-width: 100%; // Default for smaller screens

    @media (min-width: 1200px) {
        max-width: 1000px; // Set max-width to 1000px for screens wider than 1200px
    }
`;
