import {Group} from "@visx/group";
import Pie, {PieArcDatum, ProvidedProps} from "@visx/shape/lib/shapes/Pie";
import {Strings} from "../../../Types";
import React, {useState} from "react";
import {animated, to, useTransition} from "@react-spring/web";

const margin = {top: 20, right: 20, bottom: 20, left: 20};


export type PieEntry = { label: string, value: number };

interface PieViewI {
    width: number,
    height: number,
    colors: Strings,
    data: PieEntry[],
    whole?: boolean,
    shortNames?: Strings,
    names?: Strings,
    useNumberLabel: boolean
    usePieNumberPercentage: boolean
};

const frequency = (d: PieEntry) => d.value;

export default function PieView({
                                    width,
                                    data,
                                    colors,
                                    height,
                                    whole = true,
                                    shortNames,
                                    names,
                                    useNumberLabel,
                                    usePieNumberPercentage
                                }: PieViewI) {

    const [selected, setSelected] = useState<string | null>(null);

    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;
    const radius = Math.min(innerWidth, innerHeight) / 2;
    const centerY = innerHeight / 2;
    const centerX = innerWidth / 2;
    const donutThickness = 50;

    const [percentages, sum] = normalizePercentages(data)

    function getSelectedText() {
        if (selected)
            return `${shortNames?.[selected] || names?.[selected] || selected}: ${data.find(x => x.label === selected)!.value} (${percentages[selected!]})`
        return ``
    }

    function getText(label: string, value: number) {
        if (usePieNumberPercentage)
            return percentages[label]
        if (useNumberLabel)
            return value
        return `${shortNames?.[label] || names?.[label] || label}`
    }

    return (
        <svg width={width} height={height}>
            {!whole && <Group top={centerY + margin.top} left={centerX + margin.left}>
                <text
                    fill="var(--table-row-text-color)"
                    x={0}
                    y={0}
                    dy=".33em"
                    fontSize={16}
                    textAnchor="middle"
                    pointerEvents="none"
                >
                    {selected ? getSelectedText() : `Усього: ${sum}`}
                </text>
            </Group>}
            <Group top={centerY + margin.top} left={centerX + margin.left}>
                {whole && <Pie
                    data={selected ? data.filter(x => x.label === selected) : data}
                    pieValue={frequency}
                    pieSortValues={() => -1}
                    outerRadius={radius}
                >
                    {(pie) => (
                        <AnimatedPie<PieEntry>
                            {...pie}
                            animate={true}
                            getColor={({data: {label}}) => colors[label] || '#fff'}
                            onClickDatum={({data: {label}}) =>
                                setSelected(selected && selected === label ? null : label)
                            }
                            getRealKey={({data: {label, value}}) => label}
                            getKey={({data: {label, value}}) => selected ? getSelectedText() : getText(label, value)}/>
                    )}
                </Pie>}

                {!whole && <Pie
                    data={selected ? data.filter(x => x.label === selected) : data}
                    pieValue={frequency}
                    outerRadius={radius}
                    innerRadius={radius - donutThickness}
                    cornerRadius={3}
                    padAngle={0.005}
                >
                    {(pie) => (
                        <AnimatedPie<PieEntry>
                            {...pie}
                            animate={true}
                            getColor={({data: {label}}) => colors[label] || '#fff'}
                            onClickDatum={({data: {label}}) =>
                                setSelected(selected && selected === label ? null : label)
                            }
                            getRealKey={({data: {label, value}}) => label}
                            getKey={({data: {label, value}}) => selected ? '' : getText(label, value)}/>
                    )}
                </Pie>}
            </Group>
        </svg>
    )
}


type AnimatedStyles = { startAngle: number; endAngle: number; opacity: number };

const fromLeaveTransition = ({endAngle}: PieArcDatum<any>) => ({
    // enter from 360° if end angle is > 180°
    startAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
    endAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
    opacity: 0,
});
const enterUpdateTransition = ({startAngle, endAngle}: PieArcDatum<any>) => ({
    startAngle,
    endAngle,
    opacity: 1,
});

type AnimatedPieProps<Datum> = ProvidedProps<Datum> & {
    animate?: boolean;
    getKey: (d: PieArcDatum<Datum>) => string | number;
    getRealKey: (d: PieArcDatum<Datum>) => string;
    getColor: (d: PieArcDatum<Datum>) => string;
    onClickDatum: (d: PieArcDatum<Datum>) => void;
    delay?: number;
};

function AnimatedPie<Datum>({
                                animate,
                                arcs,
                                path,
                                getKey,
                                getRealKey,
                                getColor,
                                onClickDatum,
                            }: AnimatedPieProps<Datum>) {
    const transitions = useTransition<PieArcDatum<Datum>, AnimatedStyles>(arcs, {
        from: animate ? fromLeaveTransition : enterUpdateTransition,
        enter: enterUpdateTransition,
        update: enterUpdateTransition,
        leave: animate ? fromLeaveTransition : enterUpdateTransition,
        keys: getRealKey,
    });
    return transitions((props, arc, {key}) => {
        const [centroidX, centroidY] = path.centroid(arc);
        const hasSpaceForLabel = arc.endAngle - arc.startAngle >= 0.1;

        return (
            <g key={key}>
                <animated.path
                    // compute interpolated path d attribute from intermediate angle values
                    d={to([props.startAngle, props.endAngle], (startAngle, endAngle) =>
                        path({
                            ...arc,
                            startAngle,
                            endAngle,
                        }),
                    )}
                    fill={getColor(arc)}
                    onClick={() => onClickDatum(arc)}
                    onTouchStart={() => onClickDatum(arc)}
                />
                {hasSpaceForLabel && (
                    <animated.g style={{opacity: props.opacity}}>
                        <text
                            fill="white"
                            x={centroidX}
                            y={centroidY}
                            dy=".33em"
                            fontSize={14}
                            textAnchor="middle"
                            pointerEvents="none"
                        >
                            {getKey(arc)}
                        </text>
                    </animated.g>
                )}
            </g>
        );
    });
}


export function normalizePercentages(data: PieEntry[]): [Record<string, string>, number] {
    // Calculate the total sum of values
    const total = data.reduce((sum, entry) => sum + entry.value, 0);

    // Compute raw percentages
    let rawPercentages = data.map((entry) => (total > 0 ? (entry.value / total) * 100 : 0));

    // Round percentages to nearest whole numbers
    let roundedPercentages = rawPercentages.map((p) => Math.floor(p));

    // Calculate rounding error
    let roundingError = 100 - roundedPercentages.reduce((sum, p) => sum + p, 0);

    // Distribute rounding error
    rawPercentages
        .map((value, index) => ({index, diff: value - roundedPercentages[index]}))
        .sort((a, b) => b.diff - a.diff) // Sort by largest fractional part
        .slice(0, roundingError)
        .forEach(({index}) => {
            roundedPercentages[index] += 1;
        });

    // Format and return the final percentages as a map
    const prc = data.reduce((acc, entry, i) => {
        acc[entry.label] = `${roundedPercentages[i]}%`;
        return acc;
    }, {} as Record<string, string>);
    return [prc, total];
}
