import {FieldMap, FieldOperations, OpsCategories, SqlOperation, TableI} from "../../Types";
import {nanoid} from "@reduxjs/toolkit";


const fieldOperations = {
    id: [...OpsCategories.eq, ...OpsCategories.range, ...OpsCategories.in],
    active: [...OpsCategories.eq],
    dt: [...OpsCategories.range],
};

export const ExtraActivationTable:TableI = {
    backend: "/287/",
    title: {
        ua: "Доп. активації",
    },
    pk: 'id',
    forAdmin: true,
    defaultOrderedBy: "dt",
    defaultOrderedWay: "ASC",
    defaultPerPage: 20,
    orderByFields: {
        "id": { ua: "ID" },
        "dt": { ua: "Створено" },
    },
    fieldOperations: fieldOperations,
    action: 'act287createExtraActivation',
    defaultFilters: 'extraActivation287DefFilters',
    fields: [
        {
            key: "n",
            inline: true,
            title: {ua: "#"},
            type: "number",
            applyTransform: true
        },
        {
            key: "name",
            type: "string",
            detailed: true,
            inline: true,
            editable: true,
            title: { ua: "Назва" }
        },
        {
            key: "active",
            type: "boolean",
            applyTransform: true,
            detailed: true,
            inline: true,
            editable: true,
            title: { ua: "Активна" }
        },
        {
            key: "dt",
            type: "datetime",
            datetimeFormat: "DD MMMM YYYY HH:mm",
            applyTransform: true,
            inline: true,
            detailed: true,
            title: { ua: "Дата створення" }
        },
        {
            key: "first_name",
            type: "string",
            detailed: true,
            editable: true,
            title: { ua: "Перший подарунок" }
        },
        {
            key: "first_num_max",
            type: "number",
            detailed: true,
            editable: true,
            title: { ua: "Макс. к-сть 1ого подарунка" }
        },
        {
            key: "second_name",
            type: "string",
            detailed: true,
            editable: true,
            title: { ua: "Другий подарунок" }
        },
        {
            key: "second_num_max",
            type: "number",
            detailed: true,
            editable: true,
            title: { ua: "Макс. к-сть 2ого подарунка" }
        },
        {
            key: "pay_amount",
            type: "number",
            detailed: true,
            editable: true,
            title: { ua: "Сума поповнення" }
        },
        {
            key: "third_name",
            type: "string",
            detailed: true,
            editable: true,
            title: { ua: "Третій подарунок" }
        },
        {
            key: "balls_amount",
            type: "number",
            detailed: true,
            editable: true,
            title: { ua: "Кількість балів" }
        },
        {
            key: "id",
            type: "number",
            applyTransform: true,
            detailed: true,
            title: { ua: "ID" }
        },
    ]
};

export function extraActivation287DefFilters() {
    return [
        // {
        //     nanoid: nanoid(),
        //     field: 'table_name',
        //     type: 'map',
        //     filterOp: SqlOperation.IN,
        //     locked: true,
        //     value: []
        // }
    ]
}
